import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpService } from './http/http.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PortalDataService {

  constructor(private httpService: HttpService,private router: Router) { }

  public updatePortalWelcomeMessage(project_identifier: string, body: any): Observable<any> {
    return this.httpService.post(`${environment.BASE_URL}projects/${project_identifier}/portal_welcome/`, body);
  }

  public updatePortalNominateMessage(project_identifier: string, body: any): Observable<any> {
    return this.httpService.post(`${environment.BASE_URL}/projects/${project_identifier}/portal_nominate/`, body);
  }

  public updatePortalEvaluateSelfMessage(project_identifier: string, body: any): Observable<any> {
    return this.httpService.post(`${environment.BASE_URL}/projects/${project_identifier}/portal_evaluate_self/`, body);
  }

  public updatePortalEvaluateOthersMessage(project_identifier: string, body: any): Observable<any> {
    return this.httpService.post(`${environment.BASE_URL}/projects/${project_identifier}/portal_evaluate_other/`, body);
  }


  public getPortalWelcomeMessage(project_identifier: string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}projects/${project_identifier}/portal_welcome/`);
  }

  public getPortalNominateMessage(project_identifier: string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}/projects/${project_identifier}/portal_nominate/`);
  }

  public getPortalEvaluateSelfMessage(project_identifier: string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}/projects/${project_identifier}/portal_evaluate_self/`);
  }

  public getPortalEvaluateOthersMessage(project_identifier: string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}/projects/${project_identifier}/portal_evaluate_other/`);
  }

  public updateLogo(project_identifier: string, logo_s3_obj_name: string): Observable<any> {
    return this.httpService.patch(`${environment.BASE_URL}/projects/${project_identifier}/`, { logo_s3_obj_name });
  }

  public getSurveyDetails(project_identifier: string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}/projects/${project_identifier}/`);
  }

  public getProjectLogo(project_identifier: string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}/projects/${project_identifier}/get_logo_url/`);
  }


  public getProjectContactsCsv(projectId: string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}projects/${projectId}/contacts-export/`);
  }

  public getProjectEmailAnalytics(projectId: string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}projects/${projectId}/email_analytics/`);
  }

  public getProjectCampaign(projectId: string, filter?: any): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}projects/${projectId}/report/campaign/dashboard/`, { params: filter });
  }

  exportCampaignReport(projectId: string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}projects/${projectId}/report/campaign/export/`);
  }

  getUserResponseDataInProject(id: any, pageNumber: any, page_size: any, body?: any) {
    return this.httpService.post(
      environment.BASE_URL +
      `projects/${id}/responses/?page=${pageNumber}&page_size=${page_size}`,
      body
    );
  }

  deleteProjectResponses(id: any, responseArray: any) {
    return this.httpService.delete(
      environment.BASE_URL + `projects/${id}/responses/`,
      { body: { responses: responseArray } } as any
    );
  }

  public getParicularResponseDataForProject(projectId: any, responseId: any) {
    return this.httpService.get(
      environment.BASE_URL + `projects/${projectId}/responses/${responseId}/`
    );
  }

  public getReportForDataManagmentInProject(
    projectId: string
  ): any {
    return this.httpService.get(
      environment.BASE_URL + `projects/${projectId}/csv-report/`
    );
  }

  public updateProjectResponseStatus(
    projectId: string,
    responseID: string,
    response: any
  ): Observable<any> {
    return this.httpService.put(environment.BASE_URL + `projects/${projectId}/responses/${responseID}/`, response);
  }


  downloadSpecialityReport(projectId: string, reportCode: string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}projects/${projectId}/report/speciality/?code=${reportCode}`);
  }

  public getReminderContacts(project_identifier: any): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `projects/${project_identifier}/reminder-email-contacts/?page_size=100`
    );

    // return this.httpService.get(
    //   environment.BASE_URL + `surveys/contact-role/?page_size=100`
    // );
  }

  public scheduleProject(
    projectId: string,
    body: any
  ): Observable<any> {
    return this.httpService.post(environment.BASE_URL + `projects/${projectId}/schedule/`, body);
  }

  public testProjectEmail(
    projectId: string,
    body: any
  ): Observable<any> {
    return this.httpService.post(environment.BASE_URL + `projects/${projectId}/send_test_email/`, body);
  }

  public getSurveysInPortal(): Observable<any> {
    let is_impersonate = localStorage.getItem("is_impersonate");
    let uuid = localStorage.getItem("uuid");
    return this.httpService.get(`${environment.BASE_URL}portal/surveys/${ is_impersonate == 'true' ? `?is_impersonate=${is_impersonate}&uuid=${uuid}` : ``}`);
  }

  public surveyDetailForParticipant(surveyId:string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}portal/survey/${surveyId}/`);
  }

  public nominationListForParticipant(surveyId:string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}portal/survey/${surveyId}/nominate/`);
  }


  public addNewRaters(
    surveyId: string,
    body: any
  ): Observable<any> {
    return this.httpService.post(environment.BASE_URL + `portal/survey/${surveyId}/send_ratee_mail/`, body);
  }
  public scheduleReminderToAll(
    projectId: string,
    body: any
  ): Observable<any> {
    return this.httpService.post(environment.BASE_URL + `projects/${projectId}/schedule-all-reminder-emails/`, body);
  }

  public sendEmailAll(
    projectId: string,
    body: any
  ): Observable<any> {
    return this.httpService.post(environment.BASE_URL + `projects/${projectId}/send-reminder-all/`, body);
  }

  public getProjectContactRoles(projectId:string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}project/${projectId}/project-contact-role/`);
  }
  
  public retakeSurveyLink(surveyId:string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}portal/survey/${surveyId}/survey_retake/`);
  }


  public getScheduledGroupData(projectId:string): Observable<any> {
    return this.httpService.get(`${environment.BASE_URL}projects/${projectId}/scheduled_group_list/`);
  }

  public updateBouncedEmail(
    surveyId: string,
    body: any
  ): Observable<any> {
    return this.httpService.put(environment.BASE_URL + `portal/survey/${surveyId}/bounce-email-update/`, body);
  }

  backToStandard(): void {
    const adminUserSavedState = JSON.parse(localStorage.getItem('adminUserSavedState') || '{}');

    // Set each value individually to localStorage
    for (const key in adminUserSavedState) {
      if (adminUserSavedState.hasOwnProperty(key)) {
        if (typeof adminUserSavedState[key] === 'object') {
          localStorage.setItem(key, JSON.stringify(adminUserSavedState[key]));
        } else {
          localStorage.setItem(key, adminUserSavedState[key]);
        }
      }
    }

    localStorage.removeItem('adminUserSavedState');
    localStorage.removeItem('is_impersonate');
    localStorage.removeItem('total_surveys');

    let project_id = localStorage.getItem("project_id");

    this.router.navigate([`project/${project_id}/report`]).then(() => {
      window.location.replace(window.location.href);
    });
  }
}
