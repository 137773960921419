import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from "@angular/core";
import { FormGroup, FormArray, Validators } from "@angular/forms";
import { Observable, of, Subscription } from "rxjs";
@Component({
  selector: "app-common-logic-row-child",
  templateUrl: "./app-common-logic-row-child.component.html",
  styleUrls: ["./app-common-logic-row-child.component.scss"],
})
export class CommonLogicRowChildComponent implements OnInit, OnDestroy {
  @Input() i: any;
  @Input() control: any;
  @Input() allQuestions: any;
  @Input() currentQuestion: any;
  @Input() postQuesSet: any;
  @Input() postSameSecQuesSet: any;
  @Input() postSecSet: any;
  @Input() includeSecQuesSet: any;
  @Input() parentForm: FormGroup;

  @Output()
  addRow: EventEmitter<any> = new EventEmitter();

  @Output()
  removeRow: EventEmitter<any> = new EventEmitter();

  /**
   * Observable for options list.
   */
  quesOptions$: Observable<any>;

  isDefault: boolean = false;

  conditionSetForMCQ: any = [
    { id: "Is Selected", name: "Is Selected" },
    { id: "Is Not Selected", name: "Is Not Selected" },
  ];

  conditionSetForTextbox: any = [
    { id: "Is greater than", name: "Is greater than" },
    { id: "Is greater than or equal to", name: "Is greater than or equal to" },
    { id: "Is less than", name: "Is less than" },
    { id: "Is less than or equal to", name: "Is less than or equal to" },
    { id: "Is equal to", name: "Is equal to" },
    { id: "Is not equal to", name: "Is not equal to" },
    { id: "Is empty", name: "Is empty" },
    { id: "Is not empty", name: "Is not empty" },
    { id: "Contains", name: "Contains" },
    { id: "Does not contain", name: "Does not contain" },
  ];

  interConditionSet: any = [
    { id: "AND", name: "And" },
    { id: "OR", name: "Or" },
  ];

  showOperatorValue: boolean = true;
  showConditionSetForMCQ: boolean = false;
  subscription: any;

  constructor() {}

  ngOnInit() {
    this.editCondition();
    if (this.currentQuestion._type == 1) {
      this.selectedQuesIsMCQ = true;
      this.quesOptions$ = of(this.currentQuestion["question_options"]);
    } else {
      this.selectedQuesIsTextbox = true;
    }

    if (
      this.control.get("operator")?.value == "Is empty" ||
      this.control.get("operator")?.value == "Is not empty"
    ) {
      this.control.get("operator_value")?.setValidators([]);
      this.showOperatorValue = false;
    }else{
      if(this.currentQuestion._type===2){
        this.control.get("operator_value")?.setValidators([Validators.nullValidator]);
      }
      
    }
    this.control.get("operator_value")?.updateValueAndValidity();

    this.subscription = this.control.get("operator")?.valueChanges.subscribe((data)=>{
      if (
        data == "Is empty" ||
        data == "Is not empty"
      ) {
        this.control.get("operator_value")?.setValidators([Validators.nullValidator]);
        this.showOperatorValue = false;
        this.control.get("operator_value")?.updateValueAndValidity();
      }else{
        if(this.currentQuestion._type===2){
          this.control.get("operator_value")?.setValidators([Validators.required]);
          this.control.get("operator_value")?.updateValueAndValidity();
        }
        
      }
    })


  }
  getArrayControl() {
    return (<FormArray>this.parentForm.controls["conditions"]).controls;
  }

  selectedQuesIsMCQ: boolean = false;
  selectedQuesIsTextbox: boolean = false;

  // user changes choosen question -> show the options dropdown accordingly
  onQuestionChange(i: any) {
    this.selectedQuesIsMCQ = false;
    this.selectedQuesIsTextbox = false;
    const control = <FormArray>this.parentForm.controls["logic"];
    const ithControl = control.controls[i];
    // let quesId = this.parentForm.value["logic"][i]
    //   .question_id_from_locator;
    // let ques = this.postQuesSet.filter((elem: any) => {
    //   if (elem._id == quesId) {
    //     return true;
    //   }
    // });

    // let t = ques[0]._type;
    // if mcq
    // if (t == 1) {
    //   ithControl.get("question_type").patchValue(t);
    //   this.quesOptions$ = this.getQuesOptions(i);
    //   this.selectedQuesIsMCQ = true;
    // }
    // if text box
    // else if (t == 2) {
    //   ithControl.get("question_type").patchValue(t);
    //   ithControl.get("question_choice_locator").patchValue(null);
    //   this.selectedQuesIsTextbox = true;
    // }
  }

  getQuesOptions(i: any) {
    let quesId = this.parentForm.value["conditions"][i]
      .question_id_from_locator;
    if (quesId) {
      let ques = this.postQuesSet.filter((elem: any) => {
        if (elem._id == quesId) {
          return true;
        }
      });
      return of(ques[0]["question_options"]);
    } else of([]);
  }

  addRowEvent() {
    this.addRow.next(true);
  }

  removeRowEvent() {
    this.removeRow.next(this.i);
  }

  clear() {
    this.control.get("question_choice_locator")?.patchValue(null);
    this.control.get("operator")?.patchValue(null);
    this.control.get("operator_value")?.patchValue(null);
  }

  editCondition() {
    if (
      this.currentQuestion.logic &&
      this.currentQuestion.logic.length &&
      this.parentForm.value["logic_type"]
    ) {
      // this.onQuestionChange(this.i);
      if (this.currentQuestion._type == 1) {  
        this.showConditionSetForMCQ = true;
        let flag = false;
        let currentQuesChoiceLocator = this.control.get("question_choice_locator")?.value;
        if(currentQuesChoiceLocator){
          let currentQuesOptions = this.currentQuestion["question_options"];
          if(currentQuesOptions && currentQuesOptions.length){
            currentQuesOptions.forEach((op:any) => {
              if(op.id == currentQuesChoiceLocator){
                flag = true;
              }
            });
          }
        }
        if(!flag){
          this.control.get("question_choice_locator")?.patchValue(null);
        }
      } else {
        // this.selectedQuesIsTextbox = true;
      }
    }
  }

  onTextboxConditionChange(item: any) {
    if (item && (item.id == "Is empty" || item.id == "Is not empty")) {
      this.showOperatorValue = false;
    } else {
      this.showOperatorValue = true;
    }
  }

  onMcqResponseOptionSelection(event: any) {
    this.showConditionSetForMCQ = true;
  }

  querstionOrRaterGroup = [
    {
      id:"question",
      name:"Question"
    },
    {
      id:"rater-group",
      name:"Rater Group"
    }
  ]

  onQuestionOrRaterGroupSelect(e){
    if(e.id == "rater-group"){
      this.parentForm?.get("user_type")?.setValue("participants")
      this.control.get('question_choice_locator').setValue(null);
      // this.control.get('operator').setValue(null);
      this.control.get("logic_type_name")?.setValue("display")
      this.control.get("logic_type_name")?.disable();
      this.control.get('operator').setValidators([Validators.nullValidator])
      //
      /**
       * control question_choice_locator
       * control operator
       */
    }else{
      this.parentForm?.get("user_type")?.setValue(null);
      this.control.get("logic_type_name")?.setValue(null)
      this.control.get("logic_type_name")?.enable()
    }
  }

  raterGroupList = [
    {
      id:"participants",
      name:"Participants",
    },
    {
      id:"non-participants",
      name:"Non-Participants"
    }
  ]
  onRaterGroupSelect(e){

  }


  get isRaterGroup(){
    return this.parentForm && this.parentForm?.get('group_type')?.value == 'rater-group'
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
}
