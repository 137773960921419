<div #sec class="section" [@enterAnimation]>
  <!-- [ngClass]="{ 'section-inative': !uiSelected }" -->
  <div
    *ngIf="!previewMode"
    [ngStyle]="{ 'background-color' : uiSelected? headerColor : convertHex() }"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    class="section-header padding-0"
  >
    <i
      *ngIf="showClose"
      (click)="openDeleteSectionAlert(deleteSectionAlert)"
      class="fas fa-times d-print-none"
    ></i>
    <div
      class="heading-section"
      [ngStyle]="{ 'background-color': headerColor }"
    >
      <div
        *ngIf="!previewMode"
        (click)="toggleSection()"
        class="toggle d-print-none"
      >
        <i
          style="position: relative; top: 3px;"
          *ngIf="open"
          class="fas fa-plus"
        ></i>
        <i *ngIf="!open" class="fas fa-window-minimize"></i>
      </div>
      <span *ngIf="!previewMode" class="caption">
        {{ section.name? section.name : 'Section '+ section.position }}</span
      >
      <fa-icon (click)="editSectionName()" class="edit" [icon]="fapen"></fa-icon>
    </div>

    <app-branch-logic-section-view
      [currentSection]="section"
      [allQuestions]="sections"
    ></app-branch-logic-section-view>
    <!-- <div class="info-box mb-2 pink">
      <div class="toggle data-toggle d-flex align-items-center">
        <div class="t-switch" (click)="ShowBranchBox=!ShowBranchBox">
          <i
            class="fas"
            [ngClass]="ShowBranchBox ? 'fa-angle-down' : 'fa-angle-right'"
          ></i>
        </div>
        <div class="d-flex w-100 align-items-center justify-content-between">
          <div>
            <span>
              <a class="display-logic-view" title="Branch Logic"
                >Branch Logic</a
              >
            </span>
            <span>Branch Logic on this section</span>
          </div>
        </div>
      </div>
      <div class="toggle-data" *ngIf="ShowBranchBox">
        <span class="d-inline-block me-2">If</span>
        <span class="d-inline-block me-2">condition 1</span>
        <span class="d-inline-block me-2">condition 2</span>
        <span class="d-inline-block me-2">condition 3</span>
        <span class="d-inline-block me-2">condition 4</span>
        <span class="d-inline-block me-2">condition 5</span>
      </div>
    </div> -->
  </div>

  <div
    [@enterAnimation]
    *ngIf="!open"
    class="section-content"
    [ngClass]="{
      'content-align-left': questions !== null && questions.length !== 0,
      'with-brd': questions !== null && questions.length == 0 && !previewMode
    }"
  >
    <h3
      class="d-print-none"
      *ngIf="questions !== null && questions.length == 0 && !previewMode"
    >
      Start adding questions.
    </h3>

    <ul
      id="section_{{sectionIndex}}"
      class="survey-builder"
      #list
      *ngIf="!previewMode"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
    >
    <!-- [runInsideAngular]="true" -->
    <!-- [sortablejs]="questions"
      [sortablejsOptions]="sortablejsOptions" -->
      <ng-container *ngFor="let question of questions; let i = index">
        <!-- Decide to display the question or not -->
        <li
          [ngStyle]="{ 'margin-top': questions[i - 1] !== undefined ? '25px' : '0px'}"
          *ngIf="i == 0 && previewMode"
          [ngClass]="{ 'light-header': previewMode && theme === 'default' }"
          style="
            position: relative !important;
            left: -2.5em !important;
            list-style-type: none !important;
            margin-bottom: 30px !important;
            width: calc(100% + 2.5em);
            padding-left: 22px;
          "
          class="section-header"
        ></li>
        <li cdkDrag [cdkDragData]="question" id="question_index_{{i}}">
          <!-- [ngClass] = "question._type == 8? 'descriptiveType_' + sectionIndex + '_' + i  : ''"   -->
          <!-- {{question.position}} -->
          <!-- here -->
          <input
            *ngIf="question.obj_type !== 'pagebrake'"
            [checked]="question?.uiSelected"
            (change)="onCheckedQuestionChanged($event, question, i)"
            type="checkbox"
            class="d-print-none fsop-checkbox qes left-20"

          />
          <!-- name="question-{{ index }}" -->
          <div
            #q
            class="no-pointer-events"
            (click)="selectedQuestionChanged(question, q, sec, i)"
          >
            <ng-container [ngSwitch]="question._type">
              <fsop-multichoice
                [showRequiredAsterisk]="showRequiredAsterisk"
                [showQuestionNumber]="showQuestionNumber"
                [previewMode]="previewMode"
                [question]="question"
                [numbering]="question.numbering"
                *ngSwitchCase="1"
                [ques_update_random]="ques_random"
                [type_name]="'builder'"
                [allQuestions]="sections"
                [surveyData]="surveyData"
              >
              </fsop-multichoice>

              <fsop-text-entry
                [showRequiredAsterisk]="showRequiredAsterisk"
                [showQuestionNumber]="showQuestionNumber"
                [previewMode]="previewMode"
                [question]="question"
                [numbering]="question.numbering"
                *ngSwitchCase="2"
                [allQuestions]="sections"
                [surveyData]="surveyData"
              >
              </fsop-text-entry>

              <fsop-matrix-table
                [showRequiredAsterisk]="showRequiredAsterisk"
                [showQuestionNumber]="showQuestionNumber"
                [previewMode]="previewMode"
                [question]="question"
                [numbering]="question.numbering"
                *ngSwitchCase="3"
                [allQuestions]="sections"
                [surveyData]="surveyData"
              ></fsop-matrix-table>

              <fsop-rank-order
                [showRequiredAsterisk]="showRequiredAsterisk"
                [showQuestionNumber]="showQuestionNumber"
                [previewMode]="previewMode"
                [question]="question"
                [numbering]="question.numbering"
                *ngSwitchCase="4"
                [allQuestions]="sections"
                [surveyData]="surveyData"
              >
              </fsop-rank-order>

              <fsop-dynamic-sum
                [showRequiredAsterisk]="showRequiredAsterisk"
                [showQuestionNumber]="showQuestionNumber"
                [previewMode]="previewMode"
                [question]="question"
                [numbering]="question.numbering"
                *ngSwitchCase="5"
                [allQuestions]="sections"
                [surveyData]="surveyData"
              >
              </fsop-dynamic-sum>

              <fsop-drill-down
                [showRequiredAsterisk]="showRequiredAsterisk"
                [showQuestionNumber]="showQuestionNumber"
                [previewMode]="previewMode"
                [question]="question"
                [numbering]="question.numbering"
                *ngSwitchCase="6"
                [allQuestions]="sections"
                [surveyData]="surveyData"
              >
              </fsop-drill-down>

              <fsop-side-by-side
                [showRequiredAsterisk]="showRequiredAsterisk"
                [showQuestionNumber]="showQuestionNumber"
                [previewMode]="previewMode"
                [question]="question"
                [numbering]="question.numbering"
                *ngSwitchCase="7"
                [allQuestions]="sections"
                [surveyData]="surveyData"
              >
              </fsop-side-by-side>

              <fsop-descriptive-text
                [showQuestionNumber]="showQuestionNumber"
                [previewMode]="previewMode"
                [question]="question"
                [numbering]="question.numbering"
                *ngSwitchCase="8"
                [allQuestions]="sections"
                [surveyData]="surveyData"
              >
              </fsop-descriptive-text>

              <fsop-captcha
                [showRequiredAsterisk]="showRequiredAsterisk"
                [showQuestionNumber]="showQuestionNumber"
                [previewMode]="previewMode"
                [question]="question"
                [numbering]="question.numbering"
                *ngSwitchCase="9"
                [sectionIndex]="sectionIndex"
                [currentPageIndex]="currentPageIndex"
                [allQuestions]="sections"
                [surveyData]="surveyData"
              >
              </fsop-captcha>
            </ng-container>

            <ng-container *ngIf="question.obj_type === 'pagebrake'">
              <div
                style="
                  border: 1px solid #e4d799;
                  align-items: center;
                  display: flex;
                "
              >
                <div class="dashed-seperator"></div>
                <i
                  (click)="removePageBreak(i)"
                  style="
                    position: absolute;
                    right: 3px;
                    cursor: pointer;
                    border: 1px solid gray;
                    border-radius: 50%;
                    padding: 3px 5px;
                    font-size: 10px;
                  "
                  class="fas fa-times"
                ></i>
              </div>
            </ng-container>
          </div>
        </li>
      </ng-container>
    </ul>

    <ul *ngIf="previewMode" class="defaultCursor">
      <ng-container *ngFor="let question of questions; let i = index">
        <!-- *ngIf="(checkSkipQuesHide(question) && showSection(question)&& checkQuesHide(question)) && ((i == 0 && previewMode) || (questions[i-1] && questions[i-1]['section'] != questions[i]['section']))" -->

        <li
          [ngStyle]="{ 'margin-top': questions[i - 1] !== undefined ? '25px' : '0px', 'height': '2rem' ,'color' :customThemeSettings['section_name_font_color']  ? customThemeSettings['section_name_font_color'] : '#a5a5a5' }"
          *ngIf="(showSectionDisplayCondition(question) && showSection(question)) && ((i == 0 && previewMode) || (questions[i-1] && questions[i-1]['section'] != questions[i]['section']))"
          [ngClass]="{
            'light-header': previewMode && theme === 'light',
            'gray-header': (previewMode && theme === 'grey') || (previewMode && theme === 'dark'),
            'no-title': !showSectionName
          }"
          style="
            position: relative !important;
            list-style-type: none !important;
            width: 100%;
            font-size: 16px;
            padding-left: 22px;
          "
          class="section-header"
        >
          {{showSectionName? question.section_name : ''}}
        </li>
        <!-- [ngClass] = "question._type == 8? 'descriptiveType ques_'+ question.section_pos + '_' + question.position  : ''" -->
        <!-- class="left-border-blue" -->
        <li
          #q
          *ngIf="checkSkipQuesHide(question) && checkBranchQuesHide(question) &&  checkQuesHide(question)"
          (click)="selectedQuestionChanged(question, q, sec, i)"
          class="content-background"
          [ngClass]="{
            'light-background': previewMode && theme === 'light',
            'gray-background': (previewMode && theme === 'grey'),
            'dark-background': (previewMode && theme === 'dark')
          }"
          id="question_index_{{i}}"
          [ngStyle]="{
            'border-color':
              (question.error && question.error.hasError) ||
              question.hasCustomValidationError ||
              question.hasMustTotalError ||
              question.hasSameRankError ||
              question.hasLengthValidationError
                ? '#ed4948'
                : '',
            'border-width' :
              (question.error && question.error.hasError) ||
              question.hasCustomValidationError ||
              question.hasMustTotalError ||
              question.hasSameRankError ||
              question.hasLengthValidationError
                ? '2px'
                : '0px 0px 0px 2px'
          }"
        >
          <ng-container [ngSwitch]="question._type">
            <fsop-multichoice
              [showRequiredAsterisk]="showRequiredAsterisk"
              (onQuestionDone)="onQuestionDone($event)"
              [previewMode]="previewMode"
              [isNextButtonClicked]="isNextButtonClicked"
              (resetNextButton)="doResetNextButton($event)"
              [showQuestionNumber]="showQuestionNumber"
              [question]="question"
              [numbering]="question.numbering"
              [backClicked]="backClicked"
              [editable]="editable"
              [customThemeSettings]="customThemeSettings"
              [theme]="theme"
              *ngSwitchCase="1"
              [type_name]="'preview'"
            ></fsop-multichoice>
            <!-- (onOptionSelect)="onOptionSelect($event)" -->
            <fsop-text-entry
              [showRequiredAsterisk]="showRequiredAsterisk"
              [customThemeSettings]="customThemeSettings"
              (onQuestionDone)="onQuestionDone($event)"
              [previewMode]="previewMode"
              [isNextButtonClicked]="isNextButtonClicked"
              (resetNextButton)="doResetNextButton($event)"
              [showQuestionNumber]="showQuestionNumber"
              [question]="question"
              [numbering]="question.numbering"
              [backClicked]="backClicked"
              [editable]="editable"
              [theme]="theme"
              *ngSwitchCase="2"
            ></fsop-text-entry>

            <fsop-matrix-table
              [showRequiredAsterisk]="showRequiredAsterisk"
              [customThemeSettings]="customThemeSettings"
              (onQuestionDone)="onQuestionDone($event)"
              [previewMode]="previewMode"
              [isNextButtonClicked]="isNextButtonClicked"
              (resetNextButton)="doResetNextButton($event)"
              [showQuestionNumber]="showQuestionNumber"
              [question]="question"
              [numbering]="question.numbering"
              [backClicked]="backClicked"
              [editable]="editable"
              [theme]="theme"
              *ngSwitchCase="3"
            ></fsop-matrix-table>

            <fsop-rank-order
              [showRequiredAsterisk]="showRequiredAsterisk"
              [customThemeSettings]="customThemeSettings"
              [theme]="theme"
              (onQuestionDone)="onQuestionDone($event)"
              [previewMode]="previewMode"
              [isNextButtonClicked]="isNextButtonClicked"
              (resetNextButton)="doResetNextButton($event)"
              [showQuestionNumber]="showQuestionNumber"
              [question]="question"
              [numbering]="question.numbering"
              [backClicked]="backClicked"
              [editable]="editable"
              *ngSwitchCase="4"
            ></fsop-rank-order>

            <fsop-dynamic-sum
              [showRequiredAsterisk]="showRequiredAsterisk"
              [customThemeSettings]="customThemeSettings"
              [theme]="theme"
              (onQuestionDone)="onQuestionDone($event)"
              [previewMode]="previewMode"
              [isNextButtonClicked]="isNextButtonClicked"
              (resetNextButton)="doResetNextButton($event)"
              [showQuestionNumber]="showQuestionNumber"
              [question]="question"
              [numbering]="question.numbering"
              [backClicked]="backClicked"
              [editable]="editable"
              *ngSwitchCase="5"
            ></fsop-dynamic-sum>

            <fsop-drill-down
              [showRequiredAsterisk]="showRequiredAsterisk"
              [customThemeSettings]="customThemeSettings"
              [theme]="theme"
              (onQuestionDone)="onQuestionDone($event)"
              [previewMode]="previewMode"
              [showQuestionNumber]="showQuestionNumber"
              [question]="question"
              [numbering]="question.numbering"
              [backClicked]="backClicked"
              [editable]="editable"
              *ngSwitchCase="6"
            ></fsop-drill-down>

            <fsop-side-by-side
              [showRequiredAsterisk]="showRequiredAsterisk"
              [customThemeSettings]="customThemeSettings"
              [theme]="theme"
              (onQuestionDone)="onQuestionDone($event)"
              [previewMode]="previewMode"
              [isNextButtonClicked]="isNextButtonClicked"
              (resetNextButton)="doResetNextButton($event)"
              [showQuestionNumber]="showQuestionNumber"
              [question]="question"
              [numbering]="question.numbering"
              [backClicked]="backClicked"
              [editable]="editable"
              *ngSwitchCase="7"
            ></fsop-side-by-side>

            <fsop-descriptive-text
              (onQuestionDone)="onQuestionDone($event)"
              [customThemeSettings]="customThemeSettings"
              [theme]="theme"
              [previewMode]="previewMode"
              [showQuestionNumber]="showQuestionNumber"
              [question]="question"
              [numbering]="question.numbering"
              *ngSwitchCase="8"
            ></fsop-descriptive-text>

            <fsop-captcha
              [showRequiredAsterisk]="showRequiredAsterisk"
              [customThemeSettings]="customThemeSettings"
              [theme]="theme"
              (onQuestionDone)="onQuestionDone($event)"
              [previewMode]="previewMode"
              [showQuestionNumber]="showQuestionNumber"
              [question]="question"
              [numbering]="question.numbering"
              *ngSwitchCase="9"
              [sectionIndex]="sectionIndex"
              [currentPageIndex]="currentPageIndex"
            ></fsop-captcha>
          </ng-container>

          <ng-container>
            <div
              style="
                width: 100%;
                height: 20px;
                margin-top: 10px;
                border: 1px solid green;
              "
              *ngIf="question.obj_type === 'pagebrake'"
            >
              <div
                style="
                  height: 10px;
                  width: 95%;
                  border-top: 5px dashed black;
                  margin-top: 5px;
                  display: inline-block;
                  position: relative;
                  float: left;
                "
              ></div>
              <i
                (click)="removePageBreak(i)"
                style="
                  float: right;
                  cursor: pointer;
                  position: relative;
                  display: inline-block;
                "
                class="fas fa-times"
              ></i>
            </div>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<ng-template #deleteSectionAlert class="no-border">
  <app-alert [content]="alertContent"></app-alert>
</ng-template>
