import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "fsop-descriptive-text",
  templateUrl: "./fsop-descriptive-text.component.html",
  styleUrls: ["./fsop-descriptive-text.component.scss"],
})
export class FsopDescriptiveTextComponent implements OnInit, AfterViewInit {
  @Output("onQuestionDone")
  onQuestionDone = new EventEmitter<any>();

  @Input("numbering")
  numbering: number;
  @Input("from") from = "";
  @Input("customThemeSettings") customThemeSettings: object;

  @Input("theme")
  theme: string;

  @Input("previewMode")
  previewMode: boolean;

  @Input("showQuestionNumber")
  showQuestionNumber: boolean;

  @Input("question")
  question: any;
  mobileView: boolean = false;
  @Input() allQuestions: any;
  @Input() surveyData: any;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    if (window.outerWidth < 769) {
      this.mobileView = true;
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (
        window.name &&
        window.name == "frameAndHashing" &&
        window.innerWidth < 769
      ) {
        this.mobileView = true;
      }
    }, 100);
  }
  tooltipClick(event: any) {
    event.stopPropagation();
  }
  openModal(e: any, content: any) {
    this.modalService.open(content, {
      size: "sm",
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "tooltip-modal",
    });
  }

  checkForZero(value: any, zeroReturnValue: any, defaultValue: any) {
    if (value == 0) {
      return zeroReturnValue;
    } else {
      return defaultValue;
    }
  }

  getQuestionNumbering(numbering: any) {
    return `Q${numbering}<sup
    style="color: red;"
    *ngIf="showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
    >*</sup
  >`;
  }

  getQuestionNumberingWithoutAsterick(numbering: any) {
    return `Q${numbering}`;
  }

  displayLogic: any = [];
  displayLogicUpdated(event: any) {
    this.displayLogic = event;
  }

  displayLogicClicked: any = false;
  displayLogicClickedEvent(event: any) {
    this.displayLogicClicked = event;
  }
}
