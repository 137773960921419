import { Injectable } from '@angular/core';
import { HttpService } from './http/http.service';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Page } from '../models/data-table-page.model';
import { IRole } from '../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleDataService {

  constructor(private httpService: HttpService) { }

   /**
    * Get all roles
    */
  public getRoles(pageObj?: Page,filter?:any): Observable<any> {
    let queryStatement = '?';
    if(pageObj){
      queryStatement += `page=${pageObj.pageNumber}&page_size=${pageObj.size}`;
    }
    if(filter && filter.search){
      queryStatement += `&search=${filter.search}`;
    }
    return this.httpService.get(environment.BASE_URL + `roles/`+queryStatement);
  }

  /**
   *
   * @param role role object to add
   */
  public addRole(role: IRole): Observable<any> {
    return this.httpService.post(environment.BASE_URL + 'roles/', role);
  }


  /**
   * @param rolesEntities  array of the roles url that should be updated.
   * @param action the action that wil be applied on the role activate/deactivate
   * @returns Http observable response
   */
  public updateRolesStatus(rolesEntities: string[], action: string): Observable<any> {
    return this.httpService.post(environment.BASE_URL + `roles/${action.toLowerCase()}/`, {roles : rolesEntities});
  }

  public editRole(updatedRole: IRole): Observable<any> {
    return this.httpService.put( environment.BASE_URL + 'roles/' + updatedRole.id + '/', updatedRole);
  }

  /**
   * Get permissions group to be listed in add role component
   */
  public getPermissionsGroup(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + 'permissions/group/');
  }

  public getSpecificRole(roleId: string): Observable<any> {
    return this.httpService.get( environment.BASE_URL + 'roles/' + roleId + '/');
  }

  /**
   * check if role is in use
   */

   public checkIfRoleInUse(roldId: string): Observable<any> {
     return this.httpService.head( environment.BASE_URL + 'role-in-use/?role=' + roldId);
   }

   public deleteRole(roleId: string): Observable<any> {
     return this.httpService.delete( environment.BASE_URL + 'roles/' + roleId + '/');
   }

     public getRoleByUrl(role_endpoint: string): Observable<any> {
        return this.httpService.get(role_endpoint);
  }

}
