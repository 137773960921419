import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AssessmentDataService } from "../../../core/assessment-data.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
declare const tinymce: any;
declare var $: any;
@Component({
  selector: "app-new-rater-modal",
  templateUrl: "./app-new-rater-modal.component.html",
  styleUrls: ["./app-new-rater-modal.component.scss"],
})
export class NewRaterModalComponent implements OnInit {
  @Output()
  onCloseModalTriggered: EventEmitter<any> = new EventEmitter();

  @Output()
  submitTriggered: EventEmitter<any> = new EventEmitter();

  @Input("selectedRole") set selectedRoleValue(val: any) {
    if (this.customRaterForm && val) {
      this.id = val.id;
      this.customRaterForm.patchValue(val);
    }
  }

  @Input() contactRoles: any;
  @Input() selectedRow: any;
  id: any;
  customRaterForm: FormGroup;
  isSubmitted: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private assessmentDataService: AssessmentDataService,
    private toastrService: ToastrService
  ) {
    this.createForm();
  }
  ngOnInit() {}

  save() {
    this.isSubmitted = true;
    // if (
    //   this.contactRoles &&
    //   !this.id &&
    //   this.contactRoles.length &&
    //   this.contactRoles.length == 19
    // ) {
    //   this.toastrService.error("Maximum 19 raters are allowed.");
    //   return;
    // }
    this.submitTriggered.next({
      ...this.customRaterForm.value,
      id: this.id,
      row: this.selectedRow,
    });
    setTimeout(() => {
      this.onCloseModalTriggered.next(true);
    }, 300);
  }

  closeModal() {
    this.onCloseModalTriggered.next(true);
  }

  createForm() {
    this.customRaterForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(30)]],
    });
  }
}
