<div class="info-box mb-2 purple" *ngIf="conditionsArr && conditionsArr.length">
  <span
    class="box-icon d-flex align-items-center justify-content-center"
    [ngStyle]="{'background-color': isInvalid() ? '#FF6961' : '' }"
  >
    <i class="svg skipLogic-icon"></i>
  </span>
  <div class="toggle d-flex align-items-center">
    <div class="t-switch" (click)="ShowSkipBox=!ShowSkipBox">
      <i
        class="fas"
        [ngClass]="ShowSkipBox ? 'fa-angle-down' : 'fa-angle-right'"
      ></i>
    </div>
    <div class="d-flex w-100 align-items-center justify-content-between">
      <div>
        <span>Skip to</span>
      </div>
      <div class="nav-item dropdown">
        <div class="nav-item dropdown">
          <div
            role="button"
            data-bs-toggle='dropdown'
            aria-haspopup="true"
            aria-expanded="false"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-ellipsis-h"></i>
          </div>
          <div
            class="dropdown-menu"
            role="menu"
            style="padding: 0; right: 0px; left: inherit;"
          >
            <a class="dropdown-item" (click)="openSkipLogicModal()">Edit</a>
            <!-- *ngIf="showDeleteOption()" -->
            <a class="dropdown-item" (click)="openConfirmModal(deleteModal)"
              >Remove</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="toggle-data d-flex flex-wrap" *ngIf="ShowSkipBox">
    <!-- <span class="d-inline-block me-2" *ngIf="!isInvalid()">If</span> -->
    <!-- dynamic -->
    <ng-container *ngFor="let condition of conditionsArr;let i=index">
      <ng-container *ngIf="!condition.invalid">
        <span class="me-1" *ngIf="condition.conjuction"
          >{{condition.conjuction}}</span
        >

        <span style="max-width: 300px" class="me-1 text-truncate" placement="bottom" [ngbTooltip]="UploadCerttipContent" *ngIf="i==0"
          >{{condition.question_choosen}}</span>
        <ng-template #UploadCerttipContent>{{condition.question_choosen}}</ng-template>
        <span class="me-1" *ngIf="!isInvalid() && i==0">If</span>
        <span class="me-1" *ngIf="condition.option_choosen"
          >{{condition.option_choosen}}</span
        >
        <span class="me-1" *ngIf="condition.operator"
          >{{condition.operator}}</span
        >
        <span class="me-1" *ngIf="condition.operator_value"
          >{{condition.operator_value}}</span
        >
      </ng-container>

      <ng-container *ngIf="condition.invalid && i==(conditionsArr.length-1)">
        <small class="text-danger-pastel d-inline-block me-2"
          >Invalid Logic</small
        >
      </ng-container>
      <ng-container *ngIf="condition.msg && i==(conditionsArr.length-1)">
        <small class="text-danger d-inline-block me-2">{{condition.msg}}</small>
      </ng-container>
    </ng-container>
  </div>

  <ng-template #skipLogicModal let-modal class="no-border">
    <app-common-logic
      [currentQuestion]="currentQuestion"
      [allQuestions]="allQuestions"
      (onCloseModalTriggered)="closeSkipLogicModal()"
      (submitTriggered)="submitLogic($event)"
      [surveyData]="surveyData"
    ></app-common-logic>
  </ng-template>
</div>

<ng-template #deleteModal let-modal class="no-border">
  <app-confirm-modal
    [title]="'Alert'"
    [buttonName]="'Confirm'"
    [message]="'Are you sure you want to delete this logic?'"
    (onCloseModalTriggered)="closeConfirmModal()"
    (submitTriggered)="delete()"
  ></app-confirm-modal>
</ng-template>
