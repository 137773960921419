import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// import { SurveyBuilderComponent } from './survey-builder/survey-builder.component';
// import { SurveyReportComponent } from './survey-report/survey-report.component';
import { SurveyPreviewComponent } from "./survey-preview.component";
import { CheckIfSurveyLaunchedGuard } from "../../../app/core/compaign-dashboard.guard";

const routes: Routes = [
  { path: "survey/:id/preview", component: SurveyPreviewComponent }, // , canActivate: [CheckIfSurveyLaunchedGuard]
  { path: "survey/:id/resp", component: SurveyPreviewComponent },
  {
    path: "survey/:id/responses/:response_id",
    component: SurveyPreviewComponent,
  },
  {
    path: "survey/:id/resp/survey_email/:survey_email_id/token/:token",
    component: SurveyPreviewComponent,
  },
  {
    path: "survey/:id/responses/:response_id/:rtToken",
    component: SurveyPreviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SurveyPreviewRoutingModule {}
