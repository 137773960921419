<div class="no-border">
  <div class="modal-content">
    <div class="fsop-alert-modal">
      <span>{{title}}</span>
    </div>
    <div class="fsop-alert-modal-body">
      <p>
        {{description}}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="modal.close()">{{confirmButtonText}}</button>
    <button class="btn btn-secondary" (click)="modal.dismiss()">{{cancelButtonText}}</button>
  </div>
</div>
