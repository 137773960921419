import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ApplicationRef,
} from "@angular/core";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  RouterEvent,
  NavigationStart,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { interval, merge, Subscription } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import { environment } from "../environments/environment";
import { Logger, I18nService } from "../app/core";
import { AssessmentDataService } from "./core/assessment-data.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "../app/core/authentication/authentication.service";
import { EmailTimerService } from "../app/core/email-timer.service";
import * as $ from "jquery";
import { SwUpdate } from "@angular/service-worker";

const log = new Logger("App");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  showFooter!: boolean;

  isLoading = { bool: false };

  public subscription: Subscription;
  sessionTimeoutSubscription!: Subscription;
  @ViewChild("sessionTimeoutModal", { static: false }) sessionTimeoutModal: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private modalService: NgbModal,
    private assessmentDataService: AssessmentDataService,
    private authenticationService: AuthenticationService,
    private emailTimerService: EmailTimerService,
    private update: SwUpdate,
    private appRef: ApplicationRef
  ) {
    this.updateClient();
    // let v = new Date().valueOf() + "";
    // sessionStorage.setItem("tabId", v);
    router.events.subscribe((event: any) => {
      this.navigationInterceptor(event);
    });

    this.subscription = this.assessmentDataService.loaderSubject.subscribe(
      (data) => {
        setTimeout(()=>{
          this.isLoading = data;
        })
      }
    );
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.isLoading = { bool: true };
      this.modalService.dismissAll();
    }
    if (event instanceof NavigationEnd) {
      this.isLoading = { bool: false };
      window.scroll(0, 0);
    }

    if (event instanceof NavigationCancel) {
      this.isLoading = { bool: false };
    }
    if (event instanceof NavigationError) {
      this.isLoading = { bool: false };
    }
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    this.sessionTimeoutSubscription &&
      this.sessionTimeoutSubscription.unsubscribe();
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }
    if (
      !localStorage.getItem("-v") ||
      (localStorage.getItem("-v") &&
        localStorage.getItem("-v") != environment.deployment_version)
    ) {
      localStorage.setItem("-v", environment.deployment_version);
      window.location.replace(window.location.href);
    }

    log.debug("init");
    localStorage.removeItem("ratee");
    localStorage.removeItem("task_id");

    // Setup translations
    this.i18nService.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    );

    const onNavigationEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        const title = event["title"];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    var _this = this;

    // let routes_disabled = ['login', 'forgot-password', 'resend-password-instruction', 'set-new-password', 'resp']

    $(document).mousemove((e: any) => {
      if (
        window.location.href.includes("/login") ||
        window.location.href.includes("/forgot-password") ||
        window.location.href.includes("/resend-password-instruction") ||
        window.location.href.includes("/set-new-password") ||
        window.location.href.includes("/resp")
      ) {
      } else {
        _this.authenticationService.setExpiryTime();
      }
    });
    $(document).mouseup((e: any) => {
      if (
        window.location.href.includes("/login") ||
        window.location.href.includes("/forgot-password") ||
        window.location.href.includes("/resend-password-instruction") ||
        window.location.href.includes("/set-new-password") ||
        window.location.href.includes("/resp")
      ) {
      } else {
        _this.authenticationService.setExpiryTime();
      }
    });
    $(document).on("wheel", (e: any) => {
      if (
        window.location.href.includes("/login") ||
        window.location.href.includes("/forgot-password") ||
        window.location.href.includes("/resend-password-instruction") ||
        window.location.href.includes("/set-new-password") ||
        window.location.href.includes("/resp")
      ) {
      } else {
        _this.authenticationService.setExpiryTime();
      }
    });
    $(document).keypress((e: any) => {
      if (
        window.location.href.includes("/login") ||
        window.location.href.includes("/forgot-password") ||
        window.location.href.includes("/resend-password-instruction") ||
        window.location.href.includes("/set-new-password") ||
        window.location.href.includes("/resp")
      ) {
      } else {
        _this.authenticationService.setExpiryTime();
      }
    });

    document.addEventListener("visibilitychange", function (e) {
      if (
        window.location.href.includes("/login") ||
        window.location.href.includes("/forgot-password") ||
        window.location.href.includes("/resend-password-instruction") ||
        window.location.href.includes("/set-new-password") ||
        window.location.href.includes("/resp")
      ) {
      } else {
        let _date = new Date(localStorage.getItem("_expiry") as string);
        if (_date.getTime() <= Date.now()) {
          //abc
          _this.authenticationService.logout();
          _this.unsubscribeAll();
          _this.router.navigate(["/login"], {
            queryParams: !window.location.search
              ? { redirect: _this.router.url }
              : {},
            replaceUrl: true,
          });
        }
      }
    });

    this.sessionTimeoutSubscription = this.authenticationService.session_timeout_Subject.subscribe(
      (data: any) => {
        if (data) {
          if (!document.getElementsByClassName("sessionTimeoutPopup").length) {
            this.modalService.open(this.sessionTimeoutModal, {
              size: "sm",
              centered: true,
              backdrop: "static",
              keyboard: false,
              windowClass: "modal-holder sessionTimeoutPopup",
            });
          }
        }
      }
    );
  }

  updateClient() {
    if (!this.update.isEnabled) {
      console.log("not enabled")
      return;
    }
    // this.update.available.[_isScalar] = true;
    this.update.available.subscribe((event) => {
      console.log('current', event.current, 'avalable', event.available)

      this.update.activateUpdate().then(() => location.reload());

    });

    // this.update.activated._isScalar = true;
    this.update.activated.subscribe((event) => {
      console.log('current', event.previous, 'available', event.current)
    });

  }

  checkUpdate() {

    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(8 * 60 * 60 * 1000);

        timeInterval.subscribe(() => {
          this.update.checkForUpdate().then(() => console.log('checked'));
          console.log('checked')

        }
        );
      }
    }
    )



  }

  loginRoute() {
    return window.location.origin + "/login";
  }

  unsubscribeAll() {
    this.emailTimerService.setTimerState(null);
    localStorage.removeItem("ratee");
    localStorage.removeItem("task_id");
  }
}
