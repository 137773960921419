import { BranchLogicComponent } from "./logic-work/branch-logic/app-branch-logic.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FsopDescriptiveTextComponent } from "./fsop-descriptive-text/fsop-descriptive-text.component";
import { FsopDrillDownComponent } from "./fsop-drill-down/fsop-drill-down.component";
import { FsopDynamicSumComponent } from "./fsop-dynamic-sum/fsop-dynamic-sum.component";
import { FsopMatrixTableComponent } from "./fsop-matrix-table/fsop-matrix-table.component";
import { FsopMultichoiceComponent } from "./fsop-multichoice/fsop-multichoice.component";
import { FsopRankOrderComponent } from "./fsop-rank-order/fsop-rank-order.component";
import { FsopSideBySideComponent } from "./fsop-side-by-side/fsop-side-by-side.component";
import { FsopTextEntryComponent } from "./fsop-text-entry/fsop-text-entry.component";
import { CoreModule } from "../../../core/core.module";
// import { SortablejsModule } from "ngx-sortablejs";
import { ClickOutsideModule } from "ng-click-outside";
import { FsopCaptchaComponent } from "./fsop-captcha/fsop-captcha.component";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DisplayLogicComponent } from "./logic-work/display-logic/app-display-logic.component";
import { DisplayLogicRowComponent } from "./logic-work/display-logic/display-logic-row/app-display-logic-row.component";
import { DisplayLogicViewComponent } from "./logic-work/display-logic-view/app-display-logic-view.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { ConfirmModalComponent } from "./logic-work/confirm-modal/app-confirm-modal.component";
import { SkipLogicComponent } from "./logic-work/skip-logic/app-skip-logic.component";
import { SkipLogicRowComponent } from "./logic-work/skip-logic/skip-logic-row/app-skip-logic-row.component";
import { SkipLogicViewComponent } from "./logic-work/skip-logic/skip-logic-view/app-skip-logic-view.component";
import { BranchLogicRowComponent } from "./logic-work/branch-logic/branch-logic-row/app-branch-logic-row.component";
import { BranchLogicViewComponent } from "./logic-work/branch-logic/branch-logic-view/app-branch-logic-view.component";
import { BranchLogicSectionViewComponent } from "./logic-work/branch-logic/branch-section-logic-view/app-branch-logic-section-view.component";
import { CommonLogicComponent } from "./logic-work/common-logic/app-common-logic.component";
import { CommonLogicRowComponent } from "./logic-work/common-logic/common-logic-row/app-common-logic-row.component";
import { CommonLogicRowChildComponent } from "./logic-work/common-logic/common-logic-row-child/app-common-logic-row-child.component";
import { PerformActionsChildComponent } from "./logic-work/common-logic/perform-actions-child/app-perform-actions-child.component";
import { CdkDrag, CdkDropList } from "@angular/cdk/drag-drop";
// import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [
    FsopDescriptiveTextComponent,
    FsopDrillDownComponent,
    FsopDynamicSumComponent,
    FsopMatrixTableComponent,
    FsopMultichoiceComponent,
    FsopRankOrderComponent,
    FsopSideBySideComponent,
    FsopTextEntryComponent,
    FsopCaptchaComponent,
    DisplayLogicComponent,
    DisplayLogicRowComponent,
    CommonLogicComponent,
    CommonLogicRowComponent,
    CommonLogicRowChildComponent,
    PerformActionsChildComponent,
    DisplayLogicViewComponent,
    SkipLogicViewComponent,
    ConfirmModalComponent,
    SkipLogicComponent,
    SkipLogicRowComponent,
    BranchLogicComponent,
    BranchLogicRowComponent,
    BranchLogicViewComponent,
    BranchLogicSectionViewComponent,
  ],
  exports: [
    FsopDescriptiveTextComponent,
    FsopDrillDownComponent,
    FsopDynamicSumComponent,
    FsopMatrixTableComponent,
    FsopMultichoiceComponent,
    FsopRankOrderComponent,
    FsopSideBySideComponent,
    FsopTextEntryComponent,
    FsopCaptchaComponent,
    DisplayLogicComponent,
    DisplayLogicRowComponent,
    DisplayLogicViewComponent,
    CommonLogicComponent,
    CommonLogicRowComponent,
    CommonLogicRowChildComponent,
    PerformActionsChildComponent,
    SkipLogicViewComponent,
    SkipLogicComponent,
    SkipLogicRowComponent,
    BranchLogicComponent,
    BranchLogicRowComponent,
    BranchLogicViewComponent,
    BranchLogicSectionViewComponent,
    ConfirmModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    FormsModule,
    //SortablejsModule.forRoot({
//      animation: 200,
//    }),
    CoreModule,
    NgbDatepickerModule,
    NgbModule,
    NgSelectModule,
    CdkDrag, 
    CdkDropList,
    // NgxCaptchaModule
  ],
  providers: [],
})
export class QuestionsDirectivesModule {}
