import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
  AfterViewInit,
} from "@angular/core";
import { AssessmentDataService } from "../../../../../app/core/assessment-data.service";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "fsop-side-by-side",
  templateUrl: "./fsop-side-by-side.component.html",
  styleUrls: ["./fsop-side-by-side.component.scss"],
})
export class FsopSideBySideComponent implements OnInit, AfterViewInit {
  okButtonShow = false;
  @Output("onQuestionDone")
  onQuestionDone = new EventEmitter<any>();

  @Input("question")
  question: any;

  @Input("showQuestionNumber")
  showQuestionNumber: boolean;

  @Input("numbering")
  numbering: number;

  @Input("customThemeSettings") customThemeSettings: object;
  @Input("theme")
  theme: string;
  @Input("from") from = "";
  @Input("previewMode")
  previewMode: boolean;

  customQuestionError: string = "";

  @Input("isNextButtonClicked")
  isNextButtonClicked: boolean;

  @Input() allQuestions: any;
  @Input() surveyData: any;

  @Output("resetNextButton")
  resetNextButton = new EventEmitter<boolean>();

  @Input("showRequiredAsterisk") showRequiredAsterisk: boolean;

  @Input("editable") editable: boolean;
  @Input("backClicked") backClicked: boolean;
  mobileView: boolean = false;

  constructor(private modalService: NgbModal, public assessmentDataService: AssessmentDataService) {}

  ngOnInit() {
    if (window.outerWidth < 769) {
      this.mobileView = true;
    }
    if (this.previewMode) {
      if (
        !this.question["answer"] ||
        (this.question.answer &&
          (this.question.answer.length <
            this.question.question_options.rows.length *
              this.question.question_options.cols.length ||
            this.question.answer.filter((obj: any) => {
              return !obj["answer"].length;
            }).length != 0))
      ) {
        // if (!this.question['answer']) {
        this.question.answer = [];
        // }
        this.question.question_options.rows.forEach((row: any) => {
          this.question.question_options.cols.forEach((col: any) => {
            let _obj = {
              row: row.url,
              column: col.url,
            };
            _obj["answer"] = [];
            if (
              this.question["answer"].filter((ans: any) => {
                return ans.row == row.url && ans.column == col.url;
              }).length == 0
            ) {
              this.question["answer"].push(_obj);
            }
          });
        });
      } else {
        setTimeout(() => {
          this.backClicked = true;
        }, 500);
      }
    }
  }

  tooltipClick(event: any) {
    event.stopPropagation();
  }

  checkForZero(value: any, zeroReturnValue: any, defaultValue: any) {
    if (value == 0) {
      return zeroReturnValue;
    } else {
      return defaultValue;
    }
  }

  onOkClick(){
    this.assessmentDataService.focusAnswerOkClicked$.next(true);
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (this.previewMode) {
      const nextClickedChange: SimpleChange = changes.isNextButtonClicked;
      this.isNextButtonClicked =
        nextClickedChange && nextClickedChange.currentValue;

      if (this.isNextButtonClicked) {
        this.checkCustomValidation(this.question);
      }
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (
        window.name &&
        window.name == "frameAndHashing" &&
        window.innerWidth < 769
      ) {
        this.mobileView = true;
      }
    }, 100);
  }
  openModal(e: any, content: any) {
    this.modalService.open(content, {
      size: "sm",
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "tooltip-modal",
    });
  }
  private updateQuestionAsnwer(question: any) {
    if (this.previewMode) {
      this.okButtonShow = true;
      if (
        question.answer &&
        question.answer.filter((ans: any) => {
          return !ans.answer.length;
        }).length == 0
      ) {
        question["answered"] = true;
      } else {
        question["answered"] = false;
      }

      this.question["answer"] = question.answer;
      this.onQuestionDone.emit(question);
    }
  }

  public radioOptionChecked(event: any, row: any, col: any, subcol: any): void {
    this.resetNextButton.emit(true);
    let checkedAnswer: any = {
      row: row.url,
      column: col.url,
      answer: [subcol.url],
    };

    var handleEventChange = (q: any) => {
      let fetchedAnswer = q.answer.filter((a: any) => {
        return a && a.row == row.url && a.column == col.url;
      });

      if (fetchedAnswer.length > 0) {
        fetchedAnswer[0].answer = [subcol.url];
      } else {
        q.answer.push(checkedAnswer);
      }

      this.updateQuestionAsnwer(q);
    };

    handleEventChange(this.question);
  }

  public checkboxOptionChecked(
    event: any,
    row: any,
    col: any,
    subcol: any
  ): void {
    this.resetNextButton.emit(true);
    let checkedAnswer: any = {
      row: row.url,
      column: col.url,
      answer: [subcol.url],
    };

    if (event.target.checked) {
      var handleOnCheckedEvent = (q: any) => {
        let fetchedAnswer = q.answer.find((answer: any) => {
          return (
            answer.row === checkedAnswer.row &&
            answer.column === checkedAnswer.column
          );
        });

        if (!fetchedAnswer) {
          q.answer.push(checkedAnswer);
        } else {
          q.answer[q.answer.indexOf(fetchedAnswer)].answer.push(subcol.url);
        }

        this.updateQuestionAsnwer(q);
      };

      handleOnCheckedEvent(this.question);
    } else {
      var handleOnUncheckEvent = (q: any) => {
        let fetchedAnswer = q.answer.find((answer: any) => {
          return (
            answer.row === checkedAnswer.row &&
            answer.column === checkedAnswer.column
          );
        });

        if (fetchedAnswer !== undefined) {
          let fetchedChoice = fetchedAnswer.answer.find((a: any) => {
            return a === subcol.url;
          });
          if (fetchedChoice !== undefined) {
            let choiceIndex = fetchedAnswer.answer.indexOf(fetchedChoice);
            fetchedAnswer.answer.splice(choiceIndex, 1);
          }
        }

        this.updateQuestionAsnwer(q);
      };

      handleOnUncheckEvent(this.question);
    }
  }

  onSelectBoxClick(event:any){
    event.stopPropagation();
  }

  onSelectedOptionChanged(event: any, row: any, col: any): void {
    this.resetNextButton.emit(true);

    let choice = event.target.value;
    let selectedAnswer: any = {
      row: row.url,
      column: col.url,
      answer: [choice],
    };

    var handleSelectEvent = (q: any) => {
      if (q.answer !== null && q.answer !== undefined) {
        let fetchedAnswer = q.answer.filter((a: any) => {
          return a != undefined && a.row == row.url && a.column == col.url;
        });

        if (fetchedAnswer.length > 0) {
          fetchedAnswer[0].answer = [choice];
        } else {
          q.answer.push(selectedAnswer);
        }
      }

      this.updateQuestionAsnwer(q);
    };

    handleSelectEvent(this.question);
  }

  isCustomized(ques: any) {
    let is_randomize = false;
    // ques.answer.forEach((ans: any, index: any)=>{
    //   // debugger;
    //   if(ans.answer.length > 0){
    //     is_randomize = true
    //   }
    // })
    for (let index = 0; index < ques.answer.length; index++) {
      const element = ques.answer[index];
      if (element.answer.length > 0) {
        is_randomize = true;
        break;
      }
    }
    return is_randomize;
  }

  private checkCustomValidation(question: any): void {
    if (this.question.validation_option.is_custom_validation) {
      if (
        this.question.validation_option.validation == "0" &&
        !this.isCustomized(question)
      ) {
        this.customQuestionError = "";
        delete this.question["hasCustomValidationError"];
        return;
      }
      if (
        this.question.validation_option.validation != "2" ||
        (this.question.validation_option.validation == "2" &&
          this.isCustomized(question))
      ) {
        let concernedRow = this.question.question_options.rows[
          this.question.validation_option.row - 1
        ];
        let concernedCol = this.question.question_options.cols[
          this.question.validation_option.col - 1
        ];
        let concernedAns =
          concernedCol.col_choices[this.question.validation_option.choice - 1];

        let requiredAnswer = [];
        if (question.answer !== null) {
          requiredAnswer = question.answer.filter((answer: any) => {
            return (
              answer.row == concernedRow.url &&
              answer.column == concernedCol.url &&
              answer.answer.includes(concernedAns.url)
            );
          });
        }

        if (requiredAnswer.length < 1) {
          this.customQuestionError = this.question.validation_option.msg;
          this.question["hasCustomValidationError"] = true;
        } else {
          this.customQuestionError = "";
          delete this.question["hasCustomValidationError"];
        }
      } else {
        this.customQuestionError = "";
        delete this.question["hasCustomValidationError"];
      }
    }
  }

  getQuestionNumbering(numbering: any) {
    return `Q${numbering}<sup
    style="color: red;"
    *ngIf="showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
    >*</sup
  >`;
  }

  getQuestionNumberingWithoutAsterick(numbering: any) {
    return `Q${numbering}`;
  }

  public isChecked(row: any, col: any, subcol: any) {
    if (this.previewMode) {
      var handleCheckedRow = (q: any) => {
        let fetchedAnswer = q.answer.find((a: any) => {
          return (
            a.row === row.url &&
            a.column === col.url &&
            a.answer.includes(subcol.url)
          );
        });

        return fetchedAnswer !== undefined;
      };
      return handleCheckedRow(this.question);
    }
  }

  checkRelatedInput(
    event: any,
    row: any,
    col: any,
    subcol: any,
    rowIndex: any,
    colIndex: any,
    optionIndex: any
  ) {
    if (event && event.target.tagName.toLowerCase() == "input") {
      event.stopPropagation();
    } else {
      if (this.question.option_type == 1) {
        this.radioOptionChecked(event, row, col, subcol);
      } else {
        $(
          "#answer_" +
            this.question.id +
            "_" +
            rowIndex +
            "_" +
            colIndex +
            "_" +
            optionIndex
        ).trigger("click");
      }
    }
  }

  displayLogic: any = [];
  displayLogicUpdated(event: any) {
    this.displayLogic = event;
  }

  displayLogicClicked: any = false;
  displayLogicClickedEvent(event: any) {
    this.displayLogicClicked = event;
  }
}
