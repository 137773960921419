import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { Logger } from "../logger.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SuperadminService } from "../authentication/superadmin.service";

const log = new Logger("ErrorHandlerInterceptor");

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: "root",
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private superadminService: SuperadminService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((error) => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: any): Observable<HttpEvent<any>> {
    if (response["status"] == 401) {
      // || response['status'] == 500
      // response['statusText'].toLowerCase() === "unauthorized"
      this.toastrService.warning(
        "Session Timeout! Please login again to continue."
      );
      // debugger;
      localStorage.clear();
      localStorage.setItem("-v", environment.deployment_version);
      setTimeout(() => {
        window.location.replace(
          `${window.location.origin}/login?redirect=${window.location.pathname}`
        );
      }, 500);
    } else if (response["status"] == 429) {
      // || response['status'] == 500
      // response['statusText'].toLowerCase() === "unauthorized"
      this.toastrService.warning(
        "Request was throttled. Expected available in 1.0 minuete."
      );
      // debugger;
      // localStorage.clear();
      // localStorage.setItem("-v", environment.deployment_version);
      setTimeout(() => {
        // window.location.replace(
        //   `${window.location.origin}/login?redirect=${window.location.pathname}`
        // );
      }, 500);
    } else if (response["status"] == 403) {
      // || response['status'] == 500
      // response['statusText'].toLowerCase() === "unauthorized"
      try {
        this.toastrService.warning(response["error"]["error_msg"]);
      } catch (err) {
        this.toastrService.warning("Something went wrong.");
      }
    } else if (response["status"] == 400) {
      if (
        this.superadminService.isSuperAdminLogged != -1 &&
        this.superadminService.isSuperAdminLogged !=
          localStorage.getItem("is_superadmin")
      ) {
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem("-v", environment.deployment_version);
        setTimeout(() => {
          this.toastrService.warning("Please login again");
          window.location.replace(
            `${window.location.origin}/login?redirect=${window.location.pathname}`
          );
        }, 500);
      }
    } else if (response["status"] == 404) {
      // this.toastrService.warning("Something went wrong.");
    }
    if (!environment.production) {
      // Do something with the error
      log.error("Request error", response);
    }
    throw response;
  }
}
