<ng-select
  [items]="filteredTemplates$ | async"
  bindLabel="name"
  dropdownPosition="auto"
  placeholder="Select Template"
  [(ngModel)]="templateName"
  [ngModelOptions]="{ standalone: true }"
  [loading]="searchLoading"
  [typeahead]="typedValue$"
  (change)="onTemplateSelect($event)"
  (clear)="itemCleared()"
  (blur)="getBlurValue()"
>
</ng-select>
