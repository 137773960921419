import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AssessmentDataService } from "../../../../../app/core/assessment-data.service";
import $ from 'jquery';
import { DataShareService } from "../../../../data-share.service";

@Component({
  selector: "fsop-multichoice",
  templateUrl: "./fsop-multichoice.component.html",
  styleUrls: ["./fsop-multichoice.component.scss"],
})
export class FsopMultichoiceComponent implements OnInit, AfterViewInit {
  option
  // temp. solution option;
  okButtonShow = false;
  @Output("onQuestionDone")
  onQuestionDone: EventEmitter<any> = new EventEmitter<any>();

  @Output("onOptionSelect")
  onOptionSelect: EventEmitter<any> = new EventEmitter<any>();

  @Input() allQuestions: any;
  @Input("type_name") type_name: string;
  @Input("customThemeSettings") customThemeSettings: object;

  @Input("theme")
  theme: string;

  @Input("question")
  question: any;

  @Input("ques_update_random") set quesRandomNum(val: any) {
    if (val) {
      this.ques_update_random = val;
      this.getColValue();
    }
  }
  ques_update_random: any;

  public answers: any[] = [];
  public customQuestionError: string = "";
  @Input("previewMode")
  previewMode: boolean;

  @Input("numbering")
  numbering: number;

  @Input("showQuestionNumber")
  showQuestionNumber: boolean;

  @Input("isNextButtonClicked")
  isNextButtonClicked: boolean;

  @Output("resetNextButton")
  resetNextButton = new EventEmitter<boolean>();

  questionAnswer: any;

  @Input("showRequiredAsterisk") showRequiredAsterisk: boolean;
  @Input() surveyData: any;

  @Input("editable") editable: boolean;
  @Input("backClicked") backClicked: boolean;
  @Input("from") from = "";
  mobileView: boolean = false;
  col_arr: any = [];
  col_splice_index_arr: any = [];
  showOtherTextBox: boolean = false;
  emitted_val: any;
  constructor(
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private assessmentDataService: AssessmentDataService,
    private dataShareService: DataShareService
  ) {}

  ngOnInit() {
    if (window.outerWidth < 769) {
      this.mobileView = true;
    }
    if (this.previewMode) {
      if (!this.question.hasOwnProperty("answer")) {
        this.questionAnswer = {
          answer: {
            options: [],
          },
          ...this.question,
        };
      } else {
        if (
          this.question.validation_option.is_comment_box &&
          this.question.answer
        ) {
          if (
            this.question.answer["other"] ||
            this.question.answer["not_observed"]
          ) {
            if (this.question.answer["other"]) {
              this.showOtherTextBox = true;
            } else {
              delete this.question.answer["other"];
              this.showOtherTextBox = false;
            }
            this.question["answered"] = true;
            setTimeout(() => {
              this.backClicked = true;
            }, 500);
          } else {
            if (this.question.answer["options"].length > 0) {
              if (
                typeof this.question.answer == "undefined" ||
                (typeof this.question.answer != "undefined" &&
                  this.question.answer.trim())
              ) {
                this.question["answered"] = true;
              } else {
                this.question["answered"] = false;
              }
            } else {
              delete this.question["answer"]["other"];
              delete this.question.answer["not_observed"];
              this.showOtherTextBox = false;
              this.question["answered"] = false;
            }
          }
        }

        if (
          this.question.answer &&
          this.question.answer["options"].length > 0
        ) {
          if (
            this.question.validation_option.is_comment_box &&
            this.question.option_type == 2 &&
            typeof this.question["answer"]["other"] != "undefined" &&
            this.question["answer"]["other"].trim()
          ) {
            // delete this.question['answer']['other'];
          } else {
            delete this.question["answer"]["other"];
          }
          delete this.question.answer["not_observed"];
          // this.showOtherTextBox = false;
          this.question["answered"] = true;
          setTimeout(() => {
            this.backClicked = true;
          }, 500);
        }
      }
    }
    // Subscribe to the event from the shared service
    this.dataShareService.colValueChanged$.subscribe(() => {
      this.getColValue();
    });
  }

  checkForZero(value: any, zeroReturnValue: any, defaultValue: any) {
    if (value == 0) {
      return zeroReturnValue;
    } else {
      return defaultValue;
    }
  }

  checkIfEmptyOption(option: any) {
    if (option.text == "") {
      return true;
    }

    return false;
  }
  getQuestionNumbering(numbering: any) {
    return `Q${numbering}<sup
    style="color: red;"
    *ngIf="showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
    >*</sup
  >`;
  }

  getQuestionNumberingWithoutAsterick(numbering: any) {
    return `Q${numbering}`;
  }
  getColValue() {
    console.log("called");
    let option_length = JSON.parse(
      JSON.stringify(this.question.question_options.length)
    );
    let col = JSON.parse(JSON.stringify(this.question.total_columns));
    let row_arr: any = [];
    this.col_arr = [];
    this.col_splice_index_arr = [];
    let total_rows =
      Math.floor(option_length / col) + (option_length % col > 0 ? 1 : 0);

    if (this.question.option_position_type == 3) {
      for (let i = 0; i < total_rows; i++) {
        if (option_length > 0) {
          row_arr.push(option_length - col > 0 ? col : option_length);
          option_length -= col;
        }
      }

      for (let index = 0; index < col; index++) {
        let count_in_col = 0;
        row_arr.forEach((ele: any, i: any) => {
          count_in_col += ele - 1 >= 0 ? 1 : 0;
          row_arr[i] = ele - 1;
          this.col_arr[index] = count_in_col;
        });
      }
      this.cd.detectChanges();
    }
  }

  ngAfterViewInit() {
    this.question.option_position_type == 3 ? this.getColValue() : "";
    setTimeout(() => {
      if (
        window.name &&
        window.name == "frameAndHashing" &&
        window.innerWidth < 769
      ) {
        this.mobileView = true;
      }
    }, 100);
  }

  getIndex(j: any) {
    return this.col_arr.reduce(
      (a: any, b: any, ind: any) => (ind <= j ? a + b : a),
      0
    );
  }

  onOkClick(){
    this.assessmentDataService.focusAnswerOkClicked$.next(true);
  }

  onSelectBoxClick(event:any){
    event.stopPropagation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.previewMode) {
      const nextClickedChange: SimpleChange = changes.isNextButtonClicked;
      this.isNextButtonClicked =
        nextClickedChange && nextClickedChange.currentValue;

      if (this.isNextButtonClicked) {
        if (this.question.answer !== undefined) {
          this.checkCustomValidation(this.question);
        } else {
          this.checkCustomValidation(this.questionAnswer);
        }
      }
    }
  }
  changeBack(e: any, toChange: any) {
    const selected = e.currentTarget.classList.contains("mcqSelected");
    if (this.previewMode) {
      // if (e.currentTarget && toChange == "add") {
      // e.currentTarget.style.border = `1px solid ${
      //   this.customThemeSettings["container_highlight_color"]
      //     ? this.customThemeSettings["container_highlight_color"]
      //     : "#cacaca6b"
      // }`;
      // not needed  selected ? '' : e.currentTarget.style.color = this.customThemeSettings['container_highlight_color'];
      // } else {
      // e.currentTarget.style.border = this.customThemeSettings[
      //   "option_container_border_color"
      // ]
      //   ? "1px solid" +
      //     this.customThemeSettings["option_container_border_color"]
      //   : "1px solid transparent";
      // selected
      //   ? ""
      //   : (e.currentTarget.style.color = this.customThemeSettings[
      //       "options_font_color"
      //     ]
      //       ? this.customThemeSettings["options_font_color"]
      //       : "#000000");
      // }
    }
  }

  tooltipClick(event: any) {
    event.stopPropagation();
  }

  updateQuestionAnswer(question: any) {
    if (this.previewMode) {
      this.okButtonShow = true;
      if (this.question.option_type == 2) {
        if (
          (question.answer.options.length > 0 &&
            (typeof question.answer.other == "undefined" ||
              (question.answer.other && question.answer.other.trim()))) ||
          (question.answer.other && question.answer.other.trim()) ||
          question.answer["not_observed"]
        ) {
          question["answered"] = true;
        } else {
          question["answered"] = false;
        }
      } else {
        if (
          question.answer.options.length > 0 ||
          (question.answer.options.length == 0 &&
            ((question.answer.other && question.answer.other.trim()) ||
              question.answer["not_observed"]))
        ) {
          question["answered"] = true;
        } else {
          question["answered"] = false;
        }
      }

      this.question["answer"] = question.answer;
      this.question["answered"] = question.answered;
      this.onQuestionDone.emit(question);
    }
  }

  checkCustomValidation(q: any): void {
    if (this.question.validation_option.is_custom_validation) {
      if (
        this.question.validation_option.validation != 2 ||
        (this.question.validation_option.validation == 2 &&
          q.answer &&
          (q.answer.options.length ||
            (!q.answer.options.length &&
              ((q.answer.other && q.answer.other.trim()) ||
                q.answer["not_observed"]))))
      ) {
        if (
          this.question.validation_option.validation == 0 &&
          (!q.answer ||
            (q.answer &&
              (!q.answer.options.length ||
                (q.answer.other && q.answer.other.trim()) ||
                q.answer["not_observed"])))
        ) {
          this.customQuestionError = "";
          delete this.question.hasCustomValidationError;
        } else {
          if (
            q.answer.options.length &&
            !q.answer.options.includes(
              this.question.question_options[
                this.question.validation_option.choice - 1
              ].url
            )
          ) {
            this.customQuestionError = this.question.validation_option.msg;
            this.question["hasCustomValidationError"] = true;
          } else {
            this.customQuestionError = "";
            delete this.question.hasCustomValidationError;
          }
        }
      } else {
        this.customQuestionError = "";
        delete this.question.hasCustomValidationError;
      }
    } else {
      this.customQuestionError = "";
      delete this.question.hasCustomValidationError;
    }
  }
  emitRadioOtherTextboxValue(event: any) {
    clearTimeout(this.emitted_val);
    this.emitted_val = setTimeout(() => {
      this.emitRadioUpdatedQuestion("other_text_box");
    }, 1000);
  }

  radioOptionChecked(event: any, option: any): void {
    if (typeof option == "string") {
      if (option == "other_text_box") {
        this.question["answer"] = {};
        this.question["answer"]["options"] = [];
        delete this.question["answer"]["not_observed"];
        if (event.target.checked) {
          this.question["answer"]["other"] = "";
          this.showOtherTextBox = true;
        } else {
          this.showOtherTextBox = false;
          delete this.question["answer"]["other"];
        }
        setTimeout(() => {
          this.updateQuestionAnswer(this.question);
        });
      } else {
        this.question["answer"] = {};
        this.question["answer"]["options"] = [];
        delete this.question["answer"]["other"];
        this.showOtherTextBox = false;
        if (event.target.checked) {
          this.question["answer"]["not_observed"] = true;
        } else {
          delete this.question["answer"]["not_observed"];
        }
        setTimeout(() => {
          this.updateQuestionAnswer(this.question);
        });
      }
    } else {
      if (
        this.question["answer"] &&
        (typeof this.question["answer"]["other"] != "undefined" ||
          this.question["answer"]["not_observed"])
      ) {
        this.showOtherTextBox = false;
        delete this.question["answer"]["other"];
        delete this.question["answer"]["not_observed"];
      }
      this.emitRadioUpdatedQuestion(option);
    }
  }

  getBorderColor() {
    return `1px solid ${this.customThemeSettings["option_container_border_color"]}`;
  }

  emitRadioUpdatedQuestion(option: any) {
    this.resetNextButton.emit(true);
    let qArr = this.question.url.split("/");
    let id = +qArr[qArr.length - 2];
    if(option.constructor !== String){
    option["question_id"] = id;
    option['q_id'] = id;
    option["ques_pos"] = this.question.numbering;
    }
    if(this.question.logic && this.question.logic.length){
      this.assessmentDataService.optionChoosen$.next(option);
    }
    var handleEventChange = (q: any) => {
      if (typeof option == "string") {
        q.answer = {};
        q.answer.options = [];
        if (option == "other_text_box") {
          let div_other = document.getElementById("other_" + this.question.id);
          if (div_other) {
            q.answer.other = div_other["value"].trim();
          }
        }
      } else {
       if(q) q.answer.options = [option.url];
      }
      this.updateQuestionAnswer(q);
    };

    if (this.previewMode) {
      if (this.question.answer !== undefined) {
        handleEventChange(this.question);
      } else {
        handleEventChange(this.questionAnswer);
      }
    }
  }

  emitCheckBoxOtherTextboxValue(event: any) {
    this.emitCheckBoxUpdatedQuestion(null, "other_text_box");
  }

  checkboxOptionChecked(event: any, option: any) {
    if (typeof option == "string") {
      if (option == "other_text_box") {
        // this.question['answer'] = {};
        // this.question['answer']['options'] = [];
        // delete this.question['answer']['not_observed'];
        if (event.target.checked) {
          if (this.question["answer"]) {
            this.question["answer"]["other"] = "";
            this.showOtherTextBox = true;
          } else {
            this.question["answer"] = {};
            this.question['answer']['options'] = [];
            this.question["answer"]["other"] = "";
            this.showOtherTextBox = true;
          }
        } else {
          this.showOtherTextBox = false;
          if (this.question["answer"]) {
            delete this.question["answer"]["other"];
          }
        }
        setTimeout(() => {
          this.updateQuestionAnswer(this.question);
        });
      } else {
        if (!this.question["answer"]) {
          this.question["answer"] = {};
        }
        this.question["answer"]["options"] = [];
        delete this.question["answer"]["other"];
        this.showOtherTextBox = false;
        if (event.target.checked) {
          this.question["answer"]["not_observed"] = true;
        } else {
          delete this.question["answer"]["not_observed"];
        }
        setTimeout(() => {
          this.updateQuestionAnswer(this.question);
        });
      }
    } else {
      if (this.question["answer"] && this.question["answer"]["not_observed"]) {
        // typeof this.question['answer']['other'] != 'undefined' ||
        this.showOtherTextBox = false;
        // delete this.question['answer']['other'];
        delete this.question["answer"]["not_observed"];
      }
      this.emitCheckBoxUpdatedQuestion(event, option);
    }
  }

  emitCheckBoxUpdatedQuestion(event: any, option: any) {
    this.resetNextButton.emit(true);

    var handleEventChange = (q: any) => {
      if (typeof option == "string") {
        if (this.question.option_type != 2) {
          q.answer = {};
          q.answer.options = [];
        }
        if (option == "other_text_box") {
          let div_other = document.getElementById("other_" + this.question.id);
          if (div_other) {
            q.answer.other = div_other["value"].trim();
          }
        }
      } else {
        if (event.target.checked) {
          if (q && q.answer) {
            if (q.answer["options"]) {
              q.answer.options.push(option.url);
            } else {
              q.answer.options = [];
              q.answer.options.push(option.url);
            }
          } else {
            q.answer = {};
            q.answer.options = [];
            q.answer.options.push(option.url);
          }
        } else {
          let fetchedOption = q.answer.options.filter((choosedOption: any) => {
            return choosedOption === option.url;
          });
          let fetchedOptionIndex = q.answer.options.indexOf(fetchedOption[0]);
          if (fetchedOptionIndex !== -1) {
            q.answer.options.splice(fetchedOptionIndex, 1);
          }
        }
      }
      let qArr = this.question.url.split("/");
      let id = +qArr[qArr.length - 2];
      if(option.constructor !== String){
        option["question_id"] = id;
        option['q_id'] = id;
        option["ques_pos"] = this.question.numbering;
        if (this.question["option_type"] == 2) {
          option["option_type"] = 2;
          option["checked"] = event.target.checked ? event.target.checked : false;
        }
      }
      
      if(this.question.logic && this.question.logic.length){
        this.assessmentDataService.optionChoosen$.next(option);
      }
      this.updateQuestionAnswer(q);
    };
    if (this.question.answer !== undefined) {
      handleEventChange(this.question);
    } else {
      handleEventChange(this.questionAnswer);
    }
  }

  public onSelectedOptionChanged(selected: any): void {
    let option = {};
    let qArr = this.question.url.split("/");
    let id = +qArr[qArr.length - 2];
    option["question_id"] = id;
    option['q_id'] = id;
    option["ques_pos"] = this.question.numbering;
    option["id"] = selected.target.value;
    if (
      this.question.question_options &&
      this.question.question_options.length
    ) {
      let sArr = selected.target.value.split("/");
      let id = +sArr[sArr.length - 2];
      option["id"] = id;
    }
    if(this.question.logic && this.question.logic.length){
      this.assessmentDataService.optionChoosen$.next(option);
    }
    if (selected.target.value == "other_text_box") {
      this.question["answer"] = {};
      this.question["answer"]["options"] = [];
      this.question["answer"]["other"] = "";

      this.questionAnswer = {};
      this.questionAnswer["answer"] = {};
      this.questionAnswer["answer"]["options"] = [];
      this.questionAnswer["answer"]["other"] = "";

      this.showOtherTextBox = true;
    } else if (selected.target.value == "noo") {
      this.question["answer"] = {};
      this.question["answer"]["options"] = [];
      this.question["answer"]["not_observed"] = true;

      this.questionAnswer = {};
      this.questionAnswer["answer"] = {};
      this.questionAnswer["answer"]["options"] = [];
      this.questionAnswer["answer"]["not_observed"] = true;
      this.showOtherTextBox = false;
      this.emitDropdownUpdatedQuestion(selected);
    } else {
      // if (this.question['answer'] && typeof this.question['answer']['other'] != 'undefined') {
      this.showOtherTextBox = false;
      this.question &&
        this.question["answer"] &&
        delete this.question["answer"]["other"];
      this.question &&
        this.question["answer"] &&
        delete this.question["answer"]["not_observed"];

      this.questionAnswer &&
        this.questionAnswer["answer"] &&
        delete this.questionAnswer["answer"]["other"];
      this.questionAnswer &&
        this.questionAnswer["answer"] &&
        delete this.questionAnswer["answer"]["not_observed"];

      this.emitDropdownUpdatedQuestion(selected);
      // }
    }
  }

  emitDropdownUpdatedQuestion(selected: any) {
    if (this.previewMode) {
      this.resetNextButton.emit(true);

      var handleEventChange = (q: any) => {
        if (selected.target.value == "noo") {
          q["answer"] = {};
          q["answer"]["options"] = [];
          q["answer"]["not_observed"] = true;
          this.showOtherTextBox = false;
          this.updateQuestionAnswer(q);
        } else {
          q.answer.options = [selected.target.value];
          this.updateQuestionAnswer(q);
        }
      };

      if (this.question.answer !== undefined) {
        handleEventChange(this.question);
      } else {
        handleEventChange(this.questionAnswer);
      }
    }
  }

  checkIfChecked(url: string, is_other?: any) {
    if (is_other) {
      if (
        this.question.answer &&
        typeof this.question.answer["other"] != "undefined"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.question.answer &&
        this.question.answer["options"] &&
        this.question.answer["options"]["length"]
      ) {
        return this.question["answer"]["options"].indexOf(url) > -1;
      } else {
        return false;
      }
    }
  }

  isSelected(option: any) {
    if (this.question.hasOwnProperty("answer")) {
      return this.question.answer.options.includes(option.url);
    }
  }

  // this allow us to be able to click on the radio label to select the radio button
  checkRelatedRadio(radioIndex: number, event?: any, option?: any) {
    if (this.question.answer) {
      delete this.question.answer["not_observed"];
    }
    if (event && event.target.tagName.toLowerCase() == "input") {
      event.stopPropagation();
    } else {
      // this.radioOptionClicked(radioIndex);
      option
        ? $(
            "#answer_" +
              this.question.id +
              "_" +
              this.getIndexof(option) +
              "_" +
              this.type_name
          ).trigger("click")
        : $(
            "#answer_" +
              this.question.id +
              "_" +
              radioIndex +
              "_" +
              this.type_name
          ).trigger("click");
    }
  }

  radioOptionClicked(optionIndex: any) {
    if (optionIndex == 0) {
      this.onOptionSelect.next(true);
    }
  }
  openModal(e: any, content: any) {
    this.modalService.open(content, {
      size: "sm",
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "tooltip-modal",
    });
  }
  checkIfNooSelected() {
    if (this.question.answer && this.question.answer["not_observed"]) {
      return true;
    } else {
      return false;
    }
  }

  changeRelatedChecked(event: any) {
    if (event && event.target.tagName.toLowerCase() == "input") {
      event.stopPropagation();
    } else {
      $("#answer_" + this.question.id + "_noo" + "_" + this.type_name).trigger(
        "click"
      );
    }
  }
  getIndexof(opt: any) {
    return this.question.question_options.findIndex(
      (ele: any) => ele.id == opt.id
    );
  }

  displayLogic: any = [];
  skipLogic: any = [];
  branchLogic: any = [];
  displayLogicUpdated(event: any) {
    this.displayLogic = event;
  }

  skipLogicUpdated(event: any) {
    this.skipLogic = event;
  }

  branchLogicUpdated(event: any) {
    this.branchLogic = event;
  }

  displayLogicClicked: any = false;
  skipLogicClicked: any = false;
  branchLogicClicked: any = false;
  displayLogicClickedEvent(event: any) {
    this.displayLogicClicked = event;
    this.skipLogicClicked = false;
    this.branchLogicClicked = false;
  }

  skipLogicClickedEvent(event: any) {
    this.skipLogicClicked = event;
    this.branchLogicClicked = false;
    this.displayLogicClicked = false;
  }

  branchLogicClickedEvent(event: any) {
    this.branchLogicClicked = event;
    this.skipLogicClicked = false;
    this.displayLogicClicked = false;
  }
}
