import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-multirater-modal',
  templateUrl: './confirm-multirater-modal.component.html',
  styleUrls: ['./confirm-multirater-modal.component.scss']
})
export class ConfirmMultiraterModalComponent {
  activeModal = inject(NgbActiveModal);
}
