import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { UserDataService } from "../user-data";

@Injectable()
export class SingleUserDetailResolve implements Resolve<any> {
  constructor(public userDataService: UserDataService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let params = route.params;
    return this.userDataService.getStandardUserByID(params["id"]);
  }
}
