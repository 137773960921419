import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-shortcode-select',
  templateUrl: './shortcode-select.component.html',
  styleUrls: ['./shortcode-select.component.scss']
})
export class ShortcodeSelectComponent {
  shortcodeSelected = "add_shortcode";


  @Output() onShortCodeSelect = new EventEmitter();

  public onShortcodeChanged(ev: any): void {
    let shortcode = JSON.parse(JSON.stringify(this.shortcodeSelected));
    let command = '';
    let content = '';
    let code = '';

    switch (shortcode) {
      case "first_name":
        code = '{{FirstName}}';
        break;
      case "survey_name":
        code = '{{SurveyName}}';
        break;
      case "survey_link":
        code = `<a href="{{SurveyLink}}">{{SurveyLink}}</a>`;
        break;
      case "unsubscribe_link":
        code = `<a href="{{UnsubscribeLink}}">Unsubscribe Link</a>`;
        break;
      case "survey_expiration":
        code = '{{SurveyExpiration}}';
        break;
      case "ratee_name":
        code = '{{ParticipantFullName}}';
        // if (this.activeEditingField == "subject") {
        //   this.form.controls["subject"].setValue(`${this.form.controls["subject"].value} {ParticipantName}`);
        //   let _x = $("[formcontrolName=subject]");
        //   _x.focus();
        //   return; // Skip inserting content into editor
        // }
        break;
      case "member_name":
        code = '{{AdminName}}';
        break;
      case "participant_first_name":
        code = '{{ParticipantFirstName}}';
        break;
      case "member_name":
        code = '{{AdminName}}';
        break;
      case "day_prior_expiration":
        code = '{{DayPriorExpiration}}';
        break;
      case "survey_expiration_intl":
        code = '{{SurveyExpirationIntl}}';
        break;
      default:
        return; 
    }

    content = `<font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">${code}</font></font>`

    this.onShortCodeSelect.emit({
      shortcode,
      command:"mceInsertContent",
      code,
      content
    })


    setTimeout(() => {
      this.shortcodeSelected = "add_shortcode";
    }, 500);
  }
}
