import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value: any, args?:string[]) : any {
    let keys: any [] = [];
    Object.keys(value).sort().forEach((key: string)=>{
    	keys.push({key: key, value: value[key]});
    })

    return keys;
  }
}
