import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AssessmentDataService } from "../../../../../app/core/assessment-data.service";
import {
  NgbModal,
  NgbDatepickerConfig,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";

import { Subscription, Subject, fromEvent } from "rxjs";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { UserDataService } from "../../../../../app/core/user-data";
import { DataShareService } from "../../../../../app/data-share.service";

declare const google: any;
@Component({
  selector: "fsop-text-entry",
  templateUrl: "./fsop-text-entry.component.html",
  styleUrls: ["./fsop-text-entry.component.scss"],
})
export class FsopTextEntryComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input("question")
  question: any;
  dialCode: any;
  @Output("onQuestionDone")
  onQuestionDone: EventEmitter<any> = new EventEmitter<any>();
  validationError: string = "";
  answer: string = "";
  @Input("previewMode")
  previewMode: boolean;
  @Input("from") from = "";
  @Input("numbering")
  numbering: number;

  @Input("showQuestionNumber")
  showQuestionNumber: boolean;
  answerLength: number;
  // questionAnswer: any;
  @Input("customThemeSettings") customThemeSettings: object;

  @Input("theme")
  theme: string;

  @Input("isNextButtonClicked")
  isNextButtonClicked: boolean;

  @Output("resetNextButton")
  resetNextButton = new EventEmitter<boolean>();

  @Input("showRequiredAsterisk") showRequiredAsterisk: boolean;

  @Input("editable") editable: boolean;
  @Input("backClicked") backClicked: boolean;
  @Input() allQuestions: any;
  @Input() surveyData: any;

  // today: any;

  emailValid: any;

  dobSelected: any;
  @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;
  @ViewChild("phoneText", { static: false })
  public phoneText: ElementRef;
  private autoComplete: any;
  public countryList: any;
  public countryListSubscription: Subscription;
  public validPhoneSub: Subscription;

  mobileView: boolean = false;
  allowed_keys = [
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Tab",
    "Delete",
    "Backspace",
  ];

  constructor(
    private assessmentDataService: AssessmentDataService,
    private route: ActivatedRoute,
    private config: NgbDatepickerConfig,
    private modalService: NgbModal,
    private userDataService: UserDataService,
    private dataShare: DataShareService
  ) {
    this.configDateTimePicker();
  }

  configDateTimePicker(): void {
    // const tomorrow = new Date();
    // tomorrow.setDate(new Date().getDate() + 1);
    // this.config.maxDate = {
    //   year: tomorrow.getFullYear(),
    //   month: tomorrow.getMonth() + 1,
    //   day: tomorrow.getDate()
    // };
    // this.config.minDate = {
    //   year: 1900,
    //   month: 1,
    //   day: 1
    // };

    this.config.outsideDays = "hidden";
  }

  tooltipClick(event: any) {
    event.stopPropagation();
  }
  currentLocation: any = "";
  ngOnInit() {
    if (window.outerWidth < 769) {
      this.mobileView = true;
    }
    if (this.question.custom_validation.validation_type == "phone") {
      if (this.previewMode && !this.question.answer) {
        if (!this.dataShare.locationInfo) {
          this.userDataService.getMyLocationUsingIP().subscribe((data) => {
            this.dataShare.locationInfo = data;
            this.question["country_code"] = data.country_code2;
            this.currentLocation = data.country_name;
            this.dialCode = data.calling_code;
          });
        } else {
          this.question[
            "country_code"
          ] = this.dataShare.locationInfo.country_code2;
          this.currentLocation = this.dataShare.locationInfo.country_name;
          this.dialCode = this.dataShare.locationInfo.calling_code;
        }

        this.getCountryList(true);
        this.question.answer ? (this.answer = this.question.answer) : "";
      }
    }
    let current_date = new Date();
    let month = current_date.getMonth() + 1;
    // let date = current_date.getDate();
    // this.today = `${current_date.getFullYear()}-${month > 9 ? month : '0'+month}-${date > 9? date : '0'+ date}`;
    if (this.previewMode) {
      if (
        !this.question.hasOwnProperty("answer") ||
        (this.question.hasOwnProperty("answer") &&
          (!this.question["answer"] ||
            (this.question["answer"] && !this.question["answer"].trim())))
      ) {
        // this.questionAnswer = {
        //   answer: '',
        //   ...this.question
        // }
        this.question["answer"] = "";
      } else {
        this.answer = this.question.answer;
        if (this.question.custom_validation.is_custom_validation) {
          if (this.question.custom_validation["validation_type"] == "email") {
            this.checkIfEmailValid();
          } else if (
            this.question.custom_validation["validation_type"] == "dob"
          ) {
            let _arr = this.question.answer.split("-");
            // this.checkIfEmailValid();
            if (_arr.length && _arr.length == 3) {
              this.dobSelected = {
                year: parseInt(_arr[0]),
                month: parseInt(_arr[1]),
                day: parseInt(_arr[2]),
              };
              this.question["answered"] = true;
              delete this.question["customValidError"];
            }
          }
        } else {
          this.question["answered"] = true;
        }
        if (this.answer.trim()) {
          setTimeout(() => {
            this.backClicked = true;
          }, 500);
        }
      }
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (
        window.name &&
        window.name == "frameAndHashing" &&
        window.innerWidth < 769
      ) {
        this.mobileView = true;
      }
    }, 100);
    if (
      this.previewMode &&
      this.searchElementRef &&
      this.searchElementRef.nativeElement &&
      this.question.custom_validation.validation_type == "address"
    ) {
      this.initializePlaces();
      this.question.answer ? (this.answer = this.question.answer) : "";
    }
    if (
      this.previewMode &&
      this.phoneText &&
      this.phoneText.nativeElement &&
      this.question.custom_validation.validation_type == "phone"
    ) {
      this.onPhoneInput();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.previewMode && changes.isNextButtonClicked) {
      const nextClickedChange: SimpleChange = changes.isNextButtonClicked;
      this.isNextButtonClicked =
        nextClickedChange && nextClickedChange.currentValue;

      if (this.isNextButtonClicked) {
        // if(this.question.hasOwnProperty('answer')){
        //   if(this.question.answer !== undefined && this.question.answer !== null)
        this.checkLengthValidation(this.question);
        // }else{
        //   this.checkLengthValidation(this.questionAnswer);
        // }
      }
    }
  }

  private initializePlaces() {
    const input = this.searchElementRef.nativeElement;
    const options = {
      fields: ["name"],
    };
    this.autoComplete = new google.maps.places.Autocomplete(input, options);
    this.autoComplete.addListener("place_changed", this.placeChangedFunction);
  }
  private placeChangedFunction = (): void => {
    let place = this.autoComplete.getPlace();
    const address = place.name;
    this.updateAnswer(address);
  };
  private updateQuestionAnswer(question: any) {
    if (this.previewMode) {
      if (question.answer !== undefined && question.answer !== null) {
        if (question.answer.trim().length > 0) {
          question["answered"] = true;
        } else {
          question["answered"] = false;
        }
        this.question["answer"] = question.answer.trim();
        this.onQuestionDone.emit(question);
      }
    }
  }

  checkFilledAns(ans: string) {
    if (ans && ans.trim()) {
      return true;
    } else {
      return false;
    }
  }

  private checkLengthValidation(q: any): void {
    if (this.question.validation_option.validation_type.validation_type !== 0) {
      if (this.question.custom_validation.is_custom_validation) {
        if (
          this.question.answer &&
          (this.question.custom_validation.validation_type != "email" ||
            (this.question.custom_validation.validation_type == "email" &&
              this.emailValid))
        ) {
          delete this.question["customValidError"];
        } else {
          if (!this.question.answer) {
            if (this.question.validation_option.validation == 1) {
              this.question["customValidError"] = `This field is required`;
            }
          } else {
            if (this.question.custom_validation.validation_type == "email") {
              if (this.question.answer && this.emailValid == true) {
                delete this.question["customValidError"];
              } else {
                let emailPattern = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if (!emailPattern.test(this.question.answer)) {
                  this.question[
                    "customValidError"
                  ] = this.question.answer.trim()
                      ? "Please enter a valid email address."
                      : "";
                }
                // else {
                //   this.question['customValidError'] = 'This domain is restricted to fill this survey. Please enter another email.';
                // }
              }
            }
          }
        }
        if (
          this.question.custom_validation.validation_type == "email" ||
          this.question.custom_validation.validation_type == "number"
        ) {
          this.checkForMinAndMaxLength(q);
        }
      } else {
        this.checkForMinAndMaxLength(q);
      }
    }
  }

  checkForZero(value: any, zeroReturnValue: any, defaultValue: any) {
    if (value == 0) {
      return zeroReturnValue;
    } else {
      return defaultValue;
    }
  }

  public checkForMinAndMaxLength(q: any) {
    switch (this.question.validation_option.validation_type.validation_type) {
      case 2:
        // we must check if answer length is greater or equal to min length
        if (
          this.question.validation_option.validation != 2 ||
          (this.question.validation_option.validation == 2 &&
            this.checkFilledAns(q.answer))
        ) {
          if (this.question.validation_option.validation == 0 && !q.answer) {
            this.validationError = "";
            delete this.question["hasLengthValidationError"];
          } else {
            if (
              q.answer.trim().length <
              this.question.validation_option.validation_type.value
            ) {
              this.validationError = `Your response must be at least ${this.question.validation_option.validation_type.value} characters.`;
              this.question["hasLengthValidationError"] = true;
            } else {
              this.validationError = "";
              delete this.question["hasLengthValidationError"];
            }
          }
        } else {
          this.validationError = "";
          delete this.question["hasLengthValidationError"];
        }
        break;
      case 3:
        // we must check if answer length is lowwer or equal to max length
        if (
          this.question.validation_option.validation != 2 ||
          (this.question.validation_option.validation == 2 &&
            this.checkFilledAns(q.answer))
        ) {
          if (this.question.validation_option.validation == 0 && !q.answer) {
            this.validationError = "";
            delete this.question["hasLengthValidationError"];
          } else {
            if (
              q.answer.length >
              this.question.validation_option.validation_type.value
            ) {
              this.question["hasLengthValidationError"] = true;
              this.validationError = `Your response must not exceed ${this.question.validation_option.validation_type.value} characters.`;
            } else {
              this.validationError = "";
              delete this.question["hasLengthValidationError"];
            }
          }
        } else {
          this.validationError = "";
          delete this.question["hasLengthValidationError"];
        }
        break;

      default:
        break;
    }
  }
  emitted_val: any;

  public onAnswerChange($event: any) {
    clearTimeout(this.emitted_val);
    this.emitted_val = setTimeout(() => {
      this.resetNextButton.emit(true);
      this.answerLength = $event.target.value.length;

      var handleAnswerChange = (q: any) => {
        q.answer = $event.target.value;
        let option = {};
        let qArr = this.question.url.split("/");
        let id = +qArr[qArr.length - 2];
        option["question_id"] = id;
        option['q_id'] = id;
        option["ques_pos"] = this.question.numbering;
        option["value"] = q.answer;
        if (this.question.logic && this.question.logic.length) {
          this.assessmentDataService.optionChoosen$.next(option);
        }
        if (q.custom_validation.validation_type == "email") {
          this.checkforEmailValidation();
        } else {
          setTimeout(() => {
            this.updateQuestionAnswer(q);
          });
        }
        // setTimeout(() => {
        //   if (!q.custom_validation.is_custom_validation) {
        //     this.updateQuestionAnswer(q);
        //   } else {
        // if (q.custom_validation.validation_type == 'email') {
        //   let emailPattern = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        //   if (!emailPattern.test(q.answer)) {
        //     this.question['customValidError'] = q.answer.trim()? 'Please enter a valid email address.' : '';
        //   }else {
        //     this.checkIfEmailValid();
        //   }
        // } else {
        //   this.updateQuestionAnswer(q);
        // }
        // }
        // });
      };

      // if (this.question.hasOwnProperty('answer')) {
      handleAnswerChange(this.question);
      // }else{
      //   handleAnswerChange(this.questionAnswer);
      // }
    }, 700);
  }
  checkforEmailValidation() {
    let emailPattern = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(this.question.answer)) {
      this.question["customValidError"] = this.question.answer.trim()
        ? "Please enter a valid email address."
        : "";
    } else {
      this.checkIfEmailValid();
      this.emailValid = true;
      delete this.question["customValidError"];
    }
  }
  checkIfEmailValid() {
    this.assessmentDataService.setLoader({ bool: true });
    this.assessmentDataService
      .checkIfEmailBlackListed(this.route.snapshot.params.id, {
        email: this.question.answer,
      })
      .subscribe(
        (data: any) => {
          this.emailValid = true;
          delete this.question["customValidError"];
          this.question["answered"] = true;
          this.updateQuestionAnswer(this.question);
          this.assessmentDataService.setLoader({ bool: false });
        },
        (Err: any) => {
          this.emailValid = false;
          this.question["answered"] = false;
          this.updateQuestionAnswer(this.question);
          if (Err.error.message) {
            this.question["customValidError"] = Err.error.message;
          }
          this.assessmentDataService.setLoader({ bool: false });
        }
      );
  }

  checkValidKey(event: any) {
    let patt1 = /[0-9]/g;
    if (
      patt1.test(event.key) ||
      this.allowed_keys.indexOf(event.key) > -1 ||
      (this.question.custom_validation.validation_type == "number" &&
        event.key == "." &&
        event.target.value.indexOf(".") == -1) ||
      (this.question.custom_validation.validation_type == "phone" &&
        ((event.key == "+" && event.target.value.indexOf("+") == -1) ||
          event.key == " " ||
          event["key"] == "-"))
    ) {
    } else {
      event.preventDefault();
    }
  }

  // getDateSelected(event: any) {
  //   this.resetNextButton.emit(true);
  //   this.question.answer = this.answer;
  //   this.question['answered'] = true;
  //   this.updateQuestionAnswer(this.question);
  // }

  onChangeDOB(event: any) {
    this.updateAnswer(
      `${this.dobSelected.year}-${this.dobSelected.month}-${this.dobSelected.day}`
    );
  }
  updateAnswer(answer: any) {
    this.resetNextButton.emit(true);
    // this.answer = answer;
    this.question.answer = answer;
    delete this.question["customValidError"];
    this.question["answered"] = true;
    this.updateQuestionAnswer(this.question);
  }
  checkInputVal(event: any) {
    if (!this.question.answer) {
      this.answer = "";
    }
  }
  openModal(e: any, content: any) {
    this.modalService.open(content, {
      size: "sm",
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "tooltip-modal",
    });
  }
  // getting the country list with dail code and country code
  public getCountryList(initial?: any) {
    if (this.assessmentDataService.countryList.length) {
      this.countryList = this.assessmentDataService.countryList;
    } else {
      this.countryListSubscription = this.assessmentDataService
        .getCountryList()
        .subscribe((data) => {
          if (data) {
            this.assessmentDataService.countryList = data; // saved in service to igonre one more hit on next and back button
            this.countryList = data;
            this.question["country_code"] && initial
              ? this.onOptionSelected(this.question["country_code"], true)
              : "";
          }
        });
    }
  }
  // checking if entered phone number is valid
  onPhoneInput() {
    if (this.phoneText) {
      fromEvent(this.phoneText.nativeElement, "keyup")
        .pipe(debounceTime(800), distinctUntilChanged())
        .subscribe((value) => {
          this.validatePhone();
        });
    }
  }

  validatePhone() {
    let val =
      this.question.custom_validation.dial_code +
      this.phoneText.nativeElement.value;
    this.validPhoneSub = this.assessmentDataService
      .checkPhoneIfValid({
        country_code: this.question["country_code"],
        phone_number: val,
      })
      .subscribe(
        (data: any) => {
          delete this.question["customValidError"];
          this.question["answered"] = true;
          this.question.answer = this.phoneText.nativeElement.value;
          this.updateQuestionAnswer(this.question);
        },
        (Err: any) => {
          this.question["answered"] = false;
          this.updateQuestionAnswer(this.question);
          this.question["customValidError"] =
            "Please enter a valid phone number.";
        }
      );
  }

  getQuestionNumbering(numbering: any) {
    return `Q${numbering}<sup
    style="color: red;"
    *ngIf="showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
    >*</sup
  >`;
  }

  getQuestionNumberingWithoutAsterick(numbering: any) {
    return `Q${numbering}`;
  }
  // for setting dial code value
  public onOptionSelected(event: any, initial?: any) {
    for (let i = 0; i < this.countryList.length; i++) {
      if (this.countryList[i].country_code == event) {
        this.question.custom_validation.dial_code = this.countryList[i][
          "dial_code"
        ];
        this.dialCode = this.countryList[i]["dial_code"];
      }
    }
    if (this.phoneText.nativeElement.value && !initial) {
      setTimeout(() => {
        this.validatePhone();
      }, 200);
    }
  }

  onOkClick() {
    this.assessmentDataService.focusAnswerOkClicked$.next(true);
  }

  displayLogic: any = [];
  skipLogic: any = [];
  branchLogic: any = [];
  displayLogicUpdated(event: any) {
    this.displayLogic = event;
  }

  skipLogicUpdated(event: any) {
    this.skipLogic = event;
  }

  branchLogicUpdated(event: any) {
    this.branchLogic = event;
  }

  displayLogicClicked: any = false;
  skipLogicClicked: any = false;
  branchLogicClicked: any = false;
  displayLogicClickedEvent(event: any) {
    this.displayLogicClicked = event;
    this.skipLogicClicked = false;
    this.branchLogicClicked = false;
  }

  skipLogicClickedEvent(event: any) {
    this.skipLogicClicked = event;
    this.branchLogicClicked = false;
    this.displayLogicClicked = false;
  }

  branchLogicClickedEvent(event: any) {
    this.branchLogicClicked = event;
    this.skipLogicClicked = false;
    this.displayLogicClicked = false;
  }

  ngOnDestroy() {
    if (this.countryListSubscription) {
      this.countryListSubscription.unsubscribe();
    }
    if (this.validPhoneSub) {
      this.validPhoneSub.unsubscribe();
    }
  }
}
