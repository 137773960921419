import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { Logger } from "../logger.service";
import { AuthenticationService } from "./authentication.service";
import { UserDataService } from "../user-data";
import { HttpService } from "../http/http.service";
import { SuperadminService } from "./superadmin.service";

const log = new Logger("AuthenticationGuard");

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private httpService: HttpService,
    private superadminService: SuperadminService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let state_url = "/";
    if (this.authenticationService.isAuthenticated()) {
      this.superadminService.isSuperAdminLogged = localStorage.getItem("is_superadmin");
      return true;
    }

    log.debug("Not authenticated, redirecting and adding redirect url...");
    if (state.url == "/") {
      state_url = "/dashboard";
    } else {
      state_url = state.url.includes("login") ? "/dashboard" : state.url;
    }
    this.router.navigate(["/login"], {
      queryParams: { redirect: state_url },
      replaceUrl: true,
    });
    return false;
  }
}

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (!this.authenticationService.isAuthenticated()) {
      return true;
    }

    log.debug("Not authenticated, redirecting and adding redirect url...");
    if (
      state.root &&
      state.root.queryParams &&
      state.root.queryParams["redirect"] &&
      !state.root.queryParams["redirect"].includes("login")
    ) {
      this.router.navigate([state.root.queryParams["redirect"]], {
        replaceUrl: true,
      });
      return false;
    } else {
      this.router.navigate(["./dashboard"], { replaceUrl: true });
    }
  }
}
