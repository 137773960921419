import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { KeysPipe } from "../../../../core/pipes/keys.pipe";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AssessmentDataService } from "../../../../../app/core/assessment-data.service";

@Component({
  selector: "fsop-drill-down",
  templateUrl: "./fsop-drill-down.component.html",
  styleUrls: ["./fsop-drill-down.component.scss"],
})
export class FsopDrillDownComponent implements OnInit, AfterViewInit {
  okButtonShow = false;
  @Output("onQuestionDone")
  onQuestionDone = new EventEmitter<any>();

  @Input("previewMode")
  previewMode: boolean;

  @Input("customThemeSettings") customThemeSettings: object;
  @Input("from") from = "";
  @Input("theme")
  theme: string;

  @Input("showQuestionNumber")
  showQuestionNumber: boolean;

  @Input("numbering")
  numbering: number;

  @Input("question")
  question: any;

  @Input("showRequiredAsterisk") showRequiredAsterisk: boolean;

  @Input("editable") editable: boolean;
  @Input("backClicked") backClicked: boolean;

  @Input() allQuestions: any;
  @Input() surveyData: any;
  array: any[] = [];
  path: string = "";
  questionAnswer: any;
  mobileView: boolean = false;
  optionsPerResponseOption: any[] = [];
  // optionsPerResponseOptions: any [] = [];
  constructor(public keysPipe: KeysPipe, private modalService: NgbModal, public assessmentDataService:AssessmentDataService) {}

  onOkClick(){
    this.assessmentDataService.focusAnswerOkClicked$.next(true);
  }

  ngOnInit() {
    if (window.outerWidth < 769) {
      this.mobileView = true;
    }
    if (this.previewMode) {
      if (
        !this.question.hasOwnProperty("answer") ||
        !this.question["answer"] ||
        (this.question["answer"] &&
          (this.question["answer"].length !=
            this.question.question_options.length ||
            this.question.answer.filter((a: any) => {
              return !a["answer"] || !a["option"];
            }).length != 0))
      ) {
        this.question.answer = [];
        for (var i = 0; i < this.question.question_options.length; i++) {
          this.question.answer.push({
            answer: null,
            option: this.question.question_options[i].url,
          });
          this.array.push([]);
        }
      } else {
        let _answers: any = [];
        this.question.answer.forEach((element: any, _index: any) => {
          element["option"] = element["option"]
            ? element["option"]
            : this.question.question_options[_index].url;
          this.path +=
            element.answer +
            (_index == this.question.answer.length - 1 ? "" : ".");
          this.array[_index] = [
            this.getPropertyValue(this.question.choices, this.path),
          ];
        });

        setTimeout(() => {
          this.backClicked = true;
        }, 500);
      }
    }
  }

  checkForZero(value: any, zeroReturnValue: any, defaultValue: any) {
    if (value == 0) {
      return zeroReturnValue;
    } else {
      return defaultValue;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (
        window.name &&
        window.name == "frameAndHashing" &&
        window.innerWidth < 769
      ) {
        this.mobileView = true;
      }
    }, 100);
  }
  tooltipClick(event: any) {
    event.stopPropagation();
  }
  openModal(e: any, content: any) {
    this.modalService.open(content, {
      size: "sm",
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "tooltip-modal",
    });
  }
  updateQuestionAsnwer() {
    if (this.previewMode) {
      this.okButtonShow = true;
      if (this.question.answer != undefined) {
        let nullAnsweredItem = this.question.answer.filter((a: any) => {
          return !a.answer || !a.option;
        });
        if (nullAnsweredItem.length > 0) {
          this.question["answered"] = false;
        } else this.question["answered"] = true;
        this.onQuestionDone.emit(this.question);
      }
    }
  }

  public onResponseSelected(ev: any, index: number) {
    let pathToArray = this.path.split(".");
    if (ev.target.value.length > 0) {
      if (!pathToArray.includes(ev.target.value) && this.path.length > 0) {
        pathToArray[index] = ev.target.value;
        if (pathToArray[index + 1]) {
          for (let _i = index + 1; _i < pathToArray.length; _i++) {
            pathToArray[_i] = "";
          }
        }
        this.path = pathToArray.join(".");
      } else if (
        !pathToArray.includes(ev.target.value) &&
        this.path.length == 0
      ) {
        this.path += ev.target.value + ".";
      }

      let _index = this.question.answer.findIndex((obj: any) => {
        return obj["option"] == this.question.question_options[index].url;
      });

      if (_index > -1) {
        this.question.answer[_index].answer = ev.target.value;
      } else {
        this.question.answer.push({
          option: this.question.question_options[index].url,
          answer: ev.target.value,
        });
      }

      if (this.array[index]) {
        this.array[index] = [
          this.getPropertyValue(this.question.choices, this.path),
        ];
        for (let i = index + 1; i < this.array.length; i++) {
          this.array[i] = [];
          // this.questionAnswer.answer[i].answer = null;
          this.question.answer[i].answer = null;
        }
      }
    } else {
      for (var i = index; i < this.question.question_options.length; i++) {
        this.array[i] = [];
        if (this.question.answer && this.question.answer[i]) {
          this.question.answer[i].answer = null;
        }
      }
    }
    this.updateQuestionAsnwer();
  }
  getQuestionNumbering(numbering: any) {
    return `Q${numbering}<sup
    style="color: red;"
    *ngIf="showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
    >*</sup
  >`;
  }

  getQuestionNumberingWithoutAsterick(numbering: any) {
    return `Q${numbering}`;
  }
  private getPropertyValue(object: any, dataToRetrieve: string) {
    dataToRetrieve.split(".").forEach(function (token) {
      if (token && object[token]) {
        object = object[token];
      }
    });

    return object;
  }

  onSelectBoxClick(event:any){
    event.stopPropagation();
  }

  displayLogic: any = [];
  displayLogicUpdated(event: any) {
    this.displayLogic = event;
  }

  displayLogicClicked: any = false;
  displayLogicClickedEvent(event: any) {
    this.displayLogicClicked = event;
  }
}
