import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  SimpleChanges,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
declare var grecaptcha: any;

@Component({
  selector: "fsop-captcha",
  templateUrl: "./fsop-captcha.component.html",
  styleUrls: ["./fsop-captcha.component.scss"],
})
export class FsopCaptchaComponent implements OnInit, AfterViewInit {
  @Output("onQuestionDone") onQuestionDone = new EventEmitter<any>();

  @Input("customThemeSettings") customThemeSettings: object;

  @Input("theme")
  theme: string;

  @Input("numbering")
  numbering: number;

  @Input("previewMode")
  previewMode: boolean;

  @Input("showQuestionNumber")
  showQuestionNumber: boolean;

  @Input("showRequiredAsterisk")
  showRequiredAsterisk: boolean;

  @Input("question")
  question: any;

  @Input("sectionIndex") sectionIndex: any;

  @Input("currentPageIndex") currentPageIndex: any;
  @Input("from") from = "";
  reCaptchaModel: any;
  randomValue: any;
  mobileView: boolean = false;
  @Input() allQuestions: any;
  @Input() surveyData: any;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.randomValue = Math.random();
    if (window.outerWidth < 769) {
      this.mobileView = true;
    }
    this.resetCaptchaAnswer();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.previewMode &&
      changes.currentPageIndex &&
      changes.currentPageIndex.currentValue
    ) {
      this.resetCaptchaAnswer();
    }
  }

  verifycaptcha() {
    this.question["answered"] = true;
    this.question["answer"] = true;
    delete this.question["error"];
    this.question["isValidated"] = true;
    this.onQuestionDone.emit(this.question);
  }

  resetCaptchaAnswer() {
    if (
      (!this.sectionIndex && !this.currentPageIndex) ||
      (typeof this.sectionIndex != "undefined" &&
        typeof this.currentPageIndex != "undefined" &&
        this.sectionIndex == this.currentPageIndex)
    ) {
      delete this.question["answered"];
      delete this.question["answer"];
      delete this.question["error"];
      this.onQuestionDone.emit(this.question);
    }
  }

  checkForZero(value: any, zeroReturnValue: any, defaultValue: any) {
    if (value == 0) {
      return zeroReturnValue;
    } else {
      return defaultValue;
    }
  }

  tooltipClick(event: any) {
    event.stopPropagation();
  }

  getQuestionNumbering(numbering: any) {
    return `Q${numbering} <sup style="color: red;" *ngIf="showRequiredAsterisk">*</sup>`;
  }

  getQuestionNumberingWithoutAsterick(numbering: any) {
    return `Q${numbering}`;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (
        window.name &&
        window.name == "frameAndHashing" &&
        window.innerWidth < 769
      ) {
        this.mobileView = true;
      }
    }, 100);

    let _this = this;
    let captchaContainerId = `captcha_${this.getQuestionId()}_${
      this.randomValue
    }`;

    var verifyCallback = function (response: any) {
      _this.question["answered"] = true;
      _this.question["answer"] = true;
      delete _this.question["error"];
      _this.onQuestionDone.emit(_this.question);
    };

    var expCallback = function () {
      grecaptcha.reset();
      delete _this.question["answered"];
      delete _this.question["answer"];
      delete _this.question["error"];
      _this.onQuestionDone.emit(_this.question);
    };

    var widgetId1 = grecaptcha.render(`${captchaContainerId}`, {
      sitekey: "6LcCghcaAAAAAL_UJoLMVMjyIynoh6A-m4JM3Ok7",
      callback: verifyCallback,
      "expired-callback": expCallback,
    });
    if (!this.previewMode) {
      let d:any = document.getElementById(
        `captcha_${this.getQuestionId()}_${this.randomValue}`
      );
      d.style.pointerEvents = "none";
    }
  }
  openModal(e: any, content: any) {
    this.modalService.open(content, {
      size: "sm",
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "tooltip-modal",
    });
  }
  getQuestionId() {
    let _url = this.question.url;
    let arr = _url.split("/");
    return arr[arr.length - 2];
  }

  displayLogic: any = [];
  displayLogicUpdated(event: any) {
    this.displayLogic = event;
  }

  displayLogicClicked: any = false;
  displayLogicClickedEvent(event: any) {
    this.displayLogicClicked = event;
  }
}
