import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CoreModule } from "../../../app/core";
import { SharedModule } from "../../../app/shared";
import { SurveyPreviewRoutingModule } from "./survey-preview-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { ImageCropperModule } from "ngx-image-cropper";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
// import { SortablejsModule } from "ngx-sortablejs";
import { ClickOutsideModule } from "ng-click-outside";
import { EditorModule } from "@tinymce/tinymce-angular";

// import { SurveyBuilderComponent } from './survey-builder/survey-builder.component';
// import { SurveyReportComponent } from './survey-report/survey-report.component';
import { SurveyPreviewComponent } from "./survey-preview.component";

// import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgChartsModule } from "ng2-charts";

import { QuestionsSectionModule } from "../../shared/question-section/questions-section.module";
import { ToastrModule } from "ngx-toastr";

// import { QuestionsSettingModule } from '../../shared/questions-setting/questions-setting.module';
// import { QuestionOverviewModule } from '../../shared/question-overview-modal/question-overview.module';
import { ServerErrorHandlerModule } from "../../shared/server-error-handler/server-error-handler.module";
import { NgxDocViewerModule } from "ngx-doc-viewer";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    EditorModule,
    SurveyPreviewRoutingModule,
    NgxDatatableModule,
    FormsModule,
    ClickOutsideModule,
    NgbModule,
    NgxDocViewerModule,
    ReactiveFormsModule,
    ServerErrorHandlerModule,
    //SortablejsModule.forRoot({
//      animation: 200,
//    }),
    NgChartsModule,
    // NgCircleProgressModule.forRoot({
    //   "radius": 30,
    //   "space": -4,
    //   "outerStrokeGradient": false,
    //   "outerStrokeColor": "#4882c2",
    //   "outerStrokeGradientStopColor": "#53a9ff",
    //   "showZeroOuterStroke": false,
    //   "innerStrokeColor": "#e7e8ea",
    //   "innerStrokeWidth": 10,
    //   "animateTitle": false,
    //   "animationDuration": 1000,
    //   "showSubtitle": false,
    //   "showBackground": false,
    //   "clockwise": false,
    //   "startFromZero": false
    // }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-center",
    }),

    ImageCropperModule,
    QuestionsSectionModule,
    // QuestionsSettingModule,
    // QuestionOverviewModule
  ],
  declarations: [
    SurveyPreviewComponent,
    // SurveyBuilderComponent,
    // SurveyReportComponent
  ],
  providers: [],
  exports: [SurveyPreviewComponent],
})
export class SurveyPreviewModule {}
