import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class EmailTimerService {
  timerState = new BehaviorSubject(null);
  timerCancelled = new Subject();
  cancelTimerState = new BehaviorSubject(null);
  updateTimezoneData$ = new BehaviorSubject(null);

  timerComplete$ = new Subject();
  constructor() {}

  setTimerState(timerState: any) {
    this.timerState.next(timerState);
  }
  setCancelTimerState(cancelTimerState: any) {
    this.cancelTimerState.next(cancelTimerState);
  }
}
