<div class="main fsop-container launch-gutter">
    <div class="error-container">
        <table style="width:100%;">
            <tbody>
                <tr>
                    <td width="32%">
                        <img style="width:85%;" src='./assets/images/404-page-not-found-icon.png'>
                    </td>
                    <td width="68%" style="vertical-align: middle;">
                        <div class="http_status_header">
                            404 - Page not found
                        </div>
                        <div class="http_status_body">
                            <p class="text-body">The page you requested is not found on our server.</p>
                            <p class="text-body">The page may have been moved , or it could have been mistyped.</p>
                        </div>
                        <div>
                            <p style="color:#4b4b4b; line-height:20px;">Visit our <a style="color:#4175a5;">Homepage </a> or<a href="" style="color:#4175a5;"> contact us</a> about a problem.</p>
                        </div>
                        
                        <button class="btn btn-default" style="padding:5px 15px; margin:30px 0;" (click)="goBack()" type="submit">
                             <span class="fi fi-icon-long-arrow-left" ></span> Go Back
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
</div>