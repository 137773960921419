<div
  id="{{numbering}}"
  class="quest question-container print"
  [ngClass]="{ selected: question.uiSelected && !previewMode, 'non-editable': (editable && question.answered && backClicked),'previewMode' : previewMode ? true : false, 'wth-out-qnum' : !(showQuestionNumber && previewMode) && previewMode }"
  [ngStyle]="{  'paddingTop': previewMode ? customThemeSettings['content_padding_from_top'] ? customThemeSettings['content_padding_from_top'] +'px' :checkForZero(customThemeSettings['content_padding_from_top'],'0px',''): '' ,
  'paddingBottom': previewMode ? customThemeSettings['content_padding_from_bottom'] ? customThemeSettings['content_padding_from_bottom'] +'px' : checkForZero(customThemeSettings['content_padding_from_bottom'],'0px',''):'',
  'paddingLeft':  previewMode? customThemeSettings['content_padding_from_left'] ? customThemeSettings['content_padding_from_left'] +'px' :checkForZero(customThemeSettings['content_padding_from_left'],'0px','') : '',
  'paddingRight':  previewMode ? customThemeSettings['content_padding_from_right'] ? customThemeSettings['content_padding_from_right'] +'px' :checkForZero(customThemeSettings['content_padding_from_right'],'0px',''): '' ,
  'border' : from ? 'none' :'' }"
>
  <div
    class="error_container"
    *ngIf="customQuestionError.length > 0"
    style="text-align: center;"
  >
    <p style="color: red;">{{ customQuestionError }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'required'"
    style="text-align: center;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'requested'"
    style="text-align: center;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>

  <div class="pl-4 ms-1">
    <ng-container *ngIf="!previewMode">
      <ng-container>
        <ng-container>
          <app-display-logic-view
            [currentQuestion]="question"
            [allQuestions]="allQuestions"
            [surveyData]="surveyData"
            (emitDisplayLogic)="displayLogicUpdated($event)"
          ></app-display-logic-view>
        </ng-container>
        </ng-container>
      </ng-container>
  </div>

  <div class="quest" [ngStyle]="{'display':from ? 'flex':''}">
    <h3
      *ngIf="!from  && showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [ngStyle]="{
      'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
      fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumbering(numbering)"
    ></h3>

    <h3
      *ngIf="!from &&  !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [ngStyle]="{
    'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
    ></h3>
    <h6
      *ngIf="from &&  showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngStyle]="{
        'margin-right':'1%','margin-top':'0.11%',
      'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
      fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [innerHtml]="getQuestionNumbering(numbering)"
    ></h6>

    <h6
      *ngIf="from &&  !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngStyle]="{'margin-right':'1%','margin-top':'0.11%',
    'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
    ></h6>
    <h6
      [ngStyle]="{color:previewMode && customThemeSettings &&customThemeSettings['question_font_color'] ? customThemeSettings['question_font_color'] :previewMode && customThemeSettings?'#a5a5a5':'',
    paddingBottom :  previewMode && customThemeSettings && customThemeSettings['padding_btw_question_n_options']  ? customThemeSettings['padding_btw_question_n_options']+ 'px' :'',
    'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',

    fontFamily :  previewMode && customThemeSettings && customThemeSettings['question_font_family'] ? customThemeSettings['question_font_family'] :'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :'',
    width:!question.question_tooltip?'100%':''}"
      [innerHtml]="question.text | safeHtml"
    ></h6>
    <i
      (click)="tooltipClick($event)"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{marginTop: '0.2%'}"
      *ngIf="!mobileView && question.question_tooltip"
      placement="auto"
      [ngbTooltip]="UploadQuestiontipContent"
    ></i>
    <ng-template #UploadQuestiontipContent>
      <div [innerHTML]="question.question_tooltip | safeHtml "></div>
    </ng-template>
    <i
      (click)="openModal($event,tooltipModalQuestion);$event.stopPropagation();"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{marginTop: '0.2%'}"
      *ngIf="this.mobileView && question.question_tooltip"
    ></i>
  </div>
  <!-- <ng-container *ngIf="!previewMode">
    <ul
      class="nav nav-tabs"
      id="myTab"
      role="tablist"
      *ngIf="displayLogic.length"
    >
      <li
        class="nav-item"
        *ngIf="displayLogic.length"
        (click)="displayLogicClickedEvent(true)"
      >
        <a
          class="nav-link"
          id="home-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          ><i class="fas fa-question mr-1"></i> Response Logic</a
        >
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show"
        [ngClass]="{'active': displayLogicClicked }"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <app-display-logic-view
          [currentQuestion]="question"
          [allQuestions]="allQuestions"
          [surveyData]="surveyData"
          (emitDisplayLogic)="displayLogicUpdated($event)"
        ></app-display-logic-view>
      </div>
    </div>
  </ng-container> -->
  <ng-template #tooltipModalQuestion let-modal>
    <a (click)="modal.close()" href="javascript:void(0)" class="close-modal"
      ><i class="fa fa-times"></i
    ></a>
    <div [innerHTML]="question.question_tooltip | safeHtml "></div>
  </ng-template>
  <div class="quest-body side-by-side list-item">
    <div class="choice-container table-responsive">
      <table class="choice-structure">
        <thead>
          <tr>
            <th
              [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              scope="col"
            ></th>
            <th
              *ngFor="let col of question.question_options.cols"
              scope="col"
              [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color']:previewMode && customThemeSettings ?'#a5a5a5': '' ,
              'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',





          background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
          fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
          paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
          paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
          fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
            >
              {{ col.text }}
            </th>
          </tr>
        </thead>
        <tbody
          *ngIf="
            (question.option_type == 1 || question.option_type == 2 || question.option_type == 3) &&
            question.repeat_header == 1
          "
        >
          <tr *ngIf="question.option_type != 3">
            <td
              [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color']:previewMode && customThemeSettings ?'#a5a5a5': '' ,
              'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
              paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
            ></td>
            <td
              [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color']:previewMode && customThemeSettings ?'#a5a5a5': '' ,
              'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
                background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
                fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
                paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
              *ngFor="let col of question.question_options.cols"
              style="padding: 0 !important;"
            >
              <table style="height: 100%;">
                <tbody>
                  <tr>
                    <td
                      *ngFor="let subcol of col.col_choices"
                      [ngStyle]="{
                        background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
                        color: previewMode && customThemeSettings && customThemeSettings['options_font_color']? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                        'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',                 
                        fontFamily : previewMode ? customThemeSettings['options_font_family'] :'',
                      paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                      paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                      fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                    >
                      {{ subcol.text }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr
            *ngFor="let row of question.question_options.rows; let i = index"
            scope="row"
          >
            <th
              scope="row"
              [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color']? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
              'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',




            background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
            fontFamily : previewMode ? customThemeSettings['options_font_family'] :'',
          paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
          paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px': '10px',
          fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
          [innerHtml]="row.text | safeHtml"
            >
              <!-- {{ row.text }} -->
            </th>
            <td
              [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              *ngFor="let col of question.question_options.cols; let k = index"
              style="padding: 0 !important;"
            >
              <table
                *ngIf="question.option_type == 1 || question.option_type == 2"
                style="height: 100%;"
              >
                <tbody>
                  <tr>
                    <td
                      [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                      *ngFor="let subcol of col.col_choices; let l = index"
                      (click)="checkRelatedInput($event, row, col, subcol, i, k, l)"
                    >
                      <div class="form-check">
                        <input
                          (change)="radioOptionChecked($event, row, col, subcol)"
                          *ngIf="question.option_type == 1"
                          style="vertical-align: middle;"
                          type="radio"
                          class="fsop-radio"
                          [checked]="isChecked(row, col, subcol)"
                          name="answer_{{question.id}}_{{ i }}_{{ k }}"
                          [checked]="isChecked(row, col, subcol)"
                        />

                        <input
                          (change)="checkboxOptionChecked($event, row, col, subcol)"
                          *ngIf="question.option_type == 2"
                          type="checkbox"
                          class="fsop-checkbox"
                          [checked]="isChecked(row, col, subcol)"
                          id="answer_{{question.id}}_{{ i }}_{{ k }}_{{ l }}"
                          [checked]="isChecked(row, col, subcol)"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table *ngIf="question.option_type == 3">
                <tbody>
                  <tr>
                    <td
                      [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
                                  paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                                  paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                                  minHeight : previewMode  ?  '24px' : ''}"
                    >
                      <select
                        (click)="onSelectBoxClick($event)"
                        (change)="onSelectedOptionChanged($event, row, col)"
                        [ngStyle]="{paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                              paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                          minHeight : previewMode  ?  '24px' : ''}"
                        class="form-control"
                      >
                        <option value="" selected disabled
                          >Select an answer</option
                        >
                        <option
                          [selected]="isChecked(row, col, subcol)"
                          [value]="subcol.url"
                          *ngFor="let subcol of col.col_choices"
                          >{{ subcol.text }}</option
                        >
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>

        <!--Middle-->

        <tbody
          *ngIf="(question.option_type == 1 || question.option_type == 2) && question.repeat_header == 2"
        >
          <ng-container
            *ngFor="let row of question.question_options.rows; let i = index"
          >
            <tr>
              <th
                scope="row"
                [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings && customThemeSettings['options_font_color']? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
                background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
            paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['question_font_weight'] :'':''}"
            [innerHtml]="row.text | safeHtml"
              >
                <!-- {{ row.text }} -->
              </th>
              <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                *ngFor="let col of question.question_options.cols; let k = index"
                style="padding: 0 !important;"
              >
                <table style="height: 100%;">
                  <tbody>
                    <tr>
                      <td
                        [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                        *ngFor="let subcol of col.col_choices; let l = index"
                        (click)="checkRelatedInput($event, row, col, subcol, i, k, l)"
                      >
                        <div class="form-check">
                          <!-- <input
                          *ngIf="question.option_type == 1"
                          style="vertical-align: middle;"
                          type="radio"
                          class="form-control"
                          name="answer_{{question.id}}_{{ i }}_{{ k }}"
                        />

                        <input
                          *ngIf="question.option_type == 2"
                          style="vertical-align: middle;"
                          type="checkbox"
                          class="form-control"
                          name="answer_{{question.id}}_{{ i }}_{{ k }}"
                        /> -->
                          <input
                            (change)="radioOptionChecked($event, row, col, subcol)"
                            *ngIf="question.option_type == 1"
                            style="vertical-align: middle;"
                            type="radio"
                            class="fsop-radio"
                            [checked]="isChecked(row, col, subcol)"
                            name="answer_{{question.id}}_{{ i }}_{{ k }}"
                            [checked]="isChecked(row, col, subcol)"
                          />

                          <input
                            (change)="checkboxOptionChecked($event, row, col, subcol)"
                            *ngIf="question.option_type == 2"
                            type="checkbox"
                            class="fsop-checkbox"
                            [checked]="isChecked(row, col, subcol)"
                            id="answer_{{question.id}}_{{ i }}_{{ k }}_{{ l }}"
                            [checked]="isChecked(row, col, subcol)"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr
              *ngIf="
                i <= question.question_options.rows.length / 2 - 1 && i > question.question_options.rows.length / 2 - 2
              "
            >
              <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              ></td>
              <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                *ngFor="let col of question.question_options.cols"
                style="padding: 0 !important;"
              >
                <table style="height: 100%;">
                  <tbody>
                    <tr>
                      <td
                        [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                        *ngFor="let subcol of col.col_choices"
                        [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                      'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',



                      background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
                    fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
                  paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                  paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                  fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                      >
                        {{ subcol.text }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </ng-container>
        </tbody>

        <!--Bottom Header-->

        <tbody
          *ngIf="(question.option_type == 1 || question.option_type == 2) && question.repeat_header == 3"
        >
          <ng-container
            *ngFor="let row of question.question_options.rows; let i = index; let last = last"
          >
            <tr>
              <th
                scope="row"
                [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',



                background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontFamily : previewMode ? customThemeSettings['options_font_family'] :'',
            paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
            [innerHtml]="row.text | safeHtml"
              >
                <!-- {{ row.text }} -->
              </th>
              <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                *ngFor="let col of question.question_options.cols; let k = index"
              >
                <table>
                  <tbody>
                    <tr>
                      <td
                        [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                        *ngFor="let subcol of col.col_choices; let l = index"
                        (click)="checkRelatedInput($event, row, col, subcol, i, k, l)"
                      >
                        <div class="form-check">
                          <!-- <input
                          *ngIf="question.option_type == 1"
                          style="vertical-align: middle;"
                          type="radio"
                          class="form-control"
                          name="answer_{{question.id}}_{{ i }}_{{ k }}"
                        />

                        <input
                          *ngIf="question.option_type == 2"
                          style="vertical-align: middle;"
                          type="checkbox"
                          class="form-control"
                          name="answer_{{question.id}}_{{ i }}_{{ k }}"
                        /> -->
                          <input
                            (change)="radioOptionChecked($event, row, col, subcol)"
                            *ngIf="question.option_type == 1"
                            style="vertical-align: middle;"
                            type="radio"
                            class="fsop-radio"
                            [checked]="isChecked(row, col, subcol)"
                            name="answer_{{question.id}}_{{ i }}_{{ k }}_{{ l }}"
                            [checked]="isChecked(row, col, subcol)"
                          />

                          <input
                            (change)="checkboxOptionChecked($event, row, col, subcol)"
                            *ngIf="question.option_type == 2"
                            type="checkbox"
                            class="fsop-checkbox"
                            [checked]="isChecked(row, col, subcol)"
                            id="answer_{{question.id}}_{{ i }}_{{ k }}"
                            [checked]="isChecked(row, col, subcol)"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr *ngIf="last">
              <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              ></td>
              <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                *ngFor="let col of question.question_options.cols"
              >
                <table>
                  <tbody>
                    <tr>
                      <td
                        [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                        *ngFor="let subcol of col.col_choices"
                        [ngStyle]="{color: previewMode  && customThemeSettings && customThemeSettings['options_font_color']? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                      'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',



                      background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
                    fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
                  paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                  paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                  fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                      >
                        {{ subcol.text }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </ng-container>
        </tbody>

        <!--Both Header-->

        <tbody
          *ngIf="(question.option_type == 1 || question.option_type == 2) && question.repeat_header == 4"
        >
          <ng-container
            *ngFor="let row of question.question_options.rows; let i = index; let first = first; let odd = odd; let last = last"
          >
            <tr >
              <th
                scope="row"
                [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
                background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
            paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
            [innerHtml]="row.text | safeHtml"
              >
                <!-- {{ row.text }} -->
              </th>
              <td
                *ngFor="let col of question.question_options.cols; let k = index"
                [ngStyle]="{
                  background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''
                }"
              >
                <table>
                  <tbody>
                    <tr>
                      <td
                        [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                        *ngFor="let subcol of col.col_choices; let l = index"
                        (click)="checkRelatedInput($event, row, col, subcol, i, k, l)"
                      >
                        <div class="form-check">
                          <!-- <input
                          *ngIf="question.option_type == 1"
                          style="vertical-align: middle;"
                          type="radio"
                          class="form-control"
                          name="answer_{{question.id}}_{{ i }}_{{ k }}"
                        />

                        <input
                          *ngIf="question.option_type == 2"
                          style="vertical-align: middle;"
                          type="checkbox"
                          class="form-control"
                          name="answer_{{question.id}}_{{ i }}_{{ k }}"
                        /> -->
                          <input
                            (change)="radioOptionChecked($event, row, col, subcol)"
                            *ngIf="question.option_type == 1"
                            style="vertical-align: middle;"
                            type="radio"
                            class="fsop-radio"
                            [checked]="isChecked(row, col, subcol)"
                            name="answer_{{question.id}}_{{ i }}_{{ k }}_{{ l }}"
                            [checked]="isChecked(row, col, subcol)"
                          />

                          <input
                            (change)="checkboxOptionChecked($event, row, col, subcol)"
                            *ngIf="question.option_type == 2"
                            type="checkbox"
                            class="fsop-checkbox"
                            [checked]="isChecked(row, col, subcol)"
                            name="answer_{{question.id}}_{{ i }}_{{ k }}"
                            [checked]="isChecked(row, col, subcol)"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr *ngIf="last || odd">
                <td [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}" ></td>
              <td *ngFor="let col of question.question_options.cols">
                <table>
                  <tbody>
                    <tr>
                      <td
                        [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                        *ngFor="let subcol of col.col_choices"
                        [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                      'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',



                      background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
                    fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
                  paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                  paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                  fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                      >
                        {{ subcol.text }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </ng-container>
        </tbody>

        <!--All Header-->

        <tbody
          *ngIf="(question.option_type == 1 || question.option_type == 2) && question.repeat_header == 5"
        >
          <ng-container
            *ngFor="let row of question.question_options.rows; let i = index; let even = even; let odd = odd"
          >
            <tr>
              <th
                scope="row"
                [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color']? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',


                'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',

                background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
            paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
            [innerHtml]="row.text | safeHtml"
              >
                <!-- {{ row.text }} -->
              </th>
              <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                *ngFor="let col of question.question_options.cols; let k = index"
              >
                <table>
                  <tbody>
                    <tr>
                      <td
                        [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                        *ngFor="let subcol of col.col_choices; let l = index"
                        (click)="checkRelatedInput($event, row, col, subcol, i, k, l)"
                      >
                        <div class="form-check">
                          <!-- <input
                          *ngIf="question.option_type == 1"
                          style="vertical-align: middle;"
                          type="radio"
                          class="form-control"
                          name="answer_{{question.id}}_{{ i }}_{{ k }}"
                        />

                        <input
                          *ngIf="question.option_type == 2"
                          style="vertical-align: middle;"
                          type="checkbox"
                          class="form-control"
                          name="answer_{{question.id}}_{{ i }}_{{ k }}"
                        /> -->
                          <input
                            (change)="radioOptionChecked($event, row, col, subcol)"
                            *ngIf="question.option_type == 1"
                            style="vertical-align: middle;"
                            type="radio"
                            class="fsop-radio"
                            [checked]="isChecked(row, col, subcol)"
                            name="answer_{{question.id}}_{{ i }}_{{ k }}_{{ l }}"
                            [checked]="isChecked(row, col, subcol)"
                          />

                          <input
                            (change)="checkboxOptionChecked($event, row, col, subcol)"
                            *ngIf="question.option_type == 2"
                            type="checkbox"
                            class="fsop-checkbox"
                            [checked]="isChecked(row, col, subcol)"
                            id="answer_{{question.id}}_{{ i }}_{{ k }}"
                            [checked]="isChecked(row, col, subcol)"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr *ngIf="even || odd">
              <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              ></td>
              <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                *ngFor="let col of question.question_options.cols"
              >
                <table>
                  <tbody>
                    <tr>
                      <td
                        [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                        *ngFor="let subcol of col.col_choices"
                        [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] :'',
                    fontSize:previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
                  fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
                  paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                  paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                  background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',

                  fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                      >
                        {{ subcol.text }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div *ngIf="previewMode && okButtonShow && question['option_type']=='2' && customThemeSettings['theme_layout'] == 'focus'">
      <button
      [id]="'okbt'+question.numbering"
              (click)="onOkClick()"
              class="btn btn-md"
              style="display: inline;margin-top: 20px; color: white; background: #54902b;"
            >OK
            </button>
    </div>
  </div>
</div>
