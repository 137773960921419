<div class="modal-content">
  <div class="fsop-alert-modal">
    <span>{{ id ? "Edit" : "Add" }} Custom Respondent Role</span>
  </div>
  <div class="fsop-alert-modal-body text-left">
    <form class="ce-form" novalidate [formGroup]="customRaterForm" style="overflow: visible">
      <div class="form-group row">
        <div class="col-sm-12">
          <label for="name" class="col-form-label" style="text-align: left"
            >Role: <span style="color: red">*</span></label
          >
          <div>
            <input
              type="text"
              [ngStyle]="{
                border:
                  (!customRaterForm.controls.name.valid && customRaterForm.controls.name.touched && isSubmitted) ||
                  (!customRaterForm.controls.name.valid && isSubmitted)
                    ? '1px solid red'
                    : '0px'
              }"
              formControlName="name"
              maxlength="30"
              class="form-control"
              placeholder=""
            />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button (click)="save()" [disabled]="!customRaterForm.valid" class="btn btn-md btn-primary">
      {{ id ? "Update" : "Submit" }}
    </button>
    <button (click)="closeModal()" class="btn btn-md btn-secondary">Cancel</button>
  </div>
</div>
