<app-loader [bgColor]="isLoading['bgColor']? '#D8D8D8' : ''" *ngIf="isLoading.bool"></app-loader>

<ng-template #sessionTimeoutModal let-modal>
    <div class="modal-content">
      <div class="confirm-modal-header">
        <h5 class="text-center">User Session Expired</h5>
      </div>
      <div class="fsop-alert-modal-body">
        <p>You have been logged out, please login again.</p>
      </div>
      <div class="modal-footer">
        <a class="btn btn-primary" [href] = "loginRoute()">Log in</a>
        <!-- (click)="clearStorage()" -->
      </div>
    </div>
</ng-template>

<router-outlet></router-outlet>
