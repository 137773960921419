import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class FileUploaderService {
  loneHttp: HttpClient;
  constructor(private handler: HttpBackend, private httpClient: HttpClient) {
    this.loneHttp = new HttpClient(handler);
  }

  public getPresignedUrl(body: any): Observable<any> {
    return this.httpClient.post(
      environment.BASE_URL + "get-presigned-url/",
      body
    );
  }

  /**
   * save data to s3
   * @param {string} presignedUrl
   * @param {FormData} body
   * @returns
   */

  saveFileToS3(presignedUrl: string, obj: any, image: any, filename: any) {
    // const headers = new HttpHeaders({ "Content-Type": "image/jpeg" });
    // return this.loneHttp.post(presignedUrl, body, { headers });
    let fd: any = new FormData();
    fd.append("Content-Type", obj["Content-Type"]);
    fd.append("key", obj["key"]);
    fd.append("policy", obj["policy"]);
    fd.append("x-amz-algorithm", obj["x-amz-algorithm"]);
    fd.append("x-amz-credential", obj["x-amz-credential"]);
    fd.append("x-amz-date", obj["x-amz-date"]);
    fd.append("x-amz-signature", obj["x-amz-signature"]);

    fd.append("x-amz-meta-uploaded_by", obj["x-amz-meta-uploaded_by"]);
    fd.append("x-amz-meta-file_location", obj["x-amz-meta-file_location"]);
    fd.append(
      "x-amz-meta-survey_group_type",
      obj["x-amz-meta-survey_group_type"]
    );
    fd.append("x-amz-meta-type_id", obj["x-amz-meta-type_id"]);
    fd.append("file", image, filename);

    return this.loneHttp.post(presignedUrl, fd);
  }

  public downloadFile(url: any): any {
    // const headers = new HttpHeaders({ "Content-Type": "binary/octet-stream" });
    return this.loneHttp.get(url, { responseType: "blob" });
  }
}
