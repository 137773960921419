import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive ({
    selector: "[drag-drop-animation]"
})

export class DragDropAnimationDirective {

    @Input('defaultClass') defaultClass:any;
    @Input('onDragClass') onDragClass:any; 

    constructor(
        private el: ElementRef
    ) {}


    @HostListener('dragover')
    OnDragOver() {
        this.el.nativeElement.setAttribute("class",this.onDragClass);
    }

    @HostListener('dragleave')
    OnDragLeave() {
        this.el.nativeElement.setAttribute("class",this.defaultClass);
    }

    @HostListener('drop')
    OnDrop() {
        this.el.nativeElement.setAttribute("class",this.defaultClass);
    }



    


}