<div
  [ngStyle]="{'background-color': bgColor}"
  [ngClass]="{'overlay' : isRealTime ? false : true,'realTimeOverlay':isRealTime ? true : false} "
>
  <div
    *ngIf="!isRealTime"
    [ngClass]="{'circle' : isRealTime ? false : true,'realTimeCircle':isRealTime ? true : false} "
  >
    <div class="loader-2 center"><span></span></div>
  </div>
</div>
