import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AssessmentDataService } from "../../../../../../app/core/assessment-data.service";
import { Observable, of, Subscription } from "rxjs";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-display-logic-view",
  templateUrl: "./app-display-logic-view.component.html",
  styleUrls: ["./app-display-logic-view.component.scss"],
})
export class DisplayLogicViewComponent implements OnInit {
  @Input() allQuestions: any;
  @Input() currentQuestion: any;
  @Input() surveyData: any;
  @Output("emitDisplayLogic")
  emitDisplayLogic = new EventEmitter<boolean>();
  postQuesSet: any = [];
  ShowDisplayBox: boolean = true;
  ShowSkipBox: boolean = true;

  conditionSetForMCQ: any = [
    { id: "Is Selected", name: "Is Selected" },
    { id: "Is Not Selected", name: "Is Not Selected" },
  ];

  conditionSetForTextbox: any = [
    { id: "Is greater than", name: "Is greater than" },
    { id: "Is greater than or equal to", name: "Is greater than or equal to" },
    { id: "Is less than", name: "Is less than" },
    { id: "Is less than or equal to", name: "Is less than or equal to" },
    { id: "Is equal to", name: "Is equal to" },
    { id: "Is not equal to", name: "Is not equal to" },
    { id: "Is empty", name: "Is empty" },
    { id: "Is not empty", name: "Is not empty" },
    { id: "Contains", name: "Contains" },
    { id: "Does not contain", name: "Does not contain" },
  ];

  interConditionSet: any = [
    { id: "AND", name: "And" },
    { id: "OR", name: "Or" },
  ];
  conditionsArr: any = [];
  @ViewChild("displayLogicModal", { static: false }) displayLogicModal: NgbModalRef;
  subscription: Subscription = new Subscription();
  displayLogicsData: any;
  destQuesDisLogic!: any;
  isQuestionhaveDisplayLogic: boolean = false;
  serverDisplayLogic: any = null;
  constructor(
    private modalService: NgbModal,
    private assessmentData: AssessmentDataService,
    private toastService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) { }
  ngOnInit() {
    this.listenToDisplayLogic();
    this.listenToDisplayLogicDelete();
    this.createQuestionList();
    this.editCondition();
    this.listenToSectionNameUpdate();
    this.serverDisplayLogic = this.route.snapshot.data["displayLogics"];
    this.destQuesDisLogic = this.destQuesDisLogicData(this.currentQuestion._id, this.serverDisplayLogic);
    this.isQuestionhaveDisplayLogic = this.checkIfDisplayLogicPresent();
  }

  checkIfDisplayLogicPresent() {
    let flag = false;
    if (this.currentQuestion.logic && this.currentQuestion.logic) {
      this.currentQuestion.logic.forEach((lg: any) => {
        if (lg.logic_type == 'display') {
          flag = true;
        }
      });
    }
    return flag;
  }

  destQuesDisLogicData(quesId: number, displayLogics: any[]): any {
    let flag = null;
    displayLogics.forEach((lg, key) => {
      if (lg[quesId]) {
        flag = lg[quesId];
      }
    })
    return flag;
  }

  goToOrigin(e: any) {
    e.stopPropagation();
    if (this.destQuesDisLogic) {
      let sourceQuesId = this.destQuesDisLogic[0].origin_question_detail.id;
      $(`[ques_id=${sourceQuesId}]`).click();
    }
  }

  getQuestionNumber(id: any) {
    let desQues: any;

    for (let i = 0; i < this.allQuestions.length; i++) {
      const section = this.allQuestions[i];
      for (let j = 0; j < section.questions.length; j++) {
        const question = section.questions[j];
        if (question._id === id) {
          desQues = question;
        }
      }
    }
    if (desQues && desQues.numbering) {
      return `Q${desQues.numbering}`;
    } else return '';
  }

  listenToSectionNameUpdate() {
    this.subscription.add(
      this.assessmentData.sectionNameUpdated$.subscribe((reason: any) => {
        if (reason && reason.isDelete) this.editCondition(reason["id"]);
        else this.editCondition();
      })
    );
  }

  listenToDisplayLogicDelete() {
    this.assessmentData.deleteDisplayLogic$.subscribe((logic: any) => {
      let desQuestion = logic.conditions[0].question_destination;
      if (this.serverDisplayLogic && this.serverDisplayLogic.length && this.currentQuestion._id == desQuestion) {
        for (let i = 0; i < this.serverDisplayLogic.length; i++) {
          const dLg = this.serverDisplayLogic[i];
          if (dLg[desQuestion] && dLg[desQuestion].length) {
            dLg[desQuestion] = dLg[desQuestion].filter((lg: any) => lg.id != logic.id);
            this.destQuesDisLogic = this.destQuesDisLogicData(this.currentQuestion._id, this.serverDisplayLogic);
            this.isQuestionhaveDisplayLogic = this.checkIfDisplayLogicPresent();
          }
        }
      }
    })
  }

  listenToDisplayLogic() {
    this.subscription.add(
      this.assessmentData.newDisplayLogicAdded$.subscribe((data: any) => {
        this.serverDisplayLogic = data;
        // this.assessmentData.getSurveyDisplayLogics(this.surveyData.identifier).subscribe((data:any)=>{
        this.destQuesDisLogic = this.destQuesDisLogicData(this.currentQuestion._id, data);
        this.isQuestionhaveDisplayLogic = this.checkIfDisplayLogicPresent();
        // },(error)=>{
        //   this.assessmentData.setLoader({ bool: false });
        // })


        // this.createQuestionList();
        // let body = data.body.data;
        // if (this.currentQuestion) {
        //   let qArr = this.currentQuestion.url.split("/");
        //   let id = +qArr[qArr.length - 2];

        //   if (body && data.body && data.id == id && this.currentQuestion) {
        //     this.currentQuestion["logic"] = body;
        //     this.editCondition();
        //   }
        // }
      })
    );
  }

  textToBeShown(conditions: any, deleteId?: any) {
    let finArr: any = [];
    conditions.forEach((condition: any) => {
      finArr.push(
        this.getQuesOptions(
          condition.question_destination,
          condition,
          condition.question_choice_locator,
          deleteId
        )
      );
    });

    // this.conditionsArr = finArr;
    if (finArr.length) this.conditionsArr.push(finArr);
    this.emitDisplayLogic.next(this.conditionsArr);
  }

  //List of all mcq and text entry type questions
  createQuestionList() {
    let cQues: boolean = false;
    this.postQuesSet = [];
    this.allQuestions.forEach((sec: any) => {
      sec.questions.forEach((ques: any) => {
        if (ques.obj_type == "pagebrake") {
          return;
        }
        if (ques.id == this.currentQuestion.id && !cQues) {
          cQues = true;
        } else if (cQues) {
          ques["name"] = 'Q' + ques.numbering + " " + ques.text;
          let qArr = ques.url.split("/");
          let id = +qArr[qArr.length - 2];
          ques["_id"] = id;
          // if (ques["_type"] == 1 || ques["_type"] == 2)
          this.postQuesSet.push(ques);
        }
      });
    });
  }

  getQuesOptions(quesId: any, condition: any, optionId?: any, deleteId?: any) {
    let quesText: any;
    let optionText: any;
    let isDeleted: any = false;
    this.postQuesSet.forEach((pq: any) => {
      let qArr = pq.url.split("/");
      let id = +qArr[qArr.length - 2];
      if (id == quesId) {
        quesText = 'Q' + pq.numbering + " " + pq.text;
        if (deleteId && deleteId == quesId) {
          isDeleted = true;
        }
      }
    });
    let ques_options = this.currentQuestion.question_options;
    if (ques_options)
      ques_options.forEach((option: any) => {
        if (option.id == optionId) {
          optionText = option.text;
        }
      });
    if (!quesText) {
      this.isInvalidCond = true;
    } else {
      this.isInvalidCond = false;
    }

    return {
      question_choosen: this.extractContent(quesText),
      option_choosen: optionText,
      operator: condition.operator,
      conjuction: condition.conjuction,
      operator_value: condition.operator_value,
      invalid: !quesText || isDeleted ? true : false,
    };
  }

  isInvalidCond: boolean = false;

  extractContent(s: any) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  editCondition(deleteId?: any) {
    // if (
    //   this.currentQuestion &&
    //   this.currentQuestion.logic &&
    //   this.currentQuestion.logic.length &&
    //   this.currentQuestion.logic[0].conditions &&
    //   this.currentQuestion.logic[0].conditions.length
    // ) {
    //   let conditions = this.currentQuestion.logic[0].conditions;
    //   this.textToBeShown(conditions);
    // } else {
    //   this.textToBeShown([]);
    // }
    this.conditionsArr = [];
    let arr = this.currentQuestion.logic;
    let hasDisplay: boolean = false;
    if (arr.length) {
      arr.forEach((ar: any) => {
        if (ar.logic_type == "display") {
          hasDisplay = true;
          this.textToBeShown(ar.conditions, deleteId);
        }
      });
    }
    if (!hasDisplay) {
      this.textToBeShown([]);
    }
  }

  displayLogicM: any;
  public openDisplayLogicModal(): void {
    this.displayLogicM = this.modalService.open(this.displayLogicModal, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      windowClass: "xlModal",
      size: "lg",
    });
  }

  public closeDisplayLogicModal(): void {
    // this.assessmentData.getSurveyDisplayLogics(this.surveyData['identifier']).subscribe((data:any)=>{
    //   this.assessmentData.newDisplayLogicAdded$.next(data);
    // })
    this.displayLogicM.close();
  }

  submitLogic(event: any) {
    let body = event["body"]["data"];
    let id = event["id"];
    if (!event.isEdit) {
      this.assessmentData.setLoader({ bool: true });
      this.assessmentData
        .saveLogic(this.surveyData["identifier"], id, body)
        .subscribe(
          (response: any) => {
            this.toastService.success("Logic added successfully.");
            // event["id"] = response.id;
            // event["body"]["id"] = response.id;
            this.currentQuestion["logic"] = response;
            this.assessmentData.displayLogicAdded$.next(event);
            this.assessmentData.getSurveyDisplayLogics(this.surveyData['identifier']).subscribe((data: any) => {
              this.assessmentData.newDisplayLogicAdded$.next(data);
              this.assessmentData.setLoader({ bool: false });
            })
          },
          (error: any) => {
            if (
              error.error &&
              error.error.length &&
              error.error[0] &&
              error.error[0].msg &&
              error.error[0].msg.length
            ) {
              this.toastService.error(error.error[0].msg[0]);
              this.assessmentData.setLoader({ bool: false });
            } else this.toastService.error("Something went wrong.");
          }
        );
    } else if (event.isEdit) {
      this.assessmentData.setLoader({ bool: true });
      this.assessmentData
        .saveLogic(this.surveyData["identifier"], id, body)
        .subscribe(
          (response: any) => {
            this.currentQuestion["logic"] = response;
            this.toastService.success("Logic updated successfully.");
            this.assessmentData.displayLogicAdded$.next(event);
            this.assessmentData.getSurveyDisplayLogics(this.surveyData['identifier']).subscribe((data: any) => {
              this.assessmentData.newDisplayLogicAdded$.next(data);
              this.assessmentData.setLoader({ bool: false });
            })
          },
          (error: any) => {
            if (
              error.error &&
              error.error.length &&
              error.error[0] &&
              error.error[0].msg &&
              error.error[0].msg.length
            ) {
              this.toastService.error(error.error[0].msg[0]);
              this.assessmentData.setLoader({ bool: false });
            } else this.toastService.error("Something went wrong.");
          }
        );
    }
    this.closeDisplayLogicModal();
  }

  modalref3: any;
  public openConfirmModal(modal: any): void {
    this.modalref3 = this.modalService.open(modal, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      windowClass: "xlModal",
      size: "sm",
    });
  }

  public closeConfirmModal(): void {
    this.modalref3.close();
  }

  delete() {
    let qArr = this.currentQuestion.url.split("/");
    let id = +qArr[qArr.length - 2];

    let displayLogicId: any;
    let arr = this.currentQuestion.logic;
    if (arr.length) {
      arr.forEach((ar: any) => {
        if (ar.logic_type == "display") {
          displayLogicId = ar.id;
        }
      });
    }
    this.assessmentData.setLoader({ bool: true });
    this.assessmentData
      .deleteLogicCondition(this.surveyData["identifier"], id, displayLogicId)
      .subscribe(
        (obj: any) => {
          this.toastService.success("Display logic removed successfully.");

          let displayLogicIdx: any;
          let arr = this.currentQuestion.logic;
          if (arr.length) {
            arr.forEach((ar: any, index: any) => {
              if (ar.logic_type == "display") {
                displayLogicIdx = index;
              }
            });
          }

          this.currentQuestion.logic.splice(displayLogicIdx, 1);
          let event = {
            body: {
              data: this.currentQuestion.logic,
            },
            id: id,
          };
          this.assessmentData.displayLogicAdded$.next(event);
          this.assessmentData.getSurveyDisplayLogics(this.surveyData['identifier']).subscribe((data: any) => {
            this.assessmentData.newDisplayLogicAdded$.next(data);
            this.assessmentData.setLoader({ bool: false });
          })
        },
        (error) => {
          this.assessmentData.setLoader({ bool: false });
          if (error && error.error && error.error.message) {
            this.toastService.error(error.error.message);
          } else {
            this.toastService.error("Something went wrong.");
          }
        }
      );
  }

  showDeleteOption() {
    return this.currentQuestion.logic &&
      this.currentQuestion.logic.display_logic &&
      this.currentQuestion.logic.display_logic.length &&
      this.currentQuestion.logic.display_logic[0] &&
      this.currentQuestion.logic.display_logic[0]["conditions"] &&
      this.currentQuestion.logic.display_logic[0]["conditions"].length
      ? true
      : false;
  }

  isInvalid() {
    let isInvalid: boolean = false;
    this.conditionsArr.forEach((condition: any) => {
      if (condition.invalid) {
        isInvalid = true;
      }
    });
    return isInvalid;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
