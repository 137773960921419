import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from "@angular/forms";
import { AssessmentDataService } from "../../../../../../../app/core/assessment-data.service";
import { Observable, of } from "rxjs";
@Component({
  selector: "app-common-logic-row",
  templateUrl: "./app-common-logic-row.component.html",
  styleUrls: ["./app-common-logic-row.component.scss"],
})
export class CommonLogicRowComponent implements OnInit {
  @Input() i: any;
  @Input() control: any;
  @Input() allQuestions: any;
  @Input() currentQuestion: any;
  @Input() postQuesSet: any;
  @Input() postSameSecQuesSet: any;
  @Input() postSecSet: any;
  @Input() includeSecQuesSet: any;
  @Input() parentForm: FormGroup;
  @Input() totalParentRows: any;
  @Input() onCloseModalTriggered:EventEmitter<any>;
  @Output()
  removeLogicEvent: EventEmitter<any> = new EventEmitter();

  /**
   * Observable for options list.
   */
  quesOptions$: any;

  isDefault: boolean = false;

  conditionSetForMCQ: any = [
    { id: "Is Selected", name: "Is Selected" },
    { id: "Is Not Selected", name: "Is Not Selected" },
  ];

  conditionSetForTextbox: any = [
    { id: "Is greater than", name: "Is greater than" },
    { id: "Is greater than or equal to", name: "Is greater than or equal to" },
    { id: "Is less than", name: "Is less than" },
    { id: "Is less than or equal to", name: "Is less than or equal to" },
    { id: "Is equal to", name: "Is equal to" },
    { id: "Is not equal to", name: "Is not equal to" },
    { id: "Is empty", name: "Is empty" },
    { id: "Is not empty", name: "Is not empty" },
    { id: "Contains", name: "Contains" },
    { id: "Does not contain", name: "Does not contain" },
  ];

  interConditionSet: any = [
    { id: "AND", name: "And" },
    { id: "OR", name: "Or" },
  ];

  showOperatorValue: boolean = true;
  constructor(private assessmentData:AssessmentDataService) {}
  ngOnInit() {
    // this.editCondition();
    if (this.currentQuestion._type == 1) {
      this.selectedQuesIsMCQ = true;

      this.quesOptions$ = of(this.currentQuestion["question_options"]);
    } else {
      this.selectedQuesIsTextbox = true;
    }
  }

  getArrayControl() {
    return (<FormArray>this.control.controls["conditions"]).controls;
  }

  selectedQuesIsMCQ: boolean = false;
  selectedQuesIsTextbox: boolean = false;

  // user changes choosen question -> show the options dropdown accordingly
  onQuestionChange(i: any) {
    this.selectedQuesIsMCQ = false;
    this.selectedQuesIsTextbox = false;
    const control = <FormArray>this.parentForm.controls["conditions"];
    const ithControl = control.controls[i];
    let quesId = this.parentForm.value["conditions"][i]
      .question_id_from_locator;
    let ques = this.postQuesSet.filter((elem: any) => {
      if (elem._id == quesId) {
        return true;
      }
    });

    let t = ques[0]._type;
    // if mcq
    if (t == 1) {
      ithControl.get("question_type")?.patchValue(t);
      this.quesOptions$ = this.getQuesOptions(i);
      this.selectedQuesIsMCQ = true;
    }
    // if text box
    else if (t == 2) {
      ithControl.get("question_type")?.patchValue(t);
      ithControl.get("question_choice_locator")?.patchValue(null);
      this.selectedQuesIsTextbox = true;
    }
  }

  getQuesOptions(i: any) {
    let quesId = this.parentForm.value["conditions"][i]
      .question_id_from_locator;
    if (quesId) {
      let ques = this.postQuesSet.filter((elem: any) => {
        if (elem._id == quesId) {
          return true;
        }
      });
      return of(ques[0]["question_options"]);
    } else of([]);
  }

  addRow() {
    this.addConditionList(this.control);
  }

  removeRow(i) {
    const ctrl = <FormArray>this.control.controls["conditions"];
    ctrl.removeAt(i);
    if(i==0){
      this.removeLogic(this.i);
      if(!(this.parentForm.controls['data'] as FormGroup).controls.length){
        // this.onCloseModalTriggered.next(true);
      }
    }
  }

  removeLogic(i: any) {
    // this.assessmentData.setLoader({ bool: true });
    this.removeLogicEvent.next(i);
  }

  editCondition() {
    if (
      this.currentQuestion.logic &&
      this.currentQuestion.logic.display_logic &&
      this.currentQuestion.logic.display_logic.length &&
      this.currentQuestion.logic.display_logic[0].conditions &&
      this.currentQuestion.logic.display_logic[0].conditions.length &&
      this.currentQuestion.logic.display_logic[0].conditions[this.i] &&
      this.parentForm.value["conditions"][this.i].question_id_from_locator
    ) {
      this.onQuestionChange(this.i);
    }
  }

  onTextboxConditionChange(item: any) {
    if (item && (item.id == "Is empty" || item.id == "Is not empty")) {
      this.showOperatorValue = false;
    } else {
      this.showOperatorValue = true;
    }
  }

  addConditionList(group: FormGroup, data?: any) {
    const control = <FormArray>group.controls["conditions"];
    control.push(this.initConditionList());
    return control;
  }

  private initConditionList(data?: any) {
    const group = new FormGroup({
      question_choice_locator: new FormControl(
        data && data.question_choice_locator
          ? data.question_choice_locator
          : null,
          this.currentQuestion._type === 1 ? [] : []
          // this.currentQuestion._type === 1 ? [Validators.required] : []
      ),
      operator: new FormControl(data && data.operator ? data.operator : null, [Validators.required]),
      conjuction: new FormControl(
        data && data.conjuction ? data.conjuction : null,
        [Validators.required]
      ),
      question_type: new FormControl(
        data && data.question_type ? data.question_type : null,
        []
      ),
      question_destination_type: new FormControl(
        data && data.question_type ? data.question_type : null,
        []
      ),
      operator_value: new FormControl(
        data && data.operator_value ? data.operator_value : null,
        this.currentQuestion._type === 2 ? [Validators.required] : []
      ),

      section_destination: new FormControl(
        data && data.section_destination ? data.section_destination : null,
        []
      ),
    });
    return group;
  }

  getFirstConditionControl() {
    return this.getArrayControl()[0];
  }
}
