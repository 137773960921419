<select class="form-control" 
[(ngModel)]="shortcodeSelected"
[ngModelOptions]="{ standalone: true }"
class="form-control"
(change)="onShortcodeChanged($event)"
>
  <option value="add_shortcode">Add Shortcode</option>
  <option value="survey_name">Survey Name</option>
  <option value="first_name">First Name</option>
  <option value="survey_link">Survey Link</option>
  <option value="unsubscribe_link">Unsubscribe Link</option>
  <option value="survey_expiration">Survey Expiration</option>
  <option value="survey_expiration_intl">Survey Expiration (International)</option>
  <option value="day_prior_expiration">Day Prior Expiration</option>
  <option value="ratee_name">Participant’s Name</option>
  <option value="participant_first_name">Participant’s First Name</option>
  <option value="member_name">Signature</option>
</select>