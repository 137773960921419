import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AssessmentDataService } from "../../../../../../../app/core/assessment-data.service";
import { Observable, of, Subscription } from "rxjs";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-branch-logic-section-view",
  templateUrl: "./app-branch-logic-section-view.component.html",
  styleUrls: ["./app-branch-logic-section-view.component.scss"],
})
export class BranchLogicSectionViewComponent implements OnInit {
  @Input() allQuestions: any;
  @Input() currentSection: any;
  ShowBranchBox: boolean = true;

  conditionSetForMCQ: any = [
    { id: "Is Selected", name: "Is Selected" },
    { id: "Is Not Selected", name: "Is Not Selected" },
  ];

  conditionSetForTextbox: any = [
    { id: "Is greater than", name: "Is greater than" },
    { id: "Is greater than or equal to", name: "Is greater than or equal to" },
    { id: "Is less than", name: "Is less than" },
    { id: "Is less than or equal to", name: "Is less than or equal to" },
    { id: "Is equal to", name: "Is equal to" },
    { id: "Is not equal to", name: "Is not equal to" },
    { id: "Is empty", name: "Is empty" },
    { id: "Is not empty", name: "Is not empty" },
    { id: "Contains", name: "Contains" },
    { id: "Does not contain", name: "Does not contain" },
  ];

  interConditionSet: any = [
    { id: "AND", name: "And" },
    { id: "OR", name: "Or" },
  ];

  nextQuesSet: any = [];
  conditionsArr: any;
  @ViewChild("branchLogicModal", { static: false }) branchLogicModal: NgbModalRef;
  subscription: Subscription = new Subscription();
  constructor(
    private modalService: NgbModal,
    private assessmentData: AssessmentDataService,
    private toastService: ToastrService
  ) { }
  ngOnInit() {
    this.listenToBranchLogic();
    this.createQuestionList();
    this.editCondition();
  }

  listenToBranchLogic() {
    this.subscription.add(
      this.assessmentData.branchLogicAdded$.subscribe((data: any) => {
        let body = data.body;
        if (this.currentSection) {
          let qArr = this.currentSection.url.split("/");
          let id = +qArr[qArr.length - 2];

          if (
            body &&
            body.section_destination == id &&
            this.currentSection &&
            this.currentSection.branching_logic &&
            this.currentSection.branching_logic.length &&
            this.currentSection.branching_logic[0] &&
            body.logic_type &&
            body.logic_type == "branching"
          ) {
            this.currentSection.branching_logic[0] = body;
            if (data.id) {
              this.currentSection.branching_logic[0]["id"] = data.id;
            }
            this.editCondition();
          } else if (
            body &&
            body.section_destination == id &&
            this.currentSection &&
            body.logic_type &&
            body.logic_type == "branching"
          ) {
            this.currentSection["branching_logic"] = [];
            this.currentSection["branching_logic"] = [{}];
            this.currentSection.branching_logic[0] = body;
            this.editCondition();
          }
          // else if (body && body.section_destination == id)
          else if (body) {
            this.currentSection["branching_logic"] = [];
            this.editCondition();
          }
        }
      })
    );
  }

  // {"question": 36681,"section": null, "type": "display","conditions":[
  //   {"question_id_from_locator": 36680, "question_choice_locator": 138960, "operator": "selected", "conjuction": null, "question_type":1, "operator_value": 10},
  //   {"question_id_from_locator": 36680, "question_choice_locator": 138961, "operator": "not selected", "conjuction": "AND", "question_type": 1, "operator_value": 10}
  //   ]}

  textToBeShown(conditions: any) {
    let finArr: any = [];
    conditions.forEach((condition: any) => {
      finArr.push(
        this.getQuesOptions(
          condition.section_destination,
          condition,
          condition.question_choice_locator
        )
      );
    });

    this.conditionsArr = finArr;
  }

  //List of all mcq and text entry type questions
  createQuestionList() {
    let qSec = this.currentSection.url.split("/");
    let secId = +qSec[qSec.length - 2];
    let gotCurrentSection: boolean = false;

    this.allQuestions.forEach((s: any, sIndex: any) => {
      s.questions.forEach((q: any) => {
        // let sVal = q.url.split("/");
        // let sId = +sVal[sVal.length - 2];
        // if (secId == sId) {
        //   gotCurrentSection = true;
        // } else if (gotCurrentSection) {
        let qArr = q.url.split("/");
        let id = +qArr[qArr.length - 2];
        q["_id"] = id;
        this.nextQuesSet.push(q);
        // }
      });
    });

    // let endOfSurvey = {
    //   name: "End Of Survey",
    // };
    // this.nextQuesSet.push(endOfSurvey);
  }

  getQuesOptions(quesId: any, condition: any, optionId?: any) {
    let quesText: any;
    let optionText: any;
    this.nextQuesSet.filter((ques: any) => {
      if (
        ques._id == condition.question &&
        condition.question_destination_type == "question"
      ) {
        quesText = ques.name;
        let ques_options = ques.question_options;
        if (ques_options)
          ques_options.forEach((option: any) => {
            if (option.id == optionId) {
              optionText = option.text;
            }
          });
      }
      // else if (
      //   ques._id == quesId &&
      //   condition.question_destination_type == "end_of_survey"
      // ) {
      //   quesText = "End of Survey";
      //   let ques_options = this.currentSection.question_options;
      //   if (ques_options)
      //     ques_options.forEach((option: any) => {
      //       if (option.id == optionId) {
      //         optionText = option.text;
      //       }
      //     });
      // }
    });

    return {
      question_choosen: this.extractContent(quesText),
      option_choosen: optionText,
      operator: condition.operator,
      conjuction: condition.conjuction,
      operator_value: condition.operator_value,
      invalid: !quesText ? true : false,
    };
  }

  extractContent(s: any) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  isInvalidLogicByDefault: boolean = false;
  editCondition() {
    if (
      this.currentSection &&
      this.currentSection &&
      this.currentSection.branching_logic &&
      this.currentSection.branching_logic.length &&
      this.currentSection.branching_logic[0]
    ) {
      if (
        this.currentSection.branching_logic[0].msg &&
        this.currentSection.branching_logic[0].msg == "Invalid Logic"
      ) {
        this.isInvalidLogicByDefault = true;
      } else {
        this.isInvalidLogicByDefault = false;
      }
      let conditions = this.currentSection.branching_logic[0];
      this.textToBeShown([conditions]);
    } else {
      this.textToBeShown([]);
    }
  }

  branchLogicM: any;
  public openBranchLogicModal(): void {
    this.branchLogicM = this.modalService.open(this.branchLogicModal, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      windowClass: "xlModal",
      size: "lg",
    });
  }

  public closeBranchLogicModal(): void {
    this.branchLogicM.close();
  }

  modalref3: any;
  public openConfirmModal(modal: any): void {
    this.modalref3 = this.modalService.open(modal, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      windowClass: "xlModal",
      size: "sm",
    });
  }

  public closeConfirmModal(): void {
    this.modalref3.close();
  }

  isInvalid() {
    let isInvalid: boolean = false;
    this.conditionsArr.forEach((condition: any) => {
      if (condition.invalid) {
        isInvalid = true;
      }
    });
    return isInvalid;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
