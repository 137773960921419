import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-confirm-modal",
  templateUrl: "./app-confirm-modal.component.html",
  styleUrls: ["./app-confirm-modal.component.scss"],
})
export class ConfirmModalComponent implements OnInit {
  @Output()
  onCloseModalTriggered: EventEmitter<any> = new EventEmitter();

  @Output()
  submitTriggered: EventEmitter<any> = new EventEmitter();

  @Input()
  title: any;

  @Input()
  message: any;

  @Input()
  buttonName: any;
  id: any;

  constructor() {}

  ngOnInit() {}

  save() {
    this.submitTriggered.next(this.id);
    setTimeout(() => {
      this.onCloseModalTriggered.next(true);
    }, 300);
  }

  closeModal() {
    this.onCloseModalTriggered.next(true);
  }
}
