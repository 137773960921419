<div
  id="{{numbering}}"
  class="question-container print"
  [ngClass]="{ selected: question.uiSelected && !previewMode, 'non-editable': (editable && question.answered && backClicked) ,'previewMode' : previewMode ? true : false, 'wth-out-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
  [ngStyle]="{  'paddingTop':   previewMode ? customThemeSettings['content_padding_from_top'] ? customThemeSettings['content_padding_from_top'] +'px' :checkForZero(customThemeSettings['content_padding_from_top'],'0px',''): '' ,
          'paddingBottom': previewMode ? customThemeSettings['content_padding_from_bottom'] ? customThemeSettings['content_padding_from_bottom'] +'px' : checkForZero(customThemeSettings['content_padding_from_bottom'],'0px',''):'',
          'paddingLeft':  previewMode? customThemeSettings['content_padding_from_left'] ? customThemeSettings['content_padding_from_left'] +'px' :checkForZero(customThemeSettings['content_padding_from_left'],'0px',''):  '',
          'paddingRight':  previewMode ? customThemeSettings['content_padding_from_right'] ? customThemeSettings['content_padding_from_right'] +'px' :checkForZero(customThemeSettings['content_padding_from_right'],'0px',''):''  ,
          'border' : from ? 'none' :'' }"
>
  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'required'"
    style="text-align: center;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'requested'"
    style="text-align: center;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>

  <div class="pl-4 ms-1">
    <ng-container *ngIf="!previewMode">
      <ng-container>
        <ng-container>
          <app-display-logic-view
            [currentQuestion]="question"
            [allQuestions]="allQuestions"
            [surveyData]="surveyData"
            (emitDisplayLogic)="displayLogicUpdated($event)"
          ></app-display-logic-view>
        </ng-container>
        </ng-container>
      </ng-container>
  </div>
  
  <div class="quest" [ngStyle]="{'display':from ? 'flex':''}">
    <h3
      *ngIf="!from  && showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [ngStyle]="{'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumbering(numbering)"
    ></h3>

    <h3
      *ngIf="!from  && !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [ngStyle]="{'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
  fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
    >
      <sup
        style="color: red;"
        *ngIf="showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
        >*</sup
      >
    </h3>
    <h6
      *ngIf="from  && showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngStyle]="{'margin-right':'1%','margin-top':'0.11%',
    'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumbering(numbering)"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
    ></h6>

    <h6
      *ngIf="from && !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngStyle]="{'margin-right':'1%','margin-top':'0.11%',
  'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
  fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
    ></h6>
    <h6
      [ngStyle]="{color:previewMode && customThemeSettings &&customThemeSettings['question_font_color'] ? customThemeSettings['question_font_color'] :previewMode && customThemeSettings?'#a5a5a5':'',
    paddingBottom :  previewMode && customThemeSettings && customThemeSettings['padding_btw_question_n_options']  ? customThemeSettings['padding_btw_question_n_options']+ 'px' :'',
   
   
    'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',

   
    fontFamily :  previewMode && customThemeSettings && customThemeSettings['question_font_family'] ? customThemeSettings['question_font_family'] :'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :'',
    width:!question.question_tooltip?'100%':''}"
      [innerHtml]="question.text | safeHtml"
    ></h6>
    <i
      (click)="tooltipClick($event)"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{marginTop: '0.2%'}"
      *ngIf="!mobileView && question.question_tooltip"
      placement="auto"
      [ngbTooltip]="UploadQuestiontipContent"
    ></i>
    <ng-template #UploadQuestiontipContent>
      <div [innerHTML]="question.question_tooltip | safeHtml "></div>
    </ng-template>
    <i
      (click)="openModal($event,tooltipModalQuestion);$event.stopPropagation();"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{marginTop: '0.2%'}"
      *ngIf="this.mobileView && question.question_tooltip"
    ></i>
  </div>
  <!-- <ng-container *ngIf="!previewMode">
    <ul
      class="nav nav-tabs"
      id="myTab"
      role="tablist"
      *ngIf="displayLogic.length"
    >
      <li
        class="nav-item"
        *ngIf="displayLogic.length"
        (click)="displayLogicClickedEvent(true)"
      >
        <a
          class="nav-link"
          id="home-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          ><i class="fas fa-question mr-1"></i> Response Logic</a
        >
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show"
        [ngClass]="{'active': displayLogicClicked }"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <app-display-logic-view
          [currentQuestion]="question"
          [allQuestions]="allQuestions"
          [surveyData]="surveyData"
          (emitDisplayLogic)="displayLogicUpdated($event)"
        ></app-display-logic-view>
      </div>
    </div>
  </ng-container> -->
  <ng-template #tooltipModalQuestion let-modal>
    <a (click)="modal.close()" href="javascript:void(0)" class="close-modal"
      ><i class="fa fa-times"></i
    ></a>
    <div [innerHTML]="question.question_tooltip | safeHtml "></div>
  </ng-template>
  <div class="list-item">
    <div
      class="item"
      *ngFor="let option of question.question_options; let i = index; let first = first;"
    >
      <div class="form-inline">
        <label
          class="form-control-label"
          [ngStyle]="{color:previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
        fontSize:previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :'14px' :'',
      fontFamily : previewMode ? customThemeSettings['options_font_family'] :'',
    fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
    [innerHtml]="option.text | safeHtml"
          for=""
          >
          <!-- {{ option.text }} -->
          </label
        >
        <select
          (click)="onSelectBoxClick($event)"
          (change)="onResponseSelected($event, i)"
          [ngStyle]="{paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                    paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            minHeight : previewMode  ?  '24px' : ''}"
          class="form-control"
        >
          <ng-container *ngIf="previewMode">
            <ng-container *ngIf="first">
              <option value="" selected disabled>Select an answer</option>
              <option
                [selected]="path.indexOf(choice.key) > -1"
                [value]="choice.key"
                *ngFor="let choice of question?.choices | keys"
              >
                {{choice.key}}
              </option>
            </ng-container>

            <ng-container
              *ngIf="!first && array.length > 0 && array[i-1] != null"
            >
              <ng-container *ngFor="let c of array[i-1] | keys">
                <option value="" selected disabled>Select an answer</option>
                <option
                  [selected]="path.indexOf(d.key) > -1"
                  *ngFor="let d of c.value | keys"
                  [value]="d.key"
                  >{{d.key}}</option
                >
              </ng-container>
            </ng-container>
          </ng-container>
        </select>
      </div>
    </div>
    <div *ngIf="previewMode && okButtonShow && customThemeSettings['theme_layout'] == 'focus'">
      <button
      [id]="'okbt'+question.numbering"
              (click)="onOkClick()"
              class="btn btn-md"
              style="display: inline; color: white; background: #54902b;"
            >OK
            </button>
    </div>
  </div>
</div>
