import { Injectable } from '@angular/core';
import { ParticipantPortal } from '../models/survey-detail-for-participants.model';
import { NgbDate, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import moment, { Moment } from 'moment';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class UtilsService {

	constructor() { }

	public validField(fieldText: string, minLength: number): boolean {
		return fieldText.length > minLength
	}


	static makeTimeString(time: { hour: number, minute: number, second: number }) {
		let hh: any = time.hour;
		let m: any = time.minute;
		let dd = (hh >= 12) ? "PM" : "AM";
		hh = (hh >= 12) ? hh - 12 : hh;
		hh = (hh === 0) ? 12 : hh;
		m = (m < 10) ? "0" + m : m;
		hh = (hh < 10) ? "0" + hh : hh;

		const formattedTime = `${hh}:${m} ${dd}`;
		return formattedTime;
	}

	/**
	 * Converts
	 * 	2024-03-31T02:11:00Z
	 * 
	 *  to NgbDate 
	 * 
	 *  {
	 *  	year:2024,
	 * 	    month:3,
	 *      date:31
	 *  }
	 */
	static makeDateObject(date: string): NgbDateStruct {
		if (!date) {
			throw new Error("Date was not provided");
		}

		let dateArray: string[] = [];
		let hasTime: boolean = date.indexOf("T") != -1;
		if (hasTime) {
			dateArray = date.split("T")[0].split("-");
		} else {
			dateArray = date.split("-")
		}

		return {
			year: +dateArray[0],
			month: +dateArray[1],
			day: +dateArray[2]
		}
	}

	static makeMomentDateFromDateObject(date: NgbDateStruct, format: string = "YYYY-MM-DD"): Moment {
		return moment([
			date.year.toString(),
			date.month.toString(),
			date.day.toString()
		], format);
	}

	static makeDateObjectFromMomentDate(date: Moment): NgbDateStruct {
		return {
			year: date.get("year"),
			month: date.get("month") + 1,
			day: date.get("date")
		}
	}


	static makeTimeObject(date: string): NgbTimeStruct {
		let hour: string = moment(date).parseZone().format("HH");
		let minute: string = moment(date).parseZone().format("mm");

		return {
			hour: +hour,
			minute: +minute,
			second: 0
		}
	}


	static makeCompleteDateString(date: NgbDateStruct, time: NgbTimeStruct): string {
		const year: string = date.year.toString();
		const month: string = date.month.toString();
		const day: string = date.day.toString();

		let hour: string = time.hour.toString();
		let minute: string = time.minute.toString();

		const dateString: string = `${year}-${month}-${day}`;
		const timeString: string = `${hour}:${minute}:00`;

		return `${dateString}T${timeString}`
	}

	public static createHttpParams(params: any): HttpParams {
	
		let httpParams: HttpParams = new HttpParams()
		Object.keys(params).forEach(param => {
			if (params[param]) {
				httpParams = httpParams.set(param, params[param])
			}
		})

		return httpParams
	}


}


/**
 *  Groups array by role name 
 * [
 * 	{
 * 	  role:"Peer",
 *    ...someData
 * 	},
 *  {
 * 	  role:"Peer",
 *    ...someData
 * 	}
 * ]
 * 
 * to
 * 
 * [
 * 	 {
 *     role:"Peer",
 *     raters:[ {role:"Peer", ...someData},{role:"Peer", ...someData}]
 *   }
 * ]
 */
export class RaterGroupAdapter {
	static groupByRole(raterList: ParticipantPortal.Rater[]): ParticipantPortal.RatersByCategory[] {

		const grouped: { [role: string]: ParticipantPortal.Rater[] } = {};

		raterList.forEach(obj => {
			const { role } = obj;
			if (!grouped[role]) {
				grouped[role] = [];
			}
			grouped[role].push(obj);
		});

		return Object.keys(grouped).map(role => ({
			role,
			raters: grouped[role]
		}));
	}
}
