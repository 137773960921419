import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";

@Component({
  selector: 'app-ressource-not-found',
  templateUrl: './ressource-not-found.component.html',
  styleUrls: ['./ressource-not-found.component.scss']
})
export class RessourceNotFoundComponent implements OnInit {

  constructor(private _location: Location,) { }

  ngOnInit() {
  }

  goBack(){
    this._location.back();
  }

}
