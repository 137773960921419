<div class="modal-content">
  <div class="fsop-alert-modal">
    <span>{{title}}</span>
  </div>
  <div class="fsop-alert-modal-body">
    <p class="mb-0" [innerHtml]="message"></p>
  </div>
  <div class="modal-footer justify-content-center">
    <button (click)="save()" class="btn btn-md btn-primary">
      {{buttonName}}
    </button>
    <button (click)="closeModal()" class="btn btn-md btn-secondary">
      Cancel
    </button>
  </div>
</div>
