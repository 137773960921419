import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
  AfterViewInit,
} from "@angular/core";
import { AssessmentDataService } from "../../../../../app/core/assessment-data.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
@Component({
  selector: "fsop-rank-order",
  templateUrl: "./fsop-rank-order.component.html",
  styleUrls: ["./fsop-rank-order.component.scss"],
})
export class FsopRankOrderComponent implements OnInit, AfterViewInit {
  okButtonShow = false;
  @Output("onQuestionDone")
  onQuestionDone = new EventEmitter<any>();

  @Input("question")
  question: any;

  @Input("showQuestionNumber")
  showQuestionNumber: boolean;
  @Input("from") from = "";
  @Input("theme")
  theme: string;

  @Input("customThemeSettings") customThemeSettings: object;
  @Input("numbering")
  numbering: number;

  @Input("isNextButtonClicked")
  isNextButtonClicked: boolean;

  @Output("resetNextButton")
  resetNextButton = new EventEmitter<boolean>();

  sortableJSOptions: any;
  @Input("previewMode")
  previewMode: boolean;
  customQuestionError: string = "";
  invalidRankErrorMessage: string = "";
  // questionAnswer: any = {};

  concernedQuestionOption: any;

  @Input("showRequiredAsterisk") showRequiredAsterisk: boolean;

  @Input("editable") editable: boolean;
  @Input("backClicked") backClicked: boolean;
  @Input() allQuestions: any;
  @Input() surveyData: any;
  mobileView: boolean = false;

  constructor(private modalService: NgbModal, private assessmentDataService: AssessmentDataService) {
    this.sortableJSOptions = {
      onUpdate: (event: any) => {
        this.resetNextButton.emit(true);

        var handleEventChange = (q: any) => {
          q.answer.length = 0;
          for (var i = 0; i < this.question.question_options.length; i++) {
            q.answer.push({
              option: this.question.question_options[i].url,
              order: i + 1,
              position: this.question.question_options[i].position,
            });
          }
          this.updateQuestionAnswer(q);
        };

        // if (this.question.answer !== undefined) {
        handleEventChange(this.question);
        // }else{
        // handleEventChange(this.questionAnswer);
        // }
      },
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.previewMode) {
      const nextClickedChange: SimpleChange = changes.isNextButtonClicked;
      this.isNextButtonClicked =
        nextClickedChange && nextClickedChange.currentValue;

      if (this.isNextButtonClicked) {
        // if(this.question.answer !== undefined){
        this.checkCustomValidation(this.question);
        // }else{
        // this.checkCustomValidation(this.questionAnswer);
        // }
      }
    }
  }

  checkForZero(value: any, zeroReturnValue: any, defaultValue: any) {
    if (value == 0) {
      return zeroReturnValue;
    } else {
      return defaultValue;
    }
  }

  tooltipClick(event: any) {
    event.stopPropagation();
  }

  ngOnInit() {
    if (window.outerWidth < 769) {
      this.mobileView = true;
    }
    if (this.previewMode) {
      // this.questionAnswer = {
      //   answer:[],
      //   ...this.question
      // };
      if (
        !this.question.hasOwnProperty("answer") ||
        (this.question.answer &&
          (this.question.answer.length !=
            this.question.question_options.length ||
            (this.question.answer.length ==
              this.question.question_options.length &&
              this.question.answer.filter((a: any) => {
                return !a["order"] || !a["option"];
              }).length != 0)))
      ) {
        this.question["answered"] = false;
        this.question.answer = [];
        this.question.question_options.forEach((op: any, i: number) => {
          // this.questionAnswer.answer.push({
          //   option: op.url,
          //   order: (this.question.option_type == 3 || this.question.option_type == 4)? 0 : i+1,
          //   position:op.position
          // })

          this.question.answer.push({
            option: op.url,
            order: 0,
            position: op.position,
          });
        });
      } else {
        // if(this.question.option_type !== 4){
        let ans_array: any = [];
        this.question.question_options.forEach((op: any, i: number) => {
          let _index = this.question.answer.findIndex((obj: any) => {
            return obj.option == op.url;
          });
          ans_array.push({
            option: op.url,
            order: _index > -1 ? this.question.answer[_index]["order"] : 0,
            position: op.position,
          });
        });

        setTimeout(() => {
          this.backClicked = true;
        }, 500);

        this.question.answer = [];
        this.question["answer"] = ans_array;
        // }
      }
      this.checkIfAlreadyAnswered();

      if (this.question.validation_option.is_custom_validation) {
        this.concernedQuestionOption = this.question.question_options[
          this.question.validation_option.choice - 1
        ];
      }
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (
        window.name &&
        window.name == "frameAndHashing" &&
        window.innerWidth < 769
      ) {
        this.mobileView = true;
      }
    }, 100);
  }
  checkIfAlreadyAnswered() {
    let tempArr: any = [];

    this.question.question_options[0].order
      ? function () {
          this.question.question_options.forEach((element: any, index: any) => {
            tempArr[
              this.question.question_options[index]["order"] - 1
            ] = element;
          });
          this.question.question_options = [...tempArr];
        }.bind(this)()
      : "";
  }
  openModal(e: any, content: any) {
    this.modalService.open(content, {
      size: "sm",
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "tooltip-modal",
    });
  }
  private updateQuestionAnswer(question: any) {
    if (this.previewMode) {
      this.okButtonShow = true;
      const answer = question.answer.filter((a: any) => {
        return a.order && a.option;
      });

      let qa = {
        url: question.url,
        _type: question._type,
        answered: answer.length == question.answer.length ? true : false,
        answer: question.answer,
      };
      this.question["answered"] =
        answer.length == question.answer.length ? true : false;

      this.onQuestionDone.emit(qa);
    }
  }

  up(option: any): void {
    this.resetNextButton.emit(true);
    let old_pos: number = this.question.question_options.indexOf(option);
    let new_pos: any = old_pos - 1 >= 0 ? old_pos - 1 : null;
    if (new_pos != null) {
      this.question.question_options = this.array_move(
        JSON.parse(JSON.stringify(this.question.question_options)),
        old_pos,
        new_pos
      );
    }
    this.updateUpAndDownAnswer();
  }

  down(option: any): void {
    this.resetNextButton.emit(true);
    let old_pos: number = this.question.question_options.indexOf(option);
    let new_pos: any =
      old_pos + 1 < this.question.question_options.length ? old_pos + 1 : null;

    if (new_pos != null) {
      this.question.question_options = this.array_move(
        JSON.parse(JSON.stringify(this.question.question_options)),
        old_pos,
        new_pos
      );
    }
    this.updateUpAndDownAnswer();
  }

  private array_move(arr: any[], old_index: number, new_index: number): any[] {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  public updateUpAndDownAnswer(): void {
    var handleEventCheck = (q: any) => {
      for (var k = 0; k < this.question.question_options.length; k++) {
        let obj = {
          option: this.question.question_options[k].url,
          order: k + 1,
          position: this.question.question_options[k].position,
        };

        q.answer[k] = obj;
      }

      this.updateQuestionAnswer(q);
    };

    // if (this.question.answer !== undefined) {
    handleEventCheck(this.question);
    // }else{
    //   handleEventCheck(this.questionAnswer);
    // }
  }

  public radioOptionChecked(
    event: any,
    row: any,
    rowIndex: any,
    colIndex: number
  ): void {
    this.resetNextButton.emit(true);

    var handleEventChange = (q: any) => {
      let fetchedAnswer = q.answer.find((a: any) => {
        return a.option === row.url;
      });
      let arr_index = q.answer.findIndex((a: any) => {
        return a.order === colIndex;
      });
      if (arr_index == -1) {
        if (fetchedAnswer !== undefined) {
          let fetchedAnswerIndex = q.answer.indexOf(fetchedAnswer);
          q.answer[fetchedAnswerIndex].option = row.url;
          q.answer[fetchedAnswerIndex].position = row.position;
          q.answer[fetchedAnswerIndex].order = colIndex;
        } else {
          q.answer[rowIndex] = {
            order: colIndex,
            option: row.url,
            position: row.position,
          };
        }

        this.updateQuestionAnswer(q);
      } else {
        let filled_row_name = `answer_${q.id}_${arr_index}`;
        document
          .querySelectorAll('input[type=radio][name="' + filled_row_name + '"]')
          .forEach((ele: any) => {
            ele["checked"] = false;
          });
        q.answer[arr_index]["order"] = 0;
        q.answer[rowIndex]["order"] = colIndex;
        this.updateQuestionAnswer(q);
      }
    };
    // if (this.question.hasOwnProperty('answer')) {
    handleEventChange(this.question);
    // }else{
    //   handleEventChange(this.questionAnswer);
    // }
  }

  isCustomized(ques: any) {
    let is_randomize = false;
    if (ques.option_type == 1 || ques.option_type == 2) {
      // debugger;
      // ques.answer.forEach((ans: any, index: any)=>{
      //   if(ans.position != index+1){
      //     is_randomize = true
      //   }
      // })

      for (let index = 0; index < ques.answer.length; index++) {
        const element = ques.answer[index];
        if (element.position != index + 1) {
          is_randomize = true;
          break;
        }
      }

      return is_randomize;
    } else {
      // return true;
      // if (ques.option_type == 3){
      for (let index = 0; index < ques.answer.length; index++) {
        const element = ques.answer[index];
        if (element.order) {
          is_randomize = true;
          break;
        }
      }
      // ques.answer.forEach((ans: any, index: any)=>{
      //   if(ans.order){
      //     is_randomize = true
      //   }
      // })
      return is_randomize;
      // }
    }
  }

  checkCustomValidation(q: any): void {
    if (this.question.option_type === 3 || this.question.option_type === 4) {
      this.invalidRankErrorMessage = "";
      delete this.question["hasSameRankError"];

      let occurences = q.answer.reduce((acc: any, it: any) => {
        if (it.order) {
          acc[it.order] = acc[it.order] + 1 || 1;
          return acc;
        }
      }, {});

      if (occurences) {
        Object.values(occurences).forEach((val: any, i: any) => {
          if (val >= 2) {
            this.invalidRankErrorMessage =
              "Two ore More choices cannot have the same rank.";
            this.question["hasSameRankError"] = true;
            return;
          }
        });
      }
    }

    if (this.question.validation_option.is_custom_validation) {
      if (
        this.question.validation_option.validation == "0" &&
        !this.isCustomized(q)
      ) {
        this.customQuestionError = "";
        delete this.question["hasCustomValidationError"];
        return;
      }
      if (
        this.question.validation_option.validation != "2" ||
        (this.question.validation_option.validation == "2" &&
          this.isCustomized(q))
      ) {
        let fetched = q.answer.find((a: any) => {
          return a.option === this.concernedQuestionOption.url;
        });

        if (fetched !== undefined) {
          if (
            parseInt(fetched.order) !==
            this.question.validation_option.placed_at
          ) {
            this.customQuestionError = this.question.validation_option.msg;
            this.question["hasCustomValidationError"] = true;
          } else {
            this.customQuestionError = "";
            delete this.question["hasCustomValidationError"];
          }
        }
      } else {
        this.customQuestionError = "";
        delete this.question["hasCustomValidationError"];
      }
    }
  }

  drop(event: CdkDragDrop<string[]>){
    if(event.previousIndex != event.currentIndex){
      moveItemInArray(this.question.question_options, event.previousIndex, event.currentIndex);
      this.resetNextButton.emit(true);
      var handleEventChange = (q: any) => {
        q.answer.length = 0;
        for (var i = 0; i < this.question.question_options.length; i++) {
          q.answer.push({
            option: this.question.question_options[i].url,
            order: i + 1,
            position: this.question.question_options[i].position,
          });
        }
        this.updateQuestionAnswer(q);
      };
      handleEventChange(this.question);
    }
  }

  public onRankChange(event: any, option: any, index: number): void {
    let allowed_keys = [
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Tab",
      "Delete",
      "Backspace",
    ];

    if (event.key.match(/[0-9]/g) || allowed_keys.indexOf(event.key) > -1) {
      this.resetNextButton.emit(false);
      let rank = event.key.match(/[0-9]/g) ? parseInt(event.target.value) : 0;
      let _id = `answer_${this.question.id}_${index}`;

      var handleEventChange = (q: any) => {
        let fetchedOption = q.answer.filter((answer: any) => {
          return answer.option !== null && answer.option === option.url;
        });
        let fetchedRank = q.answer.filter((answer: any) => {
          return rank && answer.order == rank;
        });

        if (fetchedRank.length) {
          let oldPos = q.answer.indexOf(fetchedOption[0]);
          q.answer[oldPos].order = 0;
          let d:any = document.getElementById(_id);
          d["value"] = null;
        } else {
          if (fetchedOption.length > 0) {
            let oldPos = q.answer.indexOf(fetchedOption[0]);
            q.answer[oldPos].order = rank;
            // this.array_move(q.answer, oldPos, rank-1);
          } else {
            q.answer[rank - 1] = {
              option: option.url,
              order: rank,
              position: option.position,
            };
          }
        }

        this.updateQuestionAnswer(q);
      };

      if (this.getLength(rank) <= this.question.question_options.length) {
        // if (this.question.answer !== undefined) {
        handleEventChange(this.question);
        // }else{
        //     handleEventChange(this.questionAnswer);
        // }
      }
    }
  }

  focusOut(event: any, option: any) {
    let _value = event.target.value;
    let ans = this.question.answer.filter((ans_obj: any) => {
      return ans_obj.order == _value;
    });

    if (ans.length > 1) {
      let ans_index = this.question.answer.findIndex((ans_obj: any) => {
        return ans_obj.option == option.url;
      });
      this.question.answer[ans_index]["order"] = 0;
    }
  }

  public getRankValue(option: any) {
    if (this.previewMode) {
      var handleRankValue = (q: any) => {
        let ftched = q.answer.filter((a: any) => {
          return a.option === option.url;
        })[0];

        let _order = ftched.order ? parseInt(ftched.order) : 0;

        if (ftched !== undefined) {
          return _order > 0 && _order <= this.question.question_options.length
            ? _order
            : null;
        }
      };

      // if(this.question.hasOwnProperty('answer')){
      return handleRankValue(this.question);
      // }else{
      //   return handleRankValue(this.questionAnswer);
      // }
    }
  }

  public getLength(n: number): number {
    return n.toString().length;
  }

  public isChecked(row: any, index: number) {
    if (this.previewMode) {
      var handleCheckedRow = (q: any) => {
        let fetchedAnswer = q.answer.find((a: any) => {
          return a.order === index && a.option === row.url;
        });

        return fetchedAnswer !== undefined;
      };

      // if(this.question.hasOwnProperty('answer')){
      return handleCheckedRow(this.question);
      // }else{
      //   return handleCheckedRow(this.questionAnswer);
      // }
    }
  }
  getQuestionNumbering(numbering: any) {
    return `Q${numbering}<sup
    style="color: red;"
    *ngIf="showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
    >*</sup
  >`;
  }

  getQuestionNumberingWithoutAsterick(numbering: any) {
    return `Q${numbering}`;
  }
  checkValidKey(event: any) {
    // debugger;
    if (
      (!event.target.value && event.key == 0) ||
      event["key"] == "-" ||
      event["key"] == "+" ||
      event["key"] == "=" ||
      (event.keyCode >= 65 && event.keyCode <= 90)
    ) {
      event.preventDefault();
    }
  }

  onOkClick(){
    this.assessmentDataService.focusAnswerOkClicked$.next(true);
  }

  displayLogic: any = [];
  displayLogicUpdated(event: any) {
    this.displayLogic = event;
  }

  displayLogicClicked: any = false;
  displayLogicClickedEvent(event: any) {
    this.displayLogicClicked = event;
  }
}
