import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from "@angular/core";
import { FormGroup, FormArray, Validators } from "@angular/forms";
import { AssessmentDataService } from "../../../../../../../app/core/assessment-data.service";
import { Observable, of } from "rxjs";
@Component({
  selector: "app-perform-actions-child",
  templateUrl: "./app-perform-actions-child.component.html",
  styleUrls: ["./app-perform-actions-child.component.scss"],
})
export class PerformActionsChildComponent implements OnInit, AfterViewInit {
  @Input() i: any;
  @Input() control: any;
  @Input() allQuestions: any;
  @Input() currentQuestion: any;
  @Input() postQuesSet: any;
  @Input() includeSecQuesSet: any;
  @Input() postSameSecQuesSet: any;
  @Input() postSecSet: any;
  @Input() parentForm: FormGroup;
  showQuestionForBranch: any = true;
  showRedirectSelection = false;
  sectionQues: any = [];
  postQuesSet2: any = [];
  postSecSet$: Observable<any>;
  showTargetOptions: any = {
    display: false,
    skip: false,
    branch: false,
  };

  logicTypeArr: any = [
    { name: "Branch", id: "branching" },
    { name: "Display", id: "display" },
    { name: "Skip", id: "skip" },
  ];
  redirectUrlList: any = [];

  constructor(private assessmentData: AssessmentDataService) {}

  ngOnInit() {
    this.redirectUrlList = this.assessmentData.settings.experience.filter(
      (e: any) => ["experience_redirect_url", "experience_early_termination_url"].includes(e.code) && e.value
    );
    this.redirectUrlList.unshift({
      name:'No URL Redirect',
      value:'none',
      code:'none'
    })
    
    let logicType = this.control.get("logic_type_name").value ? this.control.get("logic_type_name").value : null;
    if (logicType) {
      this.onActionTypeChange({ id: logicType }, true);
    }
    let secId = this.control.get("section_destination").value;
    if (logicType == "branching") {
      this.postSecSet.forEach((sec: any) => {
        if (sec._id == secId) this.onSectionChange(sec);
        else if (this.control.get("question_destination_type").value == "end_of_survey") {
          this.onSectionChange(sec);
        }
      });
    } else if (secId && logicType == "display") {
      this.includeSecQuesSet.forEach((sec: any) => {
        if (sec._id == secId) this.onSectionSelectionForDisplay(sec, true);
      });
    }

    let ques_dest_type = this.control.get("question_destination_type").value;
    if (ques_dest_type == "end_of_survey") {
      if (logicType == "branching") this.control.get("section_destination").patchValue(ques_dest_type);
      else if (logicType == "skip") this.control.get("question_destination").patchValue(ques_dest_type);
      this.control.get("question_destination_type").patchValue(null);
    }
    if (ques_dest_type == "end_of_block") {
      this.control.get("question_destination").patchValue(ques_dest_type);
      this.control.get("question_destination_type").patchValue(null);
    }
  }

  getArrayControl() {
    return (this.parentForm.get("conditions") as FormArray).controls;
  }

  // user changes choosen question -> show the options dropdown accordingly
  onQuestionChange(i: any) {
    const control = <FormArray>this.parentForm.controls["conditions"];
    const ithControl = control.controls[i];
    let quesId = this.parentForm.value["conditions"][i].question_id_from_locator;
    let ques = this.postQuesSet2.filter((elem: any) => {
      if (elem._id == quesId) {
        return true;
      }
    });

    let t = ques[0]._type;
  }

  // on redirect URL change we need to update redirect_url key for all conditions
  onRedirectUrlChange(i: any) {
    (this.parentForm.get("conditions") as FormArray).controls.forEach((c) => {
      c.get("redirect_url")?.patchValue(i.code || null);
    });
  }

  onActionTypeChange(event: any, isInit?: boolean) {
    this.showRedirectSelection = false;
    if(!isInit)
    this.control.get("redirect_url").patchValue(null);
    if (event.id == "display") {
      this.showTargetOptions = {
        display: true,
        skip: false,
        branch: false,
      };
      // put validation on runtime when action type changes
      if(!this.isRaterGroup){
        this.control.get("section_destination").setValidators([Validators.required]);
        this.control.get("question_destination").setValidators([Validators.required]);
      }
    } else if (event.id == "skip") {
      this.showTargetOptions = {
        display: false,
        skip: true,
        branch: false,
      };
      // put validation on runtime when action type changes
      if(!this.isRaterGroup){
        this.control.get("section_destination").clearValidators();
        this.control.get("question_destination").setValidators([Validators.required]);
      }
    } else if (event.id == "branching") {
      this.showTargetOptions = {
        display: false,
        skip: false,
        branch: true,
      };
      // put validation on runtime when action type changes
      if(!this.isRaterGroup){
        this.control.get("section_destination").setValidators([Validators.required]);
        this.control.get("question_destination").clearValidators();
      }

    }

    if (!isInit) {
      this.control.get("section_destination").patchValue(null);
      this.control.get("question_destination").patchValue(null);
    }
  }

  onSectionChange(event: any, isChange?: any) {
    if (event._id == "end_of_survey") {
      this.showRedirectSelection = true;
      this.showQuestionForBranch = false;
      if(isChange)
      this.control.get("redirect_url").patchValue('none');
    } else {
      this.showRedirectSelection = false;
      this.showQuestionForBranch = true;
      if(isChange)
      this.control.get("redirect_url").patchValue(null);
    }
    this.sectionQues = [];
    if (!event.questions) {
      this.sectionQues = [];
    } else {
      event.questions.forEach((ques: any) => {
        if (ques.obj_type == "question") {
          this.sectionQues.push(ques);
        }
      });
    }

    if (isChange) this.control.get("question_destination").patchValue(null);
    if (event._id == "end_of_survey") {
      // this.control.get("section_destination").patchValue(null);
      this.control.get("question_destination_type").patchValue(event._id);
    }

    if (event._id == "end_of_block") {
      // this.control.get("section_destination").patchValue(null);
      this.control.get("question_destination_type").patchValue(event._id);
    }
  }

  extractContent(s: any) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  onSectionSelectionForDisplay(event: any, isEdit?: boolean) {
    if (!isEdit) this.control.get("question_destination").patchValue(null);
    this.postSecSet$ = of([]);
    this.postQuesSet2 = [];
    let cQuesId = this.currentQuestion._id;
    let sArr = this.currentQuestion.section.split("/");
    let cSecId = +sArr[sArr.length - 2];
    let qFound: boolean = false;
    let eArr = event.url.split("/");
    let eId = +eArr[eArr.length - 2];
    if (eId == cSecId) {
      event.questions.forEach((ques: any) => {
        if (ques.obj_type == "pagebrake") {
          return;
        }
        if (ques._id == cQuesId) {
          qFound = true;
        } else if (qFound) {
          if (ques.obj_type == "pagebrake") {
            return;
          }
          if (ques["_type"] == 8) {
            ques["name"] = this.extractContent(ques.text);
          } else {
            ques["name"] = "Q" + ques.numbering + " " + this.extractContent(ques.text);
          }

          let qArr = ques.url.split("/");
          let id = +qArr[qArr.length - 2];
          ques["_id"] = id;
          this.postQuesSet2.push(ques);
        }
      });
    } else {
      event.questions.forEach((ques: any) => {
        if (ques.obj_type == "pagebrake") {
          return;
        }
        if (ques["_type"] == 8) {
          ques["name"] = this.extractContent(ques.text);
        } else {
          ques["name"] = "Q" + ques.numbering + " " + this.extractContent(ques.text);
        }

        let qArr = ques.url.split("/");
        let id = +qArr[qArr.length - 2];
        ques["_id"] = id;
        this.postQuesSet2.push(ques);
      });
    }
    this.postSecSet$ = of(this.postQuesSet2);
  }

  onQuesDestChang(event: any, isChange?: any) {
    this.sectionQues = event.questions ? event.questions : [];
    if (isChange) this.control.get("section_destination").patchValue(null);
    if (event._id == "end_of_survey") {
      // this.control.get("section_destination").patchValue(null);
      this.control.get("question_destination_type").patchValue(event._id);
      this.showRedirectSelection = true;
      if(isChange)
        this.control.get("redirect_url").patchValue('none');
    }else{
      this.showRedirectSelection = false;
      if(isChange)
        this.control.get("redirect_url").patchValue(null);
    }

    if (event._id == "end_of_block") {
      // this.control.get("section_destination").patchValue(null);
      this.control.get("question_destination_type").patchValue(event._id);
    }
  }

  ngAfterViewInit(): void {
    //question_destination
    //section_destination
    let logicType = this.control.get("logic_type_name").value;
    if (logicType && logicType == "display") {
      // section dropdown
      let secDes = this.control.get("section_destination").value;
      let flag = false;
      if (secDes) {
        if (this.includeSecQuesSet && this.includeSecQuesSet.length) {
          this.includeSecQuesSet.forEach((sec: any) => {
            if (sec._id == secDes) {
              flag = true;
            }
          });
        }
      }
      if (!flag) {
        this.control.get("section_destination").patchValue(null);
      }
      // question dropdown
      // this.postQuesSet2
      let quesDes = this.control.get("question_destination").value;
      let quesFlag = false;
      if (quesDes) {
        if (this.postQuesSet2 && this.postQuesSet2.length) {
          this.postQuesSet2.forEach((que: any) => {
            if (que._id == quesDes) {
              quesFlag = true;
            }
          });
        }
      }
      if (!quesFlag) {
        this.control.get("question_destination").patchValue(null);
      }
    }

    if (logicType && logicType == "skip") {
      // postSameSecQuesSet
      // question_destination
      let quesDes = this.control.get("question_destination").value;
      let quesSkipFlag = false;
      if (quesDes) {
        if (this.postSameSecQuesSet && this.postSameSecQuesSet.length) {
          this.postSameSecQuesSet.forEach((que: any) => {
            if (que._id == quesDes) {
              this.onQuesDestChang(que);
              quesSkipFlag = true;
            }
          });
        }
      }
      if (!quesSkipFlag) {
        this.control.get("question_destination").patchValue(null);
      }
    }

    if (logicType && logicType == "branching") {
      // postSecSet
      // section_destination
      let secDes = this.control.get("section_destination").value;
      let secBranFlag = false;
      if (secDes) {
        if (this.postSecSet && this.postSecSet.length) {
          this.postSecSet.forEach((que: any) => {
            if (que._id == secDes) {
              secBranFlag = true;
            }
          });
        }
      }
      if (!secBranFlag) {
        this.control.get("section_destination").patchValue(null);
      }

      //sectionQues
      //question_destination

      let quesBranDes = this.control.get("question_destination").value;
      let quesBranFlag = false;
      if (quesBranDes) {
        if (this.sectionQues && this.sectionQues.length) {
          this.sectionQues.forEach((que: any) => {
            if (que._id == quesBranDes) {
              quesBranFlag = true;
            }
          });
        }
      }
      if (!quesBranFlag) {
        this.control.get("question_destination").patchValue(null);
      }
    }
  }

  selectedThisQuestion = 0
  get isRaterGroup(){
    return this.parentForm && this.parentForm?.get('group_type')?.value == 'rater-group'
  }
}
