<div
  id="{{numbering}}"
  class="question-container print"
  [ngClass]="{ selected: question.uiSelected && !previewMode, 'non-editable': (editable && question.answered && backClicked),'previewMode' : previewMode ? true : false,'wth-out-qnum' : !(showQuestionNumber && previewMode) && previewMode }"
  [ngStyle]="{  'paddingTop':   previewMode ? customThemeSettings['content_padding_from_top'] ? customThemeSettings['content_padding_from_top'] +'px' :checkForZero(customThemeSettings['content_padding_from_top'],'0px',''): '' ,
  'paddingBottom': previewMode ? customThemeSettings['content_padding_from_bottom'] ? customThemeSettings['content_padding_from_bottom'] +'px' : checkForZero(customThemeSettings['content_padding_from_bottom'],'0px',''):'',
  'paddingLeft':  previewMode? customThemeSettings['content_padding_from_left'] ? customThemeSettings['content_padding_from_left'] +'px' :checkForZero(customThemeSettings['content_padding_from_left'],'0px','') : '',
  'paddingRight':  previewMode ? customThemeSettings['content_padding_from_right'] ? customThemeSettings['content_padding_from_right'] +'px' :checkForZero(customThemeSettings['content_padding_from_right'],'0px',''): ''  ,
  'border' : from ? 'none' :'' }"
>
  <div
    class="error_container"
    *ngIf="customQuestionError.length > 0"
    style="text-align: center; color: red;"
  >
    <p style="color: red;">{{ customQuestionError }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="invalidRankErrorMessage.length > 0"
    style="text-align: center; color: red;"
  >
    <p style="color: red;">{{ invalidRankErrorMessage }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'required'"
    style="text-align: center; white-space: pre;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'requested'"
    style="text-align: center; white-space: pre;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>

  <div class="pl-4 ms-1">
    <ng-container *ngIf="!previewMode">
      <ng-container>
        <ng-container>
          <app-display-logic-view
            [currentQuestion]="question"
            [allQuestions]="allQuestions"
            [surveyData]="surveyData"
            (emitDisplayLogic)="displayLogicUpdated($event)"
          ></app-display-logic-view>
        </ng-container>
        </ng-container>
      </ng-container>
  </div>

  <!-- <div *ngIf="previewMode && !question.answered && (question.option_type == 1 || question.option_type == 2)" class="quest" style="margin-bottom: 1rem;">
    <h3></h3>
    <h6 style="font-size: 13px; color: #6d7379;">Note: If the given order is your preference, please change and revert the position of a single response option to continue.</h6>
  </div> -->

  <div class="quest" [ngStyle]="{'display':from ? 'flex':''}">
    <h3
      *ngIf="!from  && showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [ngStyle]="{
      'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
      fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumbering(numbering)"
    ></h3>
    <h3
      *ngIf="!from   && !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [ngStyle]="{
      'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
      fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
    ></h3>

    <h6
      *ngIf="from  && showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngStyle]="{'margin-right':'1%','margin-top':'0.11%',
    'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [innerHtml]="getQuestionNumbering(numbering)"
    ></h6>

    <h6
      *ngIf="from  && !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngStyle]="{'margin-right':'1%','margin-top':'0.11%',
  'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
  fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
    ></h6>
    <h6
      [ngStyle]="{color:previewMode && customThemeSettings &&customThemeSettings['question_font_color'] ? customThemeSettings['question_font_color'] :previewMode && customThemeSettings?'#a5a5a5':'',
    paddingBottom :  previewMode && customThemeSettings && customThemeSettings['padding_btw_question_n_options']  ? customThemeSettings['padding_btw_question_n_options']+ 'px' :'',
   
   
    'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',

    fontFamily :  previewMode && customThemeSettings && customThemeSettings['question_font_family'] ? customThemeSettings['question_font_family'] :'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :'',
    width:!question.question_tooltip?'100%':''}"
      [innerHtml]="question.text | safeHtml"
    ></h6>
    <i
      (click)="tooltipClick($event)"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{marginTop: '0.2%'}"
      *ngIf="!mobileView && question.question_tooltip"
      placement="auto"
      [ngbTooltip]="UploadQuestiontipContent"
    ></i>
    <ng-template #UploadQuestiontipContent>
      <div [innerHTML]="question.question_tooltip | safeHtml "></div>
    </ng-template>
    <i
      (click)="openModal($event,tooltipModalQuestion);$event.stopPropagation();"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{marginTop: '0.2%'}"
      *ngIf="this.mobileView && question.question_tooltip"
    ></i>
  </div>
  <!-- <ng-container *ngIf="!previewMode">
    <ul
      class="nav nav-tabs"
      id="myTab"
      role="tablist"
      *ngIf="displayLogic.length"
    >
      <li
        class="nav-item"
        *ngIf="displayLogic.length"
        (click)="displayLogicClickedEvent(true)"
      >
        <a
          class="nav-link"
          id="home-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          ><i class="fas fa-question mr-1"></i> Response Logic</a
        >
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show"
        [ngClass]="{'active': displayLogicClicked }"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <app-display-logic-view
          [currentQuestion]="question"
          [allQuestions]="allQuestions"
          [surveyData]="surveyData"
          (emitDisplayLogic)="displayLogicUpdated($event)"
        ></app-display-logic-view>
      </div>
    </div>
  </ng-container> -->
  <ng-template #tooltipModalQuestion let-modal>
    <a (click)="modal.close()" href="javascript:void(0)" class="close-modal"
      ><i class="fa fa-times"></i
    ></a>
    <div [innerHTML]="question.question_tooltip | safeHtml "></div>
  </ng-template>

  <ng-container [ngSwitch]="question.option_type">
    <div *ngSwitchCase="1" class="list-item">
      <div *ngIf="previewMode" class="form-group">
        <ul
        cdkDropList
        (cdkDropListDropped)="drop($event)"
          class="list-input-styled"
          
        >
        <!-- [sortablejs]="question.question_options"
          [sortablejsOptions]="sortableJSOptions" -->
          <li
            id="{{ i }}"
            [ngStyle]="{
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
            paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '13px',
            paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '13px'}"
            cdkDrag
            class="rank_order_preview"
            *ngFor="let option of question.question_options; let i = index"
            [cdkDragData]="option"
          >
            <span
              class="rank_order_position d-flex align-items-center justify-content-center"
              [ngStyle]="{'backgroundColor' : previewMode && this.customThemeSettings['radio_button_color_change'] ? this.customThemeSettings['radio_button_color_change'] :'' , 
              'borderColor' : previewMode && this.customThemeSettings['radio_button_color_change'] ?  this.customThemeSettings['radio_button_color_change'] :'',
              minWidth: previewMode ? customThemeSettings['option_container_height'] ? customThemeSettings['option_container_height']*1.25 + 'px' :checkForZero(customThemeSettings['option_container_height'],'0px','16px') :'',
              minHeight: previewMode ? customThemeSettings['option_container_height'] ? customThemeSettings['option_container_height']*1.25 + 'px' :checkForZero(customThemeSettings['option_container_height'],'0px','16px') :'',
              lineHeight: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']*1.01+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
              fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :''
              }"
              ><span>{{ i + 1 }}</span></span
            ><span
              [ngStyle]="{color: previewMode && customThemeSettings &&  customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
            fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
          fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
          fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
          [innerHtml]="option.text | safeHtml"
              >
              <!-- {{ option.text }} -->
              </span
            >
          </li>
        </ul>
      </div>
      <div *ngIf="!previewMode" class="form-group">
        <ul class="list-input-styled">
          <li
            id="{{ i }}"
            [ngStyle]="{
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
            paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '13px',
            paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '13px'}"
            *ngFor="let option of question.question_options; let i = index"
          >
            <span
              [ngStyle]="{color: previewMode && customThemeSettings &&  customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '' ,
            fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
          fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
          fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
          [innerHtml]="option.text | safeHtml"
              >
              <!-- {{ option.text }} -->
              </span
            >
          </li>
        </ul>
      </div>
    </div>
    <div *ngSwitchCase="2" class="list-item">
      <div class="form-group">
        <ul class="list-input-styled">
          <li
            [ngStyle]="{
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
            paddingTop : previewMode &&  customThemeSettings['option_container_height'] ? customThemeSettings['option_container_height']/2 + 'px' : '13px',
            paddingBottom : previewMode ? customThemeSettings['option_container_height']/2 + 'px' : '13px'}"
            *ngFor="let option of question.question_options; let i = index"
          >
            <span
              [ngStyle]="{color: previewMode && customThemeSettings &&  customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '' ,
            fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
          fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
          fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
          [innerHtml]="option.text | safeHtml"
              >
              <!-- {{ option.text }} -->
              </span
            >
            <div *ngIf="previewMode" class="up-and-down">
              <button (click)="up(option)">
                <i class="fas fa-caret-up"></i>
              </button>
              <button (click)="down(option)">
                <i class="fas fa-caret-down"></i>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div *ngSwitchCase="3">
      <div class="form-group">
        <div class="list-item">
          <div
            class="item"
            *ngFor="let option of question.question_options; let i = index"
          >
            <div class="form-inline rank-order-list">
              <div class="form-group row">
                <label
                  class="form-control-label col-md-4"
                  [ngStyle]="{color: previewMode && customThemeSettings &&  customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
              [innerHtml]="option.text | safeHtml"
                  >
                  <!-- {{ option.text }} -->
                  </label
                >
                <input
                  [ngStyle]="{
                    paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '',
                    paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : ''}"
                  id="answer_{{question.id}}_{{ i }}"
                  (keydown)="checkValidKey($event)"
                  (keyup)="onRankChange($event, option, i)"
                  (focusout)="focusOut($event, option)"
                  type="number"
                  class="form-control col-md-8"
                  oninput="javascript: if (this.value > this.max || this.value.length > this.max.toString().length || this.value < this.min) this.value = null;"
                  min="1"
                  [max]="question.question_options.length"
                  [value]="getRankValue(option)? getRankValue(option) : null"
                  name=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="4" class="list-item">
      <div class="form-group">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
                background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
            paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}" scope="col"></th>
                <th
                  style="padding-left: 14px;"
                  *ngFor="let col of question.question_options; let i = index"
                  [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
                background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
            paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                >
                  {{ i + 1 }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let row of question.question_options; let i = index"
                scope="row"
              >
                <td 
                [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
                background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
            paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                scope="row" [innerHtml]="row.text | safeHtml">
                  <!-- {{ row.text }} -->
                </td>
                <td
                [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                'fontSize': previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size'] +'px' : checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
                background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
            paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                  *ngFor="let col of question.question_options; let colIndex = index"
                >
                  <input
                    (change)="radioOptionChecked($event, row, i, colIndex + 1)"
                    [checked]="isChecked(row, colIndex + 1)"
                    type="radio"
                    class="fsop-radio"
                    name="answer_{{question.id}}_{{ i }}"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="previewMode && okButtonShow && customThemeSettings['theme_layout'] == 'focus'">
    <button
    [id]="'okbt'+question.numbering"
            (click)="onOkClick()"
            class="btn btn-md"
            style="display: inline; margin-left: 45px; color: white; background: #54902b;"
          >OK
          </button>
  </div>
</div>
