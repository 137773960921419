import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "./authentication/authentication.service";
import { AssessmentDataService } from "./assessment-data.service";
import { map } from "lodash";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";

@Injectable()
export class CompaignDashboardGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem("is_superadmin") === "false") {
      return true;
    } else {
      if (this.authenticationService.isAuthenticated()) {
        if (localStorage.getItem("is_superadmin") === "true") {
          this.router.navigate(["/system-dashboard"]);
        }
      }
    }
    return false;
  }
}

@Injectable()
export class SAGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticationService.isAuthenticated()) {
      if (localStorage.getItem("is_superadmin") === "false") {
        this.router.navigate(["/campaign-dashboard"]);
      } else if (localStorage.getItem("is_superadmin") === "true") {
        return true;
      }
    }

    return false;
  }
}

@Injectable()
export class CAGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticationService.isAuthenticated()) {
      if (localStorage.getItem("is_superadmin") === "false") {
        return true;
      } else if (localStorage.getItem("is_superadmin") === "true") {
        this.router.navigate(["/system-dashboard"]);
      }
    }

    return false;
  }
}

@Injectable()
export class CheckIfSurveyLaunchedGuard implements CanActivate {
  constructor(private router: Router, private assessmentDataService: AssessmentDataService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.assessmentDataService.checkIfSurveyLaunched(route.params["id"]);
  }
}
