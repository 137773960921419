<label *ngIf="control" class="mb-0 mt-3" for="">Perform this action:</label>
<div
*ngIf="control"
  class="form-group flex-item d-flex align-items-start"
  [formGroup]="control"
>
  <div class="item">
    <div class="form-group">
      <div class="group-input">
        <ng-select
          [markFirst]="false"
          [items]="logicTypeArr"
          bindLabel="name"
          dropdownPosition="auto"
          bindLabel="name"
          bindValue="id"
          placeholder="Select Action"
          [clearable]="false"
          [closeOnSelect]="true"
          formControlName="logic_type_name"
          appendTo="body"
          (change)="onActionTypeChange($event)"
        >
        <ng-template ng-label-tmp let-item="item">
          <span [innerHtml]="item.name"></span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <span [innerHtml]="item.name"></span>
    </ng-template>
        </ng-select>
      </div>
      <span 
        *ngIf="control?.controls && control.controls['logic_type_name'].invalid && (control.controls['logic_type_name'].dirty || control.controls['logic_type_name'].touched)" 
        class="logic-error">This field is required</span>
    </div>
  </div>
  <!-- <span *ngIf="showTargetOptions.display">For</span> -->
  <span class="d-inline-block" style="margin-top: 20px" *ngIf="!isRaterGroup && (showTargetOptions.skip || showTargetOptions.branch)">to</span>
  <!-- Selected Question is MCQ Type -->
  <ng-container *ngIf="showTargetOptions.display && !isRaterGroup">
    <div class="item">
      <div class="form-group">
        <div class="group-input">
          <ng-select
            [markFirst]="false"
            [items]="includeSecQuesSet"
            bindLabel="name"
            dropdownPosition="auto"
            bindLabel="name"
            bindValue="_id"
            placeholder="Select Section"
            [clearable]="false"
            [closeOnSelect]="true"
            formControlName="section_destination"
            appendTo="body"
            (change)="onSectionSelectionForDisplay($event)"
          >
          <ng-template ng-label-tmp let-item="item">
            <span [innerHtml]="item.name"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span [innerHtml]="item.name"></span>
      </ng-template>
          </ng-select>
        </div>
        <span 
        *ngIf="control?.controls && control.controls['section_destination'].invalid && (control.controls['section_destination'].dirty || control.controls['section_destination'].touched)" 
        class="logic-error">This field is required</span>
      </div>
    </div>
    <div class="item">
      <div class="form-group">
        <div class="group-input">
          <ng-select
            [markFirst]="false"
            [items]="postSecSet$ | async"
            bindLabel="name"
            dropdownPosition="auto"
            bindValue="_id"
            placeholder="Select Question"
            [clearable]="false"
            [closeOnSelect]="true"
            formControlName="question_destination"
          >
          <ng-template ng-label-tmp let-item="item">
            <span [innerHtml]="item.name"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span [innerHtml]="item.name"></span>
      </ng-template>
          </ng-select>
        </div>
        <span 
        *ngIf="control?.controls && control.controls['question_destination'].invalid && (control.controls['question_destination'].dirty || control.controls['question_destination'].touched)" 
        class="logic-error">This field is required</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showTargetOptions.skip">
    <div class="item">
      <div class="form-group">
        <div class="group-input">
          <ng-select
            [markFirst]="false"
            [items]="postSameSecQuesSet"
            bindLabel="name"
            dropdownPosition="auto"
            bindLabel="name"
            bindValue="_id"
            placeholder="Select Option"
            [clearable]="false"
            [closeOnSelect]="true"
            formControlName="question_destination"
            (change)="onQuesDestChang($event,true)"
            appendTo="body"
          >
          <ng-template ng-label-tmp let-item="item">
            <span [innerHtml]="item.name"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span [innerHtml]="item.name"></span>
      </ng-template>
          </ng-select>
        </div>
        <span 
        *ngIf="control?.controls && control.controls['question_destination'].invalid && (control.controls['question_destination'].dirty || control.controls['question_destination'].touched)" 
        class="logic-error">This field is required</span>
      </div>
    </div>
    <div class="item" *ngIf="showRedirectSelection">
      <div class="form-group">
        <div class="group-input">
          <ng-select
            [markFirst]="false"
            [items]="redirectUrlList"
            dropdownPosition="auto"
            bindLabel="name"
            bindValue="code"
            placeholder="Select Redirect URL"
            [clearable]="false"
            [closeOnSelect]="true"
            formControlName="redirect_url"
            appendTo="body"
            (change)="onRedirectUrlChange($event)"
          >
          <ng-template ng-label-tmp let-item="item">
            <span [innerHtml]="item.name"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span [innerHtml]="item.name"></span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <span [innerHtml]="item.name"></span>
    </ng-template>
          </ng-select>
        </div>
        <span 
        *ngIf="control?.controls && control.controls['redirect_url'].invalid && (control.controls['redirect_url'].dirty || control.controls['redirect_url'].touched)" 
        class="logic-error">This field is required</span>
      </div>
    </div>
  </ng-container>
  <div class="item" *ngIf="isRaterGroup">
    <div class="form-group">
      <div class="group-input">
        <ng-select
          [markFirst]="true"
          [(ngModel)]="selectedThisQuestion"
          [ngModelOptions]="{standalone: true}"
          [items]="[{id:0, name:'This question'}]"
          bindLabel="name"
          dropdownPosition="auto"
          bindLabel="name"
          disabled
          bindValue="id"
          placeholder="Select Section"
          [clearable]="false"
          [closeOnSelect]="true"
          appendTo="body"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <ng-container *ngIf="showTargetOptions.branch && !isRaterGroup">
    <div class="item">
      <div class="form-group">
        <div class="group-input">
          <ng-select
            [markFirst]="false"
            [items]="postSecSet"
            bindLabel="name"
            dropdownPosition="auto"
            bindLabel="name"
            bindValue="_id"
            placeholder="Select Section"
            [clearable]="false"
            [closeOnSelect]="true"
            formControlName="section_destination"
            appendTo="body"
            (change)="onSectionChange($event,true)"
          >
          <ng-template ng-label-tmp let-item="item">
            <span [innerHtml]="item.name"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span [innerHtml]="item.name"></span>
      </ng-template>
          </ng-select>
        </div>
        <span 
        *ngIf="control?.controls && control.controls['section_destination'].invalid && (control.controls['section_destination'].dirty || control.controls['section_destination'].touched)" 
        class="logic-error">This field is required</span>
      </div>
    </div>

    <div class="item" *ngIf="showQuestionForBranch">
      <div class="form-group">
        <div class="group-input">
          <ng-select
            [markFirst]="false"
            [items]="sectionQues"
            dropdownPosition="auto"
            bindLabel="name"
            bindValue="_id"
            placeholder="Select Question"
            [clearable]="true"
            [closeOnSelect]="true"
            formControlName="question_destination"
            appendTo="body"
          >
          <ng-template ng-label-tmp let-item="item">
            <span [innerHtml]="item.name"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span [innerHtml]="item.name"></span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <span [innerHtml]="item.name"></span>
    </ng-template>
          </ng-select>
        </div>
        <span 
        *ngIf="control?.controls && control.controls['question_destination'].invalid && (control.controls['question_destination'].dirty || control.controls['question_destination'].touched)" 
        class="logic-error">This field is required</span>
      </div>
    </div>

    <div class="item" *ngIf="showRedirectSelection">
      <div class="form-group">
        <div class="group-input">
          <ng-select
            [markFirst]="false"
            [items]="redirectUrlList"
            dropdownPosition="auto"
            bindLabel="name"
            bindValue="code"
            placeholder="Select Redirect URL"
            [clearable]="false"
            [closeOnSelect]="true"
            formControlName="redirect_url"
            appendTo="body"
            (change)="onRedirectUrlChange($event)"
          >
          <ng-template ng-label-tmp let-item="item">
            <span [innerHtml]="item.name"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span [innerHtml]="item.name"></span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <span [innerHtml]="item.name"></span>
    </ng-template>
          </ng-select>
        </div>
        <span 
        *ngIf="control?.controls && control.controls['redirect_url'].invalid && (control.controls['redirect_url'].dirty || control.controls['redirect_url'].touched)" 
        class="logic-error">This field is required</span>
      </div>
    </div>

  </ng-container>
</div>
