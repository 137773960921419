import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpService } from "./http/http.service";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { IUserProfile } from "../models/user.model";
import { Page } from "../models/data-table-page.model";
import { ISurveySort } from "../models/survey-sort.model";

@Injectable()
export class UserDataService {
  constructor(private httpService: HttpService) {}
  public ipAddress: any = null;
  public userInfo: any;
  public userTimezoneData$ = new BehaviorSubject(null);

  public addAdminUser(useradmin: IUserProfile): Observable<any> {
    return this.httpService.post(environment.BASE_URL + "users/", useradmin, {
      headers: { Staff: "Yes" },
    });
  }

  public getAdminUser(auserId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + "users/" + auserId + "/"
    );
  }

  public me(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + "me/");
  }
  public getCaTimezoneData(userId: any): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + "standard-users/" + userId + "/user_time/"
    );
  }

  public editMyProfile(myProfile: IUserProfile): Observable<any> {
    return this.httpService.put(myProfile.url as string, myProfile);
  }

  public getStandardUserByID(suserId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + "standard-users/" + suserId + "/"
    );
  }

  public checkIfUniqueEmail(email: string): Observable<any> {
    return this.httpService.head(
      environment.BASE_URL + `unique-email/${email}/`
    );
  }

  public getUserPermissions(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + "me/permissions/");
  }

  public isValidEmail(email: string): boolean {
    let regex = /^[-!#$%&'*+\/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+\/=?^_`{}|~0-9A-Z]+)*@(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}|[A-Z0-9-]{2,})$/i;
    return regex.test(String(email).toLowerCase());
  }

  public getAllAdminUsers(
    pageObj?: Page,
    filter?: any,
    sort?: any
  ): Observable<any> {
    let queryStatement = "?";
    if (pageObj) {
      queryStatement += `page=${pageObj.pageNumber}&page_size=${pageObj.size}`;
    }

    if (filter && filter.search) {
      queryStatement += `&search=${filter.search}`;
    }
    if (sort) {
      switch (sort.type) {
        case "asc":
          queryStatement += `&ordering=${sort.ordering}`;
          break;

        case "desc":
          queryStatement += `&ordering=-${sort.ordering}`;
          break;

        default:
          break;
      }
    }

    return this.httpService.get(
      environment.BASE_URL + `users/` + queryStatement
    );
  }

  public getUserByUrl(userUrl: string): Observable<any> {
    return this.httpService.get(userUrl);
  }

  public createStandardUser(standardUser: IUserProfile): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + "standard-users/",
      standardUser
    );
  }
  public getStandardUsers(
    pageObj?: Page,
    filter?: any,
    sort?: ISurveySort
  ): Observable<any> {
    let queryStatement = "?";

    if (pageObj) {
      queryStatement += `page=${pageObj.pageNumber}&page_size=${pageObj.size}`;
    }
    if (filter && filter.search) {
      queryStatement += `&search=${filter.search}`;
    }
    if (sort) {
      switch (sort.type) {
        case "asc":
          queryStatement += `&ordering=${sort.ordering}`;
          break;

        case "desc":
          queryStatement += `&ordering=-${sort.ordering}`;
          break;

        default:
          break;
      }
    }

    return this.httpService.get(
      environment.BASE_URL + `standard-users/` + queryStatement
    );
  }

  public getAllPermissionGroups(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + "permissions/group/");
  }

  public updateUserAccountStatus(
    usersEntities: string[],
    action: string
  ): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `users/${action.toLowerCase()}/`,
      { users: usersEntities }
    );
  }
  public updateUserAccountStatustoInactive(usersEntities: string[]) {
    return this.httpService.post(environment.BASE_URL + `users/opt_out/`, {
      users: usersEntities,
    });
  }
  public _detectRoleType(role: string): string {
    const END_USER_ROLE_TYPE_REGEX = /^(Survey Respondant|parent|instructor)$/i;
    if (END_USER_ROLE_TYPE_REGEX.test(role)) {
      return "end_user";
    }
    return "management_user";
  }

  public hasPermission(permissionExpression: string): boolean {
    const permissionExpressionToArray = permissionExpression.split(" || ");
    const userPermissions = localStorage.getItem("permissions");

    if (userPermissions) {
      const intersection = permissionExpressionToArray.filter((value) =>
        userPermissions.includes(value)
      );
      return intersection.length === permissionExpressionToArray.length;
    }
    return false;
  }

  public deleteUser(userId: string): Observable<any> {
    return this.httpService.delete(
      environment.BASE_URL + "users/" + userId + "/"
    );
  }

  public deleteStandardUser(suserId: string): Observable<any> {
    return this.httpService.delete(
      environment.BASE_URL + "standard-users/" + suserId + "/"
    );
  }

  public editStandardUser(
    suserId: string,
    editedSUser: IUserProfile
  ): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + "standard-users/" + suserId + "/",
      editedSUser
    );
  }

  public editAdminUser(auserId: string, editedAUser: any): Observable<any> {
    if (editedAUser.hasOwnProperty("password")) {
      return this.httpService.patch(
        environment.BASE_URL + "users/" + auserId + "/",
        { password: editedAUser.password } as any,
        { headers: { Management: "True", "X-HTTP-Method-Override": "put" } }
      );
    } else {
      return this.httpService.put(
        environment.BASE_URL + "users/" + auserId + "/",
        editedAUser
      );
    }
  }

  public editStandardUserPassword(
    auserId: string,
    editedAUser: any
  ): any {
    if (editedAUser.hasOwnProperty("password")) {
      return this.httpService.put(
        environment.BASE_URL + "standard-users/" + auserId + "/setpassword/",
        { password: editedAUser.password } as any,
        { headers: { Management: "True", "X-HTTP-Method-Override": "put" } }
      );
    }
  }

  public uploadImage(file: string): Observable<any> {
    return this.httpService.post(environment.BASE_URL + "upload_data/", {
      file,
    });
  }

  public getMyIPAddress(): Observable<any> {
    return this.httpService.get("https://api.ipgeolocation.io/getip");
  }

  public getMyLocationUsingIP(): Observable<any> {
    return this.httpService.get(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${environment.geoLocationApiKey}&ip=${this.ipAddress}`
    );
  }

  public changePassword(passObject: any): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + "me/change-password/",
      passObject
    );
  }

  public emulateStandardUser(user_id: string): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `emulate-standard-user/`,
      { user_id: user_id } as any
    );
  }

  getGroupPermission(): Observable<any> {
    return this.me().pipe(
      map(
        (response) => {
          return response;
        },
        (err: any) => {
          return err;
        }
      )
    );
  }

  backupRequest(body: any, user_id: any) {
    return this.httpService.post(
      environment.BASE_URL + `standard-users/${user_id}/backup_delete_user/`,
      body
    );
  }

  backupRequestsList(backup_type: any, pageObj: Page, status: any) {
    let backup_query = `?request_type=${backup_type}`;
    let queryStatement = backup_query + (status ? `&status=${status}` : "");
    queryStatement += `&page=${pageObj.pageNumber}&page_size=${pageObj.size}`;
    return this.httpService.get(
      environment.BASE_URL + `backup-request/${queryStatement}`
    );
  }

  backupRequestAction(type_of_action: any, user_id: any) {
    return this.httpService.put(
      environment.BASE_URL + `backup-request/${user_id}/${type_of_action}/`,
      {}
    );
  }

  getTimezoneList(pageNumber: any, tzoneSearchTerm: any, nextUrl: any) {
    return this.httpService.get(
      environment.BASE_URL +
        `time-zone-list/?&${
          nextUrl
            ? nextUrl
            : "page=" +
              pageNumber +
              "&page_size=50" +
              (tzoneSearchTerm ? "&search=" + tzoneSearchTerm : "")
        }`
    );
  }
  getUserResponseData(id: any, pageNumber: any, page_size: any, body?: any) {
    return this.httpService.post(
      environment.BASE_URL +
        `surveys/${id}/responses/?page=${pageNumber}&page_size=${page_size}`,
      body
    );
  }
  getUserEssentialData(id: any, forFilter?: boolean) {
    if (forFilter) {
      return this.httpService.get(
        environment.BASE_URL + `surveys/${id}/essentials/?type=questions`
      );
    }
    return this.httpService.get(
      environment.BASE_URL + `surveys/${id}/essentials/`
    );
  }
  deleteSurveyResponses(id: any, responseArray: any) {
    return this.httpService.delete(
      environment.BASE_URL + `surveys/${id}/responses/`,
      { body: { responses: responseArray } } as any
    );
  }
  deactivateUserProfile(id: any) {
    return this.httpService.post(
      environment.BASE_URL + "standard-users/" + id + "/deactivate/",
      {}
    );
  }
  updateUserPolicy(id: any, isAgreed: boolean) {
    return this.httpService.post(
      environment.BASE_URL +
        "standard-users/" +
        id +
        "/opt_in/?opt_in=" +
        isAgreed,
      {}
    );
  }
}
