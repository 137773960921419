<div
  class="info-box mb-2 pink"
  *ngIf="conditionsArr && conditionsArr.length && !isInvalidLogicByDefault"
>
  <div class="toggle data-toggle d-flex align-items-center">
    <div class="t-switch" (click)="ShowBranchBox=!ShowBranchBox">
      <i
        class="fas"
        [ngClass]="ShowBranchBox ? 'fa-angle-down' : 'fa-angle-right'"
      ></i>
    </div>
    <div class="d-flex w-100 align-items-center justify-content-between">
      <div>
        <span>
          <a class="branch-logic-view" title="Branch Logic">Branch Logic</a>
        </span>
        <span>Branch Logic on this section</span>
      </div>
    </div>
  </div>
  <div class="toggle-data" *ngIf="ShowBranchBox">
    <!-- dynamic -->
    <ng-container *ngFor="let condition of conditionsArr">
      <ng-container *ngIf="!condition.invalid">
        <span class="d-inline-block me-2">{{condition.conjuction}}</span>
        <span class="d-inline-block me-2" *ngIf="!isInvalid()">By</span>
        <span class="d-inline-block me-2">{{condition.question_choosen}}</span>
        <span class="d-inline-block me-2" *ngIf="!isInvalid()">If</span>
        <span class="d-inline-block me-2">{{condition.option_choosen}}</span>
        <span class="d-inline-block me-2" *ngIf="!condition.option_choosen"
          >value
        </span>
        <span class="d-inline-block me-2">{{condition.operator}}</span>
        <span class="d-inline-block me-2">{{condition.operator_value}}</span>
      </ng-container>

      <ng-container *ngIf="condition.invalid">
        <small class="text-danger-pastel d-inline-block me-2">Invalid Logic</small>
      </ng-container>
    </ng-container>
  </div>
</div>
