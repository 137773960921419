import { BrowserModule } from "@angular/platform-browser";
import { NgModule, enableProdMode } from "@angular/core";
// import { FormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateModule } from "@ngx-translate/core";
import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../environments/environment";
import { CoreModule, HttpService } from "../app/core";
import { SharedModule } from "../app/shared";
// import { HomeModule } from './home/home.module';
// import { ShellModule } from './shell/shell.module';
// import { LoginModule } from './login/login.module';
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
// import { EditorModule } from '@tinymce/tinymce-angular';
// import { SurveyBuilderComponent } from './survey-builder/survey-builder.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// //import { SortablejsModule } from 'ngx-sortablejs';
// import { ShareButton } from '@ngx-share/button';
// import { SurveyPreviewComponent } from './survey-preview/survey-preview.component';
//import { ClickOutsideModule } from 'ng-click-outside';

import { SurveyPreviewModule } from "./modules/survey-preview/survey-preview.module";

import { AuthenticationService } from "./core/authentication/authentication.service";
import { CheckIfSurveyLaunchedGuard } from "./core/compaign-dashboard.guard";
import { SuperadminService } from "./core/authentication/superadmin.service";
import { FileUploaderService } from "./core/file-upload.service";
import "../../node_modules/bootstrap/dist/js/bootstrap.min.js"
import "../../node_modules/@popperjs/core/dist/umd/popper.min.js"

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register("./ngsw-worker.js", {
      enabled: environment.production,
    }),
    // FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    SharedModule,
    // SortablejsModule.forRoot({
    //   animation: 200,
    // }),
    // ShellModule,
    // EditorModule,

    // HomeModule,
    // LoginModule,
    BrowserAnimationsModule,
    SurveyPreviewModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [
    AppComponent,
    // SurveyBuilderComponent,
    // SurveyPreviewComponent
  ],
  providers: [
    NgbActiveModal,
    HttpService,
    AuthenticationService,
    CheckIfSurveyLaunchedGuard,
    SuperadminService,
    FileUploaderService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
// enableProdMode();
