import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { RouteReusableStrategy } from "./route-reusable-strategy";
// import { AuthenticationService } from './authentication/authentication.service';
import {
  AuthenticationGuard,
  LoggedInGuard,
} from "./authentication/authentication.guard";
import { UserDataService } from "./user-data";
import { I18nService } from "./i18n.service";
import { HttpService } from "./http/http.service";
import { HttpCacheService } from "./http/http-cache.service";
import { ApiPrefixInterceptor } from "./http/api-prefix.interceptor";
import { ErrorHandlerInterceptor } from "./http/error-handler.interceptor";
import { CacheInterceptor } from "./http/cache.interceptor";
import { BasicAuthInterceptor } from "./http/basic-auth-interceptor.service";
import { KeysPipe } from "./pipes/keys.pipe";
// import { HeaderDataService } from './header-data.service';
import { ZeroPipe } from "./pipes/zero.pipe";
import { StringToIntegerPipe } from "./pipes/string-to-integer.pipe";
import { NullPipe } from "./pipes/null.pipe";
import {
  // HtmlToPlainTextPipe,
  SafeHtmlPipe,
} from "./pipes/html-to-plain-text";
import { SingleUserDetailResolve } from "./resolves/user.resolve";
import { DragDropAnimationDirective } from "./directives/drag_drop_animation.directive";
import { CapitilizePipe } from "./pipes/capitilize.pipe";
import { OnlyHexDirective } from "./directives/hexadecimal.directive";
import { RangeDirective } from "./directives/range.directive";
import { OnlyNumberDirective } from "./directives/only-number.directive";
import { FormatTimePipe, FormatDatePipe } from "./pipes/format-time.pipe";
import { RemoveHtmlTagsPipe } from "./pipes/remove-html-tags.pipe";
import { SpeicalCharacterDirective } from "./directives/alpha-numeric.directive";

@NgModule({
  imports: [CommonModule, HttpClientModule, TranslateModule, RouterModule],
  providers: [
    // AuthenticationService,
    AuthenticationGuard,
    LoggedInGuard,
    UserDataService,
    I18nService,
    KeysPipe,
    ZeroPipe,
    NullPipe,
    StringToIntegerPipe,
    HttpCacheService,
    // HeaderDataService,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    CacheInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    {
      provide: HttpClient,
      useClass: HttpService,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    SingleUserDetailResolve,
  ],
  declarations: [
    CapitilizePipe,
    KeysPipe,
    ZeroPipe,
    StringToIntegerPipe,
    NullPipe,
    SafeHtmlPipe,
    RemoveHtmlTagsPipe,
    DragDropAnimationDirective,
    OnlyHexDirective,
    OnlyNumberDirective,
    SpeicalCharacterDirective,
    RangeDirective,
    FormatDatePipe,
    FormatTimePipe,
  ],
  exports: [
    CapitilizePipe,
    KeysPipe,
    ZeroPipe,
    StringToIntegerPipe,
    RemoveHtmlTagsPipe,
    NullPipe,
    SafeHtmlPipe,
    DragDropAnimationDirective,
    OnlyHexDirective,
    OnlyNumberDirective,
    SpeicalCharacterDirective,
    RangeDirective,
    FormatDatePipe,
    FormatTimePipe,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    // if (parentModule) {
    //   throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    // }
  }
}
