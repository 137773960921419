<div class="flex-item">
  <label *ngIf="getArrayControl().length==1" for=""
    >If the following condition is met:</label
  >
  <label *ngIf="getArrayControl().length > 1" for=""
    >If the following conditions are met:</label
  >
</div>
<div
  *ngIf="getArrayControl().length"
  class="form-group flex-item box-item rounded border p-3 mb-3"
  [formGroup]="control"
  style="border: 1px solid black;"
>
  <ng-container formArrayName="conditions">
    <ng-container
      *ngFor="
let control2 of getArrayControl();
let i = index
"
    >
      <app-common-logic-row-child
        [i]="i"
        [control]="control2"
        [currentQuestion]="currentQuestion"
        [allQuestions]="allQuestions"
        [parentForm]="control"
        [postQuesSet]="postQuesSet"
        [postSameSecQuesSet]="postSameSecQuesSet"
        [postSecSet]="postSecSet"
        (addRow)="addRow()"
        (removeRow)="removeRow($event)"
      >
      </app-common-logic-row-child>
    </ng-container>

    <app-perform-actions-child
      [control]="getFirstConditionControl()"
      [currentQuestion]="currentQuestion"
      [allQuestions]="allQuestions"
      [parentForm]="control"
      [postQuesSet]="postQuesSet"
      [postSameSecQuesSet]="postSameSecQuesSet"
      [postSecSet]="postSecSet"
      [includeSecQuesSet]="includeSecQuesSet"
      (addRow)="addRow()"
      (removeRow)="removeRow($event)"
    >
    </app-perform-actions-child>
    <!-- <span
      class="remove-box fa fa-times"
      *ngIf="totalParentRows.length > 1"
      (click)="removeLogic(i)"
    ></span> -->
  </ng-container>
</div>
