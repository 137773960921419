import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { QuestionsDirectivesModule } from "./questions-directives/questions-directives.module";
import { QuestionSectionComponent } from "./question-section.component";
// import { SortablejsModule } from "ngx-sortablejs";
import { SharedModule } from "../shared.module";
import { ClickOutsideModule } from "ng-click-outside";
import { ToastrModule } from "ngx-toastr";
import { DisplayLogicComponent } from "./questions-directives/logic-work/display-logic/app-display-logic.component";
import { DisplayLogicRowComponent } from "./questions-directives/logic-work/display-logic/display-logic-row/app-display-logic-row.component";
import { SkipLogicComponent } from "./questions-directives/logic-work/skip-logic/app-skip-logic.component";
import { SkipLogicRowComponent } from "./questions-directives/logic-work/skip-logic/skip-logic-row/app-skip-logic-row.component";
import { BranchLogicComponent } from "./questions-directives/logic-work/branch-logic/app-branch-logic.component";
import { BranchLogicRowComponent } from "./questions-directives/logic-work/branch-logic/branch-logic-row/app-branch-logic-row.component";
import { CommonLogicComponent } from "./questions-directives/logic-work/common-logic/app-common-logic.component";
import { CommonLogicRowComponent } from "./questions-directives/logic-work/common-logic/common-logic-row/app-common-logic-row.component";
import { CdkDrag, CdkDropList, DragDropModule } from "@angular/cdk/drag-drop";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [QuestionSectionComponent],
  exports: [
    QuestionSectionComponent,
    DisplayLogicComponent,
    DisplayLogicRowComponent,
    CommonLogicComponent,
    CommonLogicRowComponent,
    SkipLogicComponent,
    SkipLogicRowComponent,
    BranchLogicComponent,
    BranchLogicRowComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ClickOutsideModule,
    QuestionsDirectivesModule,
    DragDropModule,
    CdkDrag, 
    CdkDropList,
    //SortablejsModule.forRoot({
//      animation: 200,
//    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-center",
    }),
    FontAwesomeModule
  ],
  providers: [],
})
export class QuestionsSectionModule {}
