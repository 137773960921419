import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  @Input() title:string = "Alert";
  @Input() description:string = "Description";
  @Input() confirmButtonText:string = "Confirm";
  @Input() cancelButtonText:string = "Cancel";


  modal = inject(NgbActiveModal);
}
