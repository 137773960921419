// import { Pipe, PipeTransform } from "@angular/core";

// @Pipe({
//   name: "htmlToPlainText"
// })
// export class HtmlToPlainTextPipe implements PipeTransform {
//   transform(text: any) {
//     let str = text? String(text).replace(/<[^>]+>/gm, "") : "";

//     // str.replace(/&nbsp;/g, '  ');
//     // str.replace(/&quot;/g, '"');
//     // str.replace(/&amp;/g, '&');
//     // str.replace(/&lt;/g, '<');
//     // str.replace(/&gt;/g, '>');
//     return str;
//   }
// }

import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: any) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}