import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class BasicAuthInterceptor implements HttpInterceptor {

  constructor(
              private router: Router){

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    const currentUser = JSON.parse(localStorage.getItem('credentials') as string);

    if (currentUser && currentUser.token) {
        request = request.clone({
            setHeaders: {
              Authorization: `Token ${currentUser.token}`,
            }
        });
    }else{
      if (!this.router.url.includes('forgot-password') 
        && !this.router.url.includes('resend-password-instruction')
        && !this.router.url.includes('set-new-password')
        && !this.router.url.includes('/resp')) {
        this.router.navigate(['/login'], { replaceUrl: true })
      }
    }

    return next.handle(request);
}
}
