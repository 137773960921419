<div
  id="{{numbering}}"
  class="question-container print"
  [ngClass]="{ selected: question.uiSelected && !previewMode, 'non-editable': (editable && question.answered && backClicked),'previewMode' : previewMode ? true : false, 'wth-out-qnum' : !(showQuestionNumber && previewMode) && previewMode }"
  [ngStyle]="{  'paddingTop':   previewMode ? customThemeSettings['content_padding_from_top'] ? customThemeSettings['content_padding_from_top'] +'px' :checkForZero(customThemeSettings['content_padding_from_top'],'0px',''): '' ,
  'paddingBottom': previewMode ? customThemeSettings['content_padding_from_bottom'] ? customThemeSettings['content_padding_from_bottom'] +'px' : checkForZero(customThemeSettings['content_padding_from_bottom'],'0px',''):'',
  'paddingLeft':  previewMode? customThemeSettings['content_padding_from_left'] ? customThemeSettings['content_padding_from_left'] +'px' :checkForZero(customThemeSettings['content_padding_from_left'],'0px','') : '',
  'paddingRight':  previewMode ? customThemeSettings['content_padding_from_right'] ? customThemeSettings['content_padding_from_right'] +'px' :checkForZero(customThemeSettings['content_padding_from_right'],'0px',''): '' ,
  'border' : from ? 'none' :'' }"
>
  <div
    class="error_container"
    *ngIf="customValidationErrorMessage.length"
    style="text-align: center; color: red;"
  >
    <p>{{ customValidationErrorMessage }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="mustTotalErrorMessage.length > 0"
    style="text-align: center; color: red;"
  >
    <p>{{ mustTotalErrorMessage }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'required'"
    style="text-align: center;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'requested'"
    style="text-align: center;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>
  <div class="pl-4 ms-1">
    <ng-container *ngIf="!previewMode">
      <ng-container>
        <ng-container>
          <app-display-logic-view
            [currentQuestion]="question"
            [allQuestions]="allQuestions"
            [surveyData]="surveyData"
            (emitDisplayLogic)="displayLogicUpdated($event)"
          ></app-display-logic-view>
        </ng-container>
        </ng-container>
      </ng-container>
  </div>
  <div class="quest" [ngStyle]="{'display':from ? 'flex':''}">
    <h3
      *ngIf="!from  && showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [innerHtml]="getQuestionNumbering(numbering)"
      [ngStyle]="{
    'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
    ></h3>
    <h3
      *ngIf="!from  && !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
      [ngStyle]="{
  'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
  fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
    ></h3>

    <h3
      *ngIf="from && showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngStyle]="{'margin-right':'1%','margin-top':'0.11%',
  'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
  fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [innerHtml]="getQuestionNumbering(numbering)"
    ></h3>
    <h3
      *ngIf="from && !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngStyle]="{'margin-right':'1%','margin-top':'0.11%',
      'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
      fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
    >
      <sup
        style="color: red;"
        *ngIf="showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
        >*</sup
      >
    </h3>
    <h6
      [ngStyle]="{color:previewMode && customThemeSettings &&customThemeSettings['question_font_color'] ? customThemeSettings['question_font_color'] :previewMode && customThemeSettings?'#a5a5a5':'',
    paddingBottom :  previewMode && customThemeSettings && customThemeSettings['padding_btw_question_n_options']  ? customThemeSettings['padding_btw_question_n_options']+ 'px' :'',

    'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',

    fontFamily :  previewMode && customThemeSettings && customThemeSettings['question_font_family'] ? customThemeSettings['question_font_family'] :'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :'',
    width:!question.question_tooltip?'100%':''}"
      [innerHtml]="question.text | safeHtml"
    ></h6>
    <i
      (click)="tooltipClick($event)"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{marginTop: '0.2%'}"
      *ngIf="!mobileView && question.question_tooltip"
      placement="auto"
      [ngbTooltip]="UploadQuestiontipContent"
    ></i>
    <ng-template #UploadQuestiontipContent>
      <div [innerHTML]="question.question_tooltip | safeHtml "></div>
    </ng-template>
  </div>
  <!-- <ng-container *ngIf="!previewMode">
    <ul
      class="nav nav-tabs"
      id="myTab"
      role="tablist"
      *ngIf="displayLogic.length"
    >
      <li
        class="nav-item"
        *ngIf="displayLogic.length"
        (click)="displayLogicClickedEvent(true)"
      >
        <a
          class="nav-link"
          id="home-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          ><i class="fas fa-question mr-1"></i> Response Logic</a
        >
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show"
        [ngClass]="{'active': displayLogicClicked }"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <app-display-logic-view
          [currentQuestion]="question"
          [allQuestions]="allQuestions"
          [surveyData]="surveyData"
          (emitDisplayLogic)="displayLogicUpdated($event)"
        ></app-display-logic-view>
      </div>
    </div>
  </ng-container> -->
  <i
    (click)="openModal($event,tooltipModalQuestion);$event.stopPropagation();"
    class="svg hint-icon"
    container="body"
    [ngStyle]="{marginTop: '0.2%'}"
    *ngIf="this.mobileView && question.question_tooltip"
  ></i>
  <ng-template #tooltipModalQuestion let-modal>
    <a (click)="modal.close()" href="javascript:void(0)" class="close-modal"
      ><i class="fa fa-times"></i
    ></a>
    <div [innerHTML]="question.question_tooltip | safeHtml "></div>
  </ng-template>
  <form *ngIf="question.option_position_type == 1">
    <div class="list-item form-group overflow-x-auto">
      <table class="dynamic-sum-table">
        <tr          
          *ngFor="let option of question.question_options; let i = index"
        >
          <td
            class="form-control-label"
            [ngStyle]="{color: previewMode && customThemeSettings &&  customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ? '#a5a5a5': '',
          fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','') :'',
        fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
        fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
        [innerHtml]="option.text | safeHtml"
          >
            <!-- {{ option.text }} -->
          </td>
          <td>
            <input
              [ngStyle]="{
              height:previewMode ?'auto':'40px',
              'line-height':previewMode ?'20px':'auto',
              paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '',
              paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : ''}"
              (keydown)="checkValidKey($event)"
              (keyup)="onOptionValueChanged($event, option, i)"
              type="text"
              class="form-control inline"
              [value]="getAnswerState(option)"
              [attr.id]="'dynamic_sum_' + question.id + '_' + i"
            />
          </td>
        </tr>
        <tfoot *ngIf="question.validation_option.is_show_total">
          <tr>
            <td class="form-control-label"
            [ngStyle]="{color: previewMode && customThemeSettings &&  customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ? '#a5a5a5': '',
          fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','') :'',
        fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
        fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}">
        <b>Total</b>
      </td>
            <td>
              <input
                [ngStyle]="{
                height:previewMode ?'auto':'40px',
                'line-height':previewMode ?'20px':'auto',
                paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '',
                paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : ''}"
                [(ngModel)]="total"
                [ngModelOptions]="{ standalone: true }"
                readonly="true"
                type="text"
                readonly="true"
                style="color: black !important;"
                class="form-control total-control inline"
              />
            </td>
          </tr>
        </tfoot>
      </table>
      <div
        *ngIf="previewMode && question.validation_option.to_observe"
        class="form-check item no-empty"
      >
        <input
          (change)="checkboxOptionChecked($event)"
          type="checkbox"
          [checked]="checkIfChecked()"
          class="fsop-checkbox"
          name="answer_{{question.id}}_{{ question.question_options.length }}"
          id="answer_{{question.id}}_{{ question.question_options.length }}"
        />
        <p
          [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] : previewMode && customThemeSettings ?'#a5a5a5': '',
        fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
      fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
      fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
          (click)="checkRelatedChecked()"
          class="form-check-label"
        >
          {{previewMode? 'No Opportunity to Observe' : 'No opportunity to
          observe'}}
        </p>
      </div>
    </div>
  </form>

  <form *ngIf="question.option_position_type == 2" class="form-inline form-inline-row">
    <div class="list-item form-group">
      <div
        class="item"
        *ngFor="let option of question.question_options; let i = index"
      >
        <label
          *ngIf="option.text"
          class="form-control-label"
          [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] : previewMode && customThemeSettings ?'#a5a5a5': '',
        fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
      fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
      fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
      [innerHtml]="option.text | safeHtml"
          >
          <!-- {{ option.text }} -->
          </label
        >
        <input
          [ngStyle]="{
            height:previewMode ?'auto':'40px',
            'line-height':previewMode ?'20px':'auto',
          paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '',
          paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : ''}"
          (keydown)="checkValidKey($event)"
          (keyup)="onOptionValueChanged($event, option, i)"
          type="text"
          [value]="getAnswerState(option)"
          class="form-control"
          [attr.id]="'dynamic_sum_' + question.id + '_' + i"
        />
      </div>
      <div class="item" *ngIf="question.validation_option.is_show_total">
        <label
          class="form-control-label min-width-auto"
          style="margin-right: 10px;"
          [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] : previewMode && customThemeSettings ?'#a5a5a5': '',
        fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
      fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
      fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
          ><b>Total</b></label
        >
        <input
          [ngStyle]="{
          height:previewMode ?'auto':'40px',
          'line-height':previewMode ?'20px':'auto',
          paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '',
          paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : ''}"
          [(ngModel)]="total"
          [ngModelOptions]="{ standalone: true }"
          readonly="true"
          type="text"
          readonly="true"
          style="color: black !important;"
          class="form-control total-control"
        />
      </div>
      <div
        *ngIf="previewMode && question.validation_option.to_observe"
        class="item left-align"
      >
        <input
          (change)="checkboxOptionChecked($event)"
          type="checkbox"
          [checked]="checkIfChecked()"
          class="fsop-checkbox"
          name="answer_{{question.id}}_{{ question.question_options.length }}"
          id="answer_{{question.id}}_{{ question.question_options.length }}"
        />
        <p
          (click)="checkRelatedChecked()"
          style="margin-left: 20px;"
          class="form-check-label"
          [ngStyle]="{color: previewMode && customThemeSettings && customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] : previewMode && customThemeSettings ?'#a5a5a5': '',
        fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
      fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
      fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
        >
          {{previewMode? 'No Opportunity to Observe' : 'No opportunity to
          observe'}}
        </p>
      </div>
    </div>
  </form>
  <div *ngIf="previewMode && okButtonShow && customThemeSettings['theme_layout'] == 'focus'">
    <button
    [id]="'okbt'+question.numbering"
            (click)="onOkClick()"
            class="btn btn-md"
            style="display: inline; margin-left: 45px; color: white; background: #54902b;"
          >OK
          </button>
  </div>
</div>
