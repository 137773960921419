import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { filter } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AssessmentDataService } from "../../../../../app/core/assessment-data.service";
@Component({
  selector: "fsop-matrix-table",
  templateUrl: "./fsop-matrix-table.component.html",
  styleUrls: ["./fsop-matrix-table.component.scss"],
})
export class FsopMatrixTableComponent implements OnInit, AfterViewInit {
  okButtonShow = false;
  @Output("onQuestionDone")
  onQuestionDone = new EventEmitter<any>();

  @Input("question")
  question: any;
  customQuestionError: string = "";

  @Input("previewMode")
  previewMode: boolean;

  @Input("customThemeSettings") customThemeSettings: object;

  @Input("theme")
  theme: string;
  @Input("from") from = "";
  @Input("numbering")
  numbering: number;

  @Input("showQuestionNumber")
  showQuestionNumber: boolean;

  @Input("respMode")
  respMode: boolean;

  @Input("isNextButtonClicked")
  isNextButtonClicked: boolean;

  @Output("resetNextButton")
  resetNextButton = new EventEmitter<boolean>();

  questionAnswer: any;

  @Input("showRequiredAsterisk") showRequiredAsterisk: boolean;

  @Input("editable") editable: boolean;
  @Input("backClicked") backClicked: boolean;
  mobileView: boolean = false;
  @ViewChild("tooltipModalQuestion", { static: false }) tooltipModalQuestion: any;
  public random = Math.random() * 1000;

  @Input() allQuestions: any;
  @Input() surveyData: any;

  constructor(private modalService: NgbModal, public assessmentDataService: AssessmentDataService) { }

  ngOnInit() {
    if (window.outerWidth < 769) {
      this.mobileView = true;
    }
    if (this.previewMode) {
      this.questionAnswer = {
        answer: [],
        ...this.question,
      };

      if (
        !this.question["answer"] ||
        (this.question["answer"] &&
          this.question["answer"].length &&
          this.question.answer.filter((ans: any) => {
            return !ans.column.length || !ans.row;
          }).length > 0)
      ) {
        // if (this.question.option_type === 3) {
        //   this.question.question_options.rows.forEach((row: any) => {
        //     this.questionAnswer.answer.push({
        //       column: [this.question.question_options.cols[0].url],
        //       row: row.url
        //     });
        //   });
        // } else {
        delete this.question.answer;
        this.question.answer = [];
        this.questionAnswer.answer = [];
        this.question.question_options.rows.forEach((row: any) => {
          this.question.answer.push({
            column: [],
            row: row.url,
          });
          this.questionAnswer.answer.push({
            column: [],
            row: row.url,
          });
        });
        // }

        // }else{
        //   if(this.question.option_type === 2)
        //   this.question.answer = this.question.answer.filter((a: any) => a.row !== null && a.column.length > 0);
      } else {
        setTimeout(() => {
          this.backClicked = true;
        }, 500);
      }
    }
  }
  ngAfterViewInit() {
    if (!this.previewMode) {
      this.setResizableColumn();
    }
    setTimeout(() => {
      if (
        window.name &&
        window.name == "frameAndHashing" &&
        window.innerWidth < 769
      ) {
        this.mobileView = true;
      }
    }, 100);
  }

  checkForZero(value: any, zeroReturnValue: any, defaultValue: any) {
    if (value == 0) {
      return zeroReturnValue;
    } else {
      return defaultValue;
    }
  }

  setResizableColumn() {
    // Query the table
    const table: any = document.getElementById("resizeMe_" + this.random)?.children[0];

    // Query all headers
    const cols: any = document.getElementById("th_" + this.random);

    // Loop over them
    // cols.forEach.call(cols, (col:any)=> {

    // Create a resizer element
    const resizer = document.createElement("div");
    resizer.classList.add("resizer");

    // Set the height
    resizer.style.height = `${table["offsetHeight"]}px`;

    // Add a resizer element to the column
    cols.appendChild(resizer);

    // Will be implemented in the next section
    this.createResizableColumn(cols, resizer);
    // });
  }
  createResizableColumn = function (col: any, resizer: any) {
    // Track the current position of mouse
    let x = 0;
    let w = 0;

    const mouseDownHandler = function (e: any) {
      // Get the current mouse position
      x = e.clientX;

      // Calculate the current width of column
      const styles = window.getComputedStyle(col);
      w = parseInt(styles.width, 10);

      // Attach listeners for document's events
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
      resizer.classList.add("resizing");
    };

    const mouseMoveHandler = function (e: any) {
      // col.style.minWidth ? col.style.minWidth = null :'';
      if (e) {
        e.preventDefault();
      }
      // Determine how far the mouse has been moved
      const dx = e.clientX - x;

      // Update the width of column
      col.style.width = `${w + dx}px`;
      col.style.minWidth = `${w + dx}px`;
      this.question["columns_width"] = `${w + dx}px`;
    }.bind(this);

    // When user releases the mouse, remove the existing event listeners
    const mouseUpHandler = function () {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
      resizer.classList.remove("resizing");
    };

    resizer.addEventListener("mousedown", mouseDownHandler);
  };

  checkIfChecked(row_url: string, col_url: string) {
    if (this.question.answer && this.question.answer["length"]) {
      let index = this.question["answer"].findIndex((obj: any) => {
        return obj["row"] == row_url && obj["column"].indexOf(col_url) > -1;
      });
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onOkClick() {
    this.assessmentDataService.focusAnswerOkClicked$.next(true);
  }

  onSelectBoxClick(event: any) {
    event.stopPropagation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.previewMode) {
      const nextClickedChange: SimpleChange = changes.isNextButtonClicked;
      this.isNextButtonClicked =
        nextClickedChange && nextClickedChange.currentValue;

      if (this.isNextButtonClicked) {
        // if (this.question.answer !== undefined) {
        //   this.checkCustomValidation(this.question);
        // } else {
        //   this.checkCustomValidation(this.questionAnswer);
        // }
        this.checkCustomValidation();
      }
    }
  }
  openModal(e: any, content: any) {
    this.modalService.open(content, {
      size: "sm",
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "tooltip-modal",
    });
  }
  private updateQuestionAnswer(question: any) {

    if (this.previewMode) {
      this.okButtonShow = true;
      this.question["answer"] = question.answer;

      const answer = question.answer.filter((a: any) => {
        return a.column.length > 0 && a.row !== null;
      });

      if (answer.length != question.answer.length) {
        this.question["answered"] = false;
      } else {
        this.question["answered"] = true;
      }

      // let qa = {
      //   url: question.url,
      //   type: question._type,
      //   answer
      // }

      this.onQuestionDone.emit(this.question);
    }
  }

  checkFilledAns(arr: any) {
    if (arr.length) {
      let count = 0;
      arr.forEach((element: any) => {
        count = element.column.length > 0 ? count + 1 : count;
      });
      if (count == 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private checkCustomValidation(): void {
    switch (this.question.option_type) {
      case 1:
        // radio box
        if (this.question.answer !== undefined) {
          if (
            this.question.validation_option.validation != 2 ||
            (this.question.validation_option.validation == 2 &&
              this.checkFilledAns(this.question.answer))
          ) {
            let unanswered = this.question.answer.filter((a: any) => {
              return !a.column.length;
            });

            if (
              (this.question.validation_option.validation == 0 ||
                this.question.validation_option.validation == 1) &&
              unanswered.length == this.question.question_options.rows.length
            ) {
              this.customQuestionError = "";
              delete this.question.hasCustomValidationError;
            } else {
              if (this.question.validation_option.is_custom_validation) {
                let fetched = this.question.answer.find((a: any) => {
                  return (
                    a.column[0] ===
                    this.question.question_options.cols[
                      this.question.validation_option.col - 1
                    ].url &&
                    a.row ===
                    this.question.question_options.rows[
                      this.question.validation_option.row - 1
                    ].url
                  );
                });

                if (fetched === undefined) {
                  this.customQuestionError = this.question.validation_option.msg;
                  this.question["hasCustomValidationError"] = true;
                } else {
                  this.customQuestionError = "";
                  delete this.question.hasCustomValidationError;
                }
              }
            }
          } else {
            this.customQuestionError = "";
            delete this.question.hasCustomValidationError;
          }
        } else {
          if (
            this.question.validation_option.validation != 2 ||
            (this.question.validation_option.validation == 2 &&
              this.checkFilledAns(this.questionAnswer.answer))
          ) {
            let unanswered = this.questionAnswer.answer.filter((a: any) => {
              return !a.column.length;
            });

            if (
              (this.question.validation_option.validation == 0 ||
                this.question.validation_option.validation == 1) &&
              unanswered.length == this.question.question_options.rows.length
            ) {
              this.customQuestionError = "";
              delete this.question.hasCustomValidationError;
            } else {
              if (this.questionAnswer.validation_option.is_custom_validation) {
                let fetched = this.questionAnswer.answer.find((a: any) => {
                  return (
                    a.column[0] ===
                    this.questionAnswer.question_options.cols[
                      this.questionAnswer.validation_option.col - 1
                    ].url &&
                    a.row ===
                    this.questionAnswer.question_options.rows[
                      this.questionAnswer.validation_option.row - 1
                    ].url
                  );
                });

                if (fetched === undefined) {
                  this.customQuestionError = this.questionAnswer.validation_option.msg;
                  this.question["hasCustomValidationError"] = true;
                } else {
                  this.customQuestionError = "";
                  delete this.question.hasCustomValidationError;
                }
              }
            }
          } else {
            this.customQuestionError = "";
            delete this.question.hasCustomValidationError;
          }
        }
        break;
      case 2:
        // checkbox
        if (this.question.answer !== undefined) {
          if (
            this.question.validation_option.validation != 2 ||
            (this.question.validation_option.validation == 2 &&
              this.checkFilledAns(this.question.answer))
          ) {
            let unanswered = this.question.answer.filter((a: any) => {
              return !a.column.length;
            });

            if (
              (this.question.validation_option.validation == 0 ||
                this.question.validation_option.validation == 1) &&
              unanswered.length == this.question.question_options.rows.length
            ) {
              this.customQuestionError = "";
              delete this.question.hasCustomValidationError;
            } else {
              if (this.question.validation_option.is_custom_validation) {
                let fetched = this.question.answer.find((a: any) => {
                  return (
                    a.column.includes(
                      this.question.question_options.cols[
                        this.question.validation_option.col - 1
                      ].url
                    ) &&
                    a.row ===
                    this.question.question_options.rows[
                      this.question.validation_option.row - 1
                    ].url
                  );
                });

                if (fetched === undefined) {
                  this.customQuestionError = this.question.validation_option.msg;
                  this.question["hasCustomValidationError"] = true;
                } else {
                  this.customQuestionError = "";
                  delete this.question.hasCustomValidationError;
                }
              }
            }
          } else {
            this.customQuestionError = "";
            delete this.question.hasCustomValidationError;
          }
        } else {
          // custom validation
          if (
            this.question.validation_option.validation != 2 ||
            (this.question.validation_option.validation == 2 &&
              this.checkFilledAns(this.questionAnswer.answer))
          ) {
            let unanswered = this.questionAnswer.answer.filter((a: any) => {
              return !a.column.length;
            });

            if (
              (this.question.validation_option.validation == 0 ||
                this.question.validation_option.validation == 1) &&
              unanswered.length == this.question.question_options.rows.length
            ) {
              this.customQuestionError = "";
              delete this.question.hasCustomValidationError;
            } else {
              if (this.questionAnswer.validation_option.is_custom_validation) {
                let fetched = this.questionAnswer.answer.find((a: any) => {
                  return (
                    a.column.includes(
                      this.questionAnswer.question_options.cols[
                        this.questionAnswer.validation_option.col - 1
                      ].url
                    ) &&
                    a.row ===
                    this.questionAnswer.question_options.rows[
                      this.questionAnswer.validation_option.row - 1
                    ].url
                  );
                });

                if (fetched === undefined) {
                  this.customQuestionError = this.question.validation_option.msg;
                  this.question["hasCustomValidationError"] = true;
                } else {
                  this.customQuestionError = "";
                  delete this.question.hasCustomValidationError;
                }
              }
            }
          } else {
            this.customQuestionError = "";
            delete this.question.hasCustomValidationError;
          }
        }
        break;
      case 3:
        // drop down
        if (this.question.answer !== undefined) {
          if (
            this.question.validation_option.validation != 2 ||
            (this.question.validation_option.validation == 2 &&
              this.checkFilledAns(this.question.answer))
          ) {
            let unanswered = this.question.answer.filter((a: any) => {
              return !a.column.length;
            });

            if (
              (this.question.validation_option.validation == 0 ||
                this.question.validation_option.validation == 1) &&
              unanswered.length == this.question.question_options.rows.length
            ) {
              this.customQuestionError = "";
              delete this.question.hasCustomValidationError;
            } else {
              if (this.question.validation_option.is_custom_validation) {
                let fetched = this.question.answer.find((a: any) => {
                  return (
                    a.column[0] ===
                    this.question.question_options.cols[
                      this.question.validation_option.col - 1
                    ].url &&
                    a.row ===
                    this.question.question_options.rows[
                      this.question.validation_option.row - 1
                    ].url
                  );
                });

                if (fetched === undefined) {
                  this.customQuestionError = this.question.validation_option.msg;
                  this.question["hasCustomValidationError"] = true;
                } else {
                  this.customQuestionError = "";
                  delete this.question.hasCustomValidationError;
                }
              }
            }
          } else {
            this.customQuestionError = "";
            delete this.question.hasCustomValidationError;
          }
        } else {
          if (
            this.question.validation_option.validation != 2 ||
            (this.question.validation_option.validation == 2 &&
              this.checkFilledAns(this.questionAnswer.answer))
          ) {
            let unanswered = this.questionAnswer.answer.filter((a: any) => {
              return !a.column.length;
            });

            if (
              (this.question.validation_option.validation == 0 ||
                this.question.validation_option.validation == 1) &&
              unanswered.length == this.question.question_options.rows.length
            ) {
              this.customQuestionError = "";
              delete this.question.hasCustomValidationError;
            } else {
              if (this.questionAnswer.validation_option.is_custom_validation) {
                let fetched = this.questionAnswer.answer.find((a: any) => {
                  return (
                    a.column[0] ===
                    this.questionAnswer.question_options.cols[
                      this.questionAnswer.validation_option.col - 1
                    ].url &&
                    a.row ===
                    this.questionAnswer.question_options.rows[
                      this.questionAnswer.validation_option.row - 1
                    ].url
                  );
                });

                if (fetched === undefined) {
                  this.customQuestionError = this.questionAnswer.validation_option.msg;
                  this.question["hasCustomValidationError"] = true;
                } else {
                  this.customQuestionError = "";
                  delete this.question.hasCustomValidationError;
                }
              }
            }
          } else {
            this.customQuestionError = "";
            delete this.question.hasCustomValidationError;
          }
        }
        break;
    }
  }

  radioOptionChecked(rowOption: any, colOption: any): void {
    var handleEventChange = (q: any) => {
      let fetchedAnswer = q.answer.find((a: any) => {
        return a.row === rowOption.url;
      });

      if (fetchedAnswer !== undefined) {
        let fetchedAnswerIndex = q.answer.indexOf(fetchedAnswer);
        q.answer[fetchedAnswerIndex].column = [colOption.url];
      } else {
        q.answer.push({
          column: [colOption.url],
          row: rowOption.url,
        });
      }

      setTimeout(() => {
        this.updateQuestionAnswer(q);
      }, 1000);
    };

    this.resetNextButton.emit(true);
    if (this.question.answer) {
      handleEventChange(this.question);
    } else {
      handleEventChange(this.questionAnswer);
    }
  }

  checkboxOptionChecked(event: any, rowOption: any, colOption: any) {
    this.resetNextButton.emit(true);
    var handleEventChange = (q: any) => {
      let fetchedRow = q.answer.find((a: any) => {
        return a.row === rowOption.url;
      });

      if (event.target.checked) {
        if (fetchedRow !== undefined) {
          let rowIndex = q.answer.indexOf(fetchedRow);
          q.answer[rowIndex].column.push(colOption.url);
        } else {
          q.answer.push({
            column: [colOption.url],
            row: rowOption.url,
          });
        }
      } else {
        if (fetchedRow !== undefined) {
          let rowIndex = q.answer.indexOf(fetchedRow);
          let fetchedColUrl = q.answer[rowIndex].column.find(
            (colUrl: string) => {
              return colOption.url === colUrl;
            }
          );

          if (fetchedColUrl !== undefined) {
            let colIndex = q.answer[rowIndex].column.indexOf(fetchedColUrl);
            q.answer[rowIndex].column.splice(colIndex, 1);
          }
        }
      }

      this.updateQuestionAnswer(q);
    };

    if (this.question.answer !== undefined) {
      handleEventChange(this.question);
    } else {
      handleEventChange(this.questionAnswer);
    }
  }

  getQuestionNumbering(numbering: any) {
    return `Q${numbering}<sup
    style="color: red;"
    *ngIf="showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
    >*</sup
  >`;
  }

  getQuestionNumberingWithoutAsterick(numbering: any) {
    return `Q${numbering}`;
  }
  public onSelectedOptionChanged(selected: any, rowOption: any): void {
    this.resetNextButton.emit(true);

    let handleEventchange = (q: any) => {
      let fetchedRow = q.answer.find((a: any) => a.row === rowOption.url);
      if (fetchedRow !== undefined) {
        let rowIndex = q.answer.indexOf(fetchedRow);
        q.answer[rowIndex].column = [
          q.question_options.cols[selected.target.value - 1].url,
        ];

        q.answer[rowIndex].row = rowOption.url;
      } else {
        q.answer.push({
          row: rowOption.url,
          column: [q.question_options.cols[selected.target.value - 1].url],
        });
      }

      this.updateQuestionAnswer(q);
    };

    if (this.question.answer) {
      handleEventchange(this.question);
    } else {
      handleEventchange(this.questionAnswer);
    }
  }

  public isSelected(rowIndex: number, col: any) {
    if (this.question.hasOwnProperty("answer")) {
      if (this.question.answer[rowIndex] !== undefined) {
        return this.question.answer[rowIndex].column.includes(col.url);
      }
    }
  }

  optionChecked(event: any, row: any, col: any, rowIndex: any, colIndex: any) {
    if (event && event.target.tagName.toLowerCase() == "input") {
      event.stopPropagation();
    } else {
      if (this.question.option_type == 1) {
        this.radioOptionChecked(row, col);
      } else {
        $(
          "#answer_" + this.question.id + "_" + rowIndex + "_" + colIndex
        ).trigger("click");
      }
    }
  }

  tooltipClick(event: any) {
    event.stopPropagation();
  }

  displayLogic: any = [];
  displayLogicUpdated(event: any) {
    this.displayLogic = event;
  }

  displayLogicClicked: any = false;
  displayLogicClickedEvent(event: any) {
    this.displayLogicClicked = event;
  }
}
