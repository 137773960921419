<div class="no-border">
    <div class="modal-content">
        <div class="fsop-alert-modal">
            <span>Alert</span>
        </div>
        <div class="fsop-alert-modal-body">
            <p>You are about to create a Multirater Survey without an accompanying project name. <br> Do you wish to
                proceed? </p>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="activeModal.dismiss('Pro')">
            Confirm
        </button>
        <button class="btn btn-secondary" (click)="activeModal.close('Bro')">
            Cancel
        </button>
    </div>
</div>