import { Injectable } from "@angular/core";

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class SuperadminService {
  public isSuperAdminLogged: any = -1;

  constructor() {}
}
