<form
  class="ce-form"
  [formGroup]="surveyEmailShareForm"
  novalidate
  style="overflow: visible;"
>
  <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label"
      >To:<span style="color: red;">*</span></label
    >
    <div class="col-sm-10">
      <button
        type="button"
        (click)="openAddContactsModal(contactsModal)"
        class="btn  btn-primary ct-act-btn"
      >
        <span *ngIf="emailReminderData == undefined">Add Contacts</span>
        <span *ngIf="emailReminderData != undefined">View Contacts</span>
      </button>

      <a (click)="openGuideLinesModal(guideLineModal)" class="pull-right guideLine link"
        >View guidelines</a
      >
    </div>
  </div>

  <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">From: </label>
    <div class="col-sm-10">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="formGroupExampleInput">From Email:</label>
            <input
              [disabled]="true"
              formControlName="from_email"
              type="email"
              class="form-control"
              placeholder=""
              [disabled]="true"
            />
          </div>
          <small
            *ngIf="!surveyEmailShareForm.controls.from_email.valid && isSubmitted"
            class="required"
            translate
          >
            {{ requiredErrMsg }}
          </small>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>From Name: </label>
            <input
              [ngStyle]="{
                border:
                  (!surveyEmailShareForm.controls.from_name.valid && surveyEmailShareForm.controls.from_name.touched) ||
                  (!surveyEmailShareForm.controls.from_name.valid && isSubmitted)
                    ? '1px solid red'
                    : '0px'
              }"
              formControlName="from_name"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
          <small
            *ngIf="!surveyEmailShareForm.controls.from_name.valid && isSubmitted"
            class="required"
            translate
          >
            {{ requiredErrMsg }}
          </small>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Reply to Email: </label>
            <input
              formControlName="reply_to_email"
              type="text"
              class="form-control"
              [ngStyle]="{
                border:
                  !isValidEmail(surveyEmailShareForm.controls.reply_to_email.value) &&
                  (surveyEmailShareForm.controls.reply_to_email.dirty || isSubmitted)
                    ? '1px solid red'
                    : '0px'
              }"
              placeholder=""
              [disabled]="true"
            />
          </div>

          <small
            *ngIf="
              !isValidEmail(surveyEmailShareForm.controls.reply_to_email.value) &&
              (surveyEmailShareForm.controls.reply_to_email.untouched ||
                surveyEmailShareForm.controls.reply_to_email.touched) &&
              isSubmitted
            "
            class="required"
            translate
          >
            {{ requiredErrMsg }}
          </small>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row" *ngIf="surveyType === 1 && !project">
    <label for="staticEmail" class="col-sm-2 col-form-label"
      >For: <span style="color: red;">*</span></label
    >
    <div class="col-sm-10">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="formGroupExampleInput"
              >Participant's First Name:
            </label>
            <input
              [ngStyle]="{
                border:
                  (!surveyEmailShareForm.controls.ratee_first_name.valid &&
                    surveyEmailShareForm.controls.ratee_first_name.touched) ||
                  (!surveyEmailShareForm.controls.ratee_first_name.valid && isSubmitted)
                    ? '1px solid red'
                    : '0px'
              }"
              formControlName="ratee_first_name"
              (keyup)="getNameForSubject()"
              type="text"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              min="0"
              maxlength="35"
              class="form-control"
              [ngClass]="disable_ratee ? 'no-pointer-events' : ''"
            />
          </div>
          <div
            *ngIf="
              !disable_ratee &&
              surveyEmailShareForm.controls.ratee_first_name.value &&
              surveyEmailShareForm.controls.ratee_first_name.value.length
            "
          >
            <small
              >Character Count: {{
              surveyEmailShareForm.controls.ratee_first_name.value &&
              surveyEmailShareForm.controls.ratee_first_name.value.length ?
              surveyEmailShareForm.controls.ratee_first_name.value.length : 0 }}
            </small>
          </div>
          <small
            *ngIf="!surveyEmailShareForm.controls.ratee_first_name.valid && isSubmitted"
            class="required"
            translate
          >
            {{ requiredErrMsg }}
          </small>
          <small
            class="required"
            *ngIf="
              !disable_ratee &&
              surveyEmailShareForm.controls.ratee_first_name.value &&
              surveyEmailShareForm.controls.ratee_first_name.value.length > 20
            "
            >You can only input a maximum of 35 characters.</small
          >
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Participant's Last Name: </label>
            <input
              [ngStyle]="{
                border:
                  (!surveyEmailShareForm.controls.ratee_last_name.valid &&
                    surveyEmailShareForm.controls.ratee_last_name.touched) ||
                  (!surveyEmailShareForm.controls.ratee_last_name.valid && isSubmitted)
                    ? '1px solid red'
                    : '0px'
              }"
              formControlName="ratee_last_name"
              (keyup)="getNameForSubject()"
              type="text"
              class="form-control"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              min="0"
              maxlength="35"
              placeholder=""
              [ngClass]="disable_ratee ? 'no-pointer-events' : ''"
            />
          </div>
          <div
            *ngIf="
              !disable_ratee &&
              surveyEmailShareForm.controls.ratee_last_name.value &&
              surveyEmailShareForm.controls.ratee_last_name.value.length
            "
          >
            <small
              >Character Count: {{
              surveyEmailShareForm.controls.ratee_last_name.value &&
              surveyEmailShareForm.controls.ratee_last_name.value.length ?
              surveyEmailShareForm.controls.ratee_last_name.value.length : 0 }}
            </small>
          </div>
          <small
            *ngIf="!surveyEmailShareForm.controls.ratee_last_name.valid && isSubmitted"
            class="required"
            translate
          >
            {{ requiredErrMsg }}
          </small>
          <small
            class="required"
            *ngIf="
              !disable_ratee &&
              surveyEmailShareForm.controls.ratee_last_name.value &&
              surveyEmailShareForm.controls.ratee_last_name.value.length > 20
            "
            >You can only input a maximum of 35 characters.</small
          >
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Participant's Email Address: </label>
            <input
              formControlName="ratee_email_id"
              type="text"
              class="form-control"
              [ngStyle]="{
                border:
                  (!surveyEmailShareForm.controls.ratee_email_id.valid &&
                    surveyEmailShareForm.controls.ratee_email_id.touched) ||
                  (!surveyEmailShareForm.controls.ratee_email_id.valid && isSubmitted)
                    ? '1px solid red'
                    : '0px'
              }"
              [ngClass]="disable_ratee ? 'no-pointer-events' : ''"
              placeholder=""
            />
          </div>

          <ng-container
            *ngIf="
              !isValidEmail(surveyEmailShareForm.controls.ratee_email_id.value) &&
              (surveyEmailShareForm.controls.ratee_email_id.untouched ||
                surveyEmailShareForm.controls.ratee_email_id.touched) &&
              isSubmitted
            "
          >
            <small
              *ngIf="surveyEmailShareForm.controls.ratee_email_id.hasError('required')"
              class="required"
              translate
            >
              {{ requiredErrMsg }}
            </small>
            <small
              *ngIf="surveyEmailShareForm.controls.ratee_email_id.hasError('email')"
              class="required"
              translate
            >
              Enter a valid email address
            </small>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label for="inputPassword" class="col-sm-2 col-form-label"
      >Subject: <span style="color: red;">*</span></label
    >
    <div class="col-sm-10">
      <input
        type="text"
        [ngStyle]="{
          border:
            (!surveyEmailShareForm.controls.subject.valid && surveyEmailShareForm.controls.subject.touched) ||
            (!surveyEmailShareForm.controls.subject.valid && isSubmitted)
              ? '1px solid red'
              : '0px'
        }"
        formControlName="subject"
        (focus)="activeEditingField = 'subject'"
        class="form-control"
        placeholder=""
      />
      <small
        [hidden]="surveyEmailShareForm.controls.subject.valid || surveyEmailShareForm.controls.subject.untouched"
        class="required"
        translate
      >
        Subject must contain at least 10 characters
      </small>
    </div>
  </div>

  <div class="form-group row">
    <label for="inputPassword" class="col-sm-2 col-form-label"
      >Message: <span style="color: red;">*</span></label
    >
    <div class="col-sm-10">
      <div class="form-group">
        <app-template-list-select
        [templateIndexToAutoSelect]="surveyInfo.survey_type == 1 ? 1 : 0"
        [form]="surveyEmailShareForm"
        [templateType]="templateType"
        (onEmailBodyChanged)="onEmailBodyChanged($event)"
      ></app-template-list-select>
      </div>
      <editor
        formControlName="message"
        [ngStyle]="{
          border:
            (!surveyEmailShareForm.controls.message.valid && surveyEmailShareForm.controls.message.touched) ||
            (!surveyEmailShareForm.controls.message.valid && isSubmitted)
              ? '1px solid red'
              : '0px'
        }"
        [(ngModel)]="emailBody"
        [apiKey]="editorApiKey"
        id="text-editor-element"
        [init]="tinymce_init_obj"
      ></editor>
      <!-- [init]="{
            min_height: 250,
            max_height: 500,
            menubar: false,
            plugins: 'link image media',
            toolbar:
              'undo redo bold italic underline link image media forecolor backcolor alignleft aligncenter alignright alignjustify bullist numlist fontsizeselect'
          }" -->
      <small
        [hidden]="surveyEmailShareForm.controls.message.valid || surveyEmailShareForm.controls.message.untouched"
        class="required"
        translate
      >
        {{ requiredErrMsg }}
      </small>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-12 col-form-label">{{ timezoneNote }}</label>
  </div>

  <div class="form-group row">
 
    <div class="col-sm-2 d-flex align-items-center">
      <label class="col-form-label bold-font">
        <span class="text-wrap">Survey Expiration Date:</span>
        <span class="red-asterisk">*</span>
      </label>
    </div>
    <div class="col-md-10">
      <div class="row">
        <div class="col-md-4">
          <!-- <input
            [(ngModel)]="expirationDate"
            (ngModelChange)="onExpirationDateChanged($event)"
            onkeydown="return false"
            (click)="d.toggle()"
            class="form-control"
            placeholder="mm-dd-yyyy"
            name="dp"
            ngbDatepicker
            #d="ngbDatepicker"
            id="date_picker_format"
            [ngModelOptions]="{ standalone: true }"
            [ngStyle]="{
              border: !expirationDate && isSubmitted ? '1px solid red' : '0px'
            }"
            [readonly]="true"
            [ngClass]="emailReminderData && emailReminderData.expirationDate ? 'in-active no-pointer-events' : ''"
          /> -->
          <!-- [disabled] = "emailReminderData != undefined" -->
          <input 
          [ngStyle]="{
            border: !expirationDate && isSubmitted ? '1px solid red' : '0px'
          }"
          (ngModelChange)="onExpirationDateChanged($event)"
          [readonly]="true"
          [ngClass]="emailReminderData && emailReminderData.expirationDate ? 'in-active no-pointer-events' : ''"
          placeholder="mm-dd-yyyy"
          id="date_picker_format"
          [(ngModel)]="expirationDate"
          [ngModelOptions]="{ standalone: true }"
          class="form-control" ngbDatepicker
          #d="ngbDatepicker"
          (click)="d.toggle()"
          >
        </div>

        <div class="col-md-4 alertTimePicker">
          <ng-template #popTemplate>
            <ngb-timepicker
              [(ngModel)]="mytime"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="scheduleTimeChanged($event)"
              [meridian]="true"
            ></ngb-timepicker>
          </ng-template>
          <input
            placeholder="hh:mm"
            [(ngModel)]="scheduleTimeVal"
            [ngModelOptions]="{ standalone: true }"
            id="schedule_time"
            container=".modal-content"
            [ngbPopover]="popTemplate"
            [autoClose]="'outside'"
            onkeydown="return false"
            placement="bottom"
            class="form-control"
            [readonly]="true"
            (click)="updateMyTime()"
            [ngClass]="emailReminderData && emailReminderData.expirationDate ? 'in-active no-pointer-events' : ''"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <!-- *ngIf = "!emailReminderData || (emailReminderData && emailReminderData.expirationDate)" -->
    <div class="col-sm-2 d-flex align-items-center">
      <label class="col-form-label bold-font">
        <span class="text-wrap">Link Expires (in days):</span>
        <span class="red-asterisk">*</span>
      </label>
    </div>
    
    
    <!-- <div *ngIf = "emailReminderData && !emailReminderData.expirationDate" class="col-md-2"></div> -->
    <div class="col-md-10">
      <div class="row">
        <div class="form-group col-md-4">
          <!-- *ngIf = "!emailReminderData || (emailReminderData && emailReminderData.expirationDate)" -->
          <div class="form-group">
            <input
              formControlName="expire_days"
              type="number"
              min="1"
              [ngStyle]="{
                border:
                  (!surveyEmailShareForm.controls.expire_days.valid &&
                    surveyEmailShareForm.controls.expire_days.touched) ||
                  surveyEmailShareForm.controls.expire_days.value > maxLinkDate ||
                  (!surveyEmailShareForm.controls.expire_days.valid && isSubmitted)
                    ? '1px solid red'
                    : '0px'
              }"
              (keyup)="onEmailExpirationChanged($event)"
              class="form-control"
              placeholder="Email expiration duration in days"
            />
            <small
              *ngIf="
                !surveyEmailShareForm.controls.expire_days.valid &&
                surveyEmailShareForm.controls.expire_days.untouched &&
                isSubmitted
              "
              class="required"
              translate
            >
              Link expiry should be at least 1 day
            </small>
            <small
              *ngIf="surveyEmailShareForm.controls.expire_days.hasError('ExpirationValid')"
              class="required"
              translate
            >
              The link expiration date cannot be greater than the survey
              expiration date.
            </small>
          </div>

          <div class="form-group">
       
            <app-shortcode-select (onShortCodeSelect)="onShortCodeSelect($event)"></app-shortcode-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-act-ctn">
    <button
      *ngIf="currentSurvey && currentSurvey.status == 'scheduled'"
      (click)="save()"
      class="btn btn-md btn-primary act-btn"
    >
      Schedule
    </button>
    <button
      *ngIf="currentSurvey && currentSurvey.status != 'scheduled'"
      (click)="send(false)"
      class="btn btn-md btn-primary act-btn"
    >
      Send
    </button>
    <button
      (click)="openTestEmailModal(testEmailModal)"
      class="act-btn btn btn-md btn-primary"
    >
      Send Test Email
    </button>
    <button
      (click)="closeComposeEmailModal()"
      class="act-btn btn btn-md  btn-secondary"
    >
      Cancel
    </button>
  </div>
</form>

<ng-template #alert class="no-border">
  <app-alert
    (onCloseModalTriggered)="onActiveModalClosed($event)"
    [content]="alertContent"
  ></app-alert>
</ng-template>

<ng-template #contactsModal let-modal class="no-border">
  <div class="modal-content">
    <!-- <span>{{ recepients | json}}</span> -->
    <div class="fsop-alert-modal d-flex justify-content-between px-3">
      <span *ngIf="emailReminderData == undefined">Add Contacts</span>
      <span *ngIf="emailReminderData != undefined">View Contacts</span>
      <button
        type="button"
        (click)="addColumnName()"
        class="btn  btn-primary ct-act-btn"
        *ngIf="emailReminderData == undefined && columns && columns.length <= 49"
      >
        <span>Add Columns</span>
      </button>
    </div>

    <div class="fsop-alert-modal-body contact-table">
      <ngx-datatable
        *ngIf="emailReminderData != undefined"
        #viewContactDataTable
        class="bootstrap contactform-table contact-table"
        (select)="onSelect($event)"
        [selected]="selected"
        [selectAllRowsOnPage]="false"
        [externalPaging]="false"
        [headerHeight]="50"
        [columnMode]="'force'"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [rows]="reminderContacts"
        [scrollbarH]="true"
      >
        <ngx-datatable-column
          [draggable]="false"
          [sortable]="false"
          [resizeable]="false"
          [width]="70"
          name="S.No."
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
          >
            <span class="dfsfsdf">
              {{ rowIndex + 1 }}
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="120"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
        >
          <ng-template
            ngx-datatable-header-template
            let-value="value"
            let-allRowsSelected="allRowsSelected"
            let-selectFn="selectFn"
          >
            <div class="d-flex checkbox-wthlabel align-items-center">
              <input
                type="checkbox"
                class="fsop-checkbox"
                [checked]="allRowsSelected"
                (change)="selectFn(!allRowsSelected)"
                id="checkbox"
                readonly
              />
              <label for="checkbox">SELECT</label>
            </div>
          </ng-template>
          <ng-template
            ngx-datatable-cell-template
            let-value="value"
            let-isSelected="isSelected"
            let-row="row"
            let-rowIndex="rowIndex"
            let-onCheckboxChangeFn="onCheckboxChangeFn"
          >
            <!-- {{row | json}} -->
            <input
              type="checkbox"
              class="fsop-checkbox"
              [checked]="isSelected"
              [attr.id]="'check_' + row.email"
              (change)="onCheckboxChangeFn($event)"
            />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="false"
          name="email"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
          >
            <div
              [ngStyle]="{
                border:
                  !isValidEmail(row.email) &&
                  (row.email.length > 0 || row.first_name.length > 0 || row.last_name.length > 0)
                    ? '1px solid red'
                    : '0px'
              }"
              class="input-container"
            >
              <input
                class="rowInput form-control"
                [(ngModel)]="row.email"
                type="email"
                name=""
                readonly
              />
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="false"
          prop="first_name"
          name="First Name"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row"
            let-value="value"
          >
            <input
              class="rowInput"
              [(ngModel)]="row.first_name"
              [value]="row.first_name"
              type="text"
              name=""
              class="form-control"
              readonly
            />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="false"
          prop="last_name"
          name="Last Name"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row"
            let-value="value"
          >
            <input
              class="rowInput"
              [(ngModel)]="row.last_name"
              [value]="row.last_name"
              type="text"
              name=""
              class="form-control"
              readonly
            />
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          *ngIf="surveyType == 1"
          [draggable]="false"
          [resizeable]="false"
          prop="role"
          name="Respondent Role"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row"
            let-value="value"
          >
            <input
              class="rowInput"
              [(ngModel)]="row.role.name"
              [value]="row.role.id"
              type="text"
              name=""
              class="form-control"
              readonly
            />
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="true"
          [name]="col.name"
          *ngFor="let col of columns; let i = index"
          [width]="getColumnWidth(col.name)"
          [prop]="col.name"
          [sortable]="true"
        >
          <ng-template let-column="column" ngx-datatable-header-template>
            <div
              (click)="$event.stopPropagation()"
              class="action-col-box d-flex align-item-center"
            >
              <span class="d-inline-block text-nowrap">
                {{ col.name }}
              </span>
              <ul class="icon-actions">
                <li></li>
                <!-- <li>
                  <span>
                    <a
                      (click)="addColumnName(col,i)"
                      class="table-app-edit"
                      title="Edit"
                      >Edit</a
                    >
                  </span>
                </li>

                <li>
                  <span>
                    <a
                      (click)="deleteColumn(col,i)"
                      class="table-app-delete"
                      title="Delete"
                      >Delete</a
                    >
                  </span>
                </li> -->
              </ul>
            </div>
          </ng-template>

          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row"
            let-value="value"
          >
            <input
              maxlength="60"
              class="rowInput"
              [(ngModel)]="row[col.name]"
              [value]="row[col.name]"
              type="text"
              name=""
              class="form-control"
              readonly
            />
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer *ngIf="true">
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
          >
            <div style="padding: 5px 10px;" class="page-count">
              <div>Number of records: {{ reminderContacts.length }}</div>
            </div>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>

      <!--STANDARD SURVEY TABLEVIEW-->
      <ngx-datatable
        *ngIf="emailReminderData == undefined && recepients.length > 0 && surveyType === 0"
        #addContactDataTable
        class="bootstrap contactform-table contact-table"
        [headerHeight]="50"
        [columnMode]="'force'"
        [footerHeight]="50"
        [externalPaging]="false"
        [scrollbarV]="true"
        [rowHeight]="50"
        [rows]="recepients"
        style="min-height: 500px;"
        [scrollbarH]="true"
      >
        <ngx-datatable-column
          [draggable]="false"
          [sortable]="false"
          [resizeable]="false"
          [width]="70"
          name="S.No."
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
          >
            {{ rowIndex + 1 }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="false"
          prop="first_name"
          name="First Name"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row"
            let-value="value"
          >
            <input
              [ngStyle]="{
                border: !row.first_name && isInvalidRow(row) ? '1px solid red' : '0px'
              }"
              class="rowInput form-control"
              [(ngModel)]="row.first_name"
              type="text"
              name=""
            />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="false"
          prod="last_name"
          name="Last Name"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row"
            let-value="value"
          >
            <input
              [ngStyle]="{
                border: !row.last_name && isInvalidRow(row) ? '1px solid red' : '0px'
              }"
              class="rowInput form-control"
              [(ngModel)]="row.last_name"
              type="text"
              name=""
            />
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="false"
          prop="email"
          name="email"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
          >
            <div
              [ngStyle]="{
                border: isInvalidEmail(row) ? '1px solid red' : '0px'
              }"
              class="input-container"
            >
              <input
                class="rowInput form-control"
                [(ngModel)]="row.email"
                type="email"
                name=""
              />
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="true"
          [name]="col.name"
          *ngFor="let col of columns; let i = index"
          [width]="getColumnWidth(col.name)"
          [prop]="col.name"
          [sortable]="true"
        >
          <ng-template let-column="column" ngx-datatable-header-template>
            <div
              (click)="$event.stopPropagation()"
              class="action-col-box d-flex align-item-center"
            >
              <span class="d-inline-block text-nowrap">
                {{ col.name }}
              </span>
              <ul class="icon-actions">
                <li></li>
                <li>
                  <span>
                    <a
                      (click)="addColumnName(col, i)"
                      class="table-app-edit"
                      title="Edit"
                      >Edit</a
                    >
                  </span>
                </li>

                <li>
                  <span>
                    <a
                      (click)="deleteColumn(col, i)"
                      class="table-app-delete"
                      title="Delete"
                      >Delete</a
                    >
                  </span>
                </li>
              </ul>
            </div>
          </ng-template>

          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row"
            let-value="value"
          >
            <!-- <span>{{row|json}}</span>
          <span>{{col|json}}</span> -->

            <input
              maxlength="60"
              class="rowInput"
              [(ngModel)]="row[col.name]"
              [value]="row[col.name]"
              type="text"
              name=""
              class="form-control"
            />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer *ngIf="true">
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
          >
            <div style="padding: 5px 10px;">
              <div>Number of records: {{ recordsNumber }}</div>
            </div>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>

      <!--360 SURVEY CONTACTS TABLEVIEW-->
      <ngx-datatable
        *ngIf="emailReminderData == undefined && recepients.length > 0 && surveyType === 1"
        #addContactDataTable
        class="bootstrap contact-table"
        [headerHeight]="50"
        [columnMode]="'force'"
        [footerHeight]="50"
        [externalPaging]="false"
        [scrollbarV]="true"
        [rowHeight]="56"
        [rows]="recepients"
        style="min-height: 500px;"
        [scrollbarH]="true"
      >
        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="false"
          [width]="70"
          name="S.No."
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
          >
            {{ rowIndex + 1 }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="false"
          prop="first_name"
          name="Respondent First Name"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row"
            let-value="value"
          >
            <!-- <span>{{row | json}}</span> -->
            <input
              [ngStyle]="{
                border: !row.first_name && isInvalidRow(row) ? '1px solid red' : '0px'
              }"
              class="rowInput form-control"
              [(ngModel)]="row['first_name']"
              (ngModelChange)="rowIndex == 0 && modelChanged($event,'first_name')"
              type="text"
              name=""
            />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="false"
          prod="last_name"
          name="Respondent Last Name"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row"
            let-value="value"
          >
            <input
              [ngStyle]="{
                border: !row.last_name && isInvalidRow(row) ? '1px solid red' : '0px'
              }"
              class="rowInput form-control"
              [(ngModel)]="row['last_name']"
              (ngModelChange)="rowIndex == 0 && modelChanged($event,'last_name')"
              type="text"
              name=""
            />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="false"
          prop="email"
          name="Respondent Email Id"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
          >
            <div
              [ngStyle]="{
                border: isInvalidEmail(row) ? '1px solid red' : '0px'
              }"
              class="input-container"
            >
              <input
                class="rowInput form-control"
                [(ngModel)]="row['email']"
                (ngModelChange)="rowIndex == 0 && modelChanged($event,'email')"
                type="email"
                name=""
              />
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="false"
          prop="raterRole"
          name="Respondent Role"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
          >
            <div
              class="form-group mb-0"
              [ngStyle]="{
                border: !row.role && isInvalidRow(row) ? '1px solid red' : '0px'
              }"
            >
              <!-- (click)="openConfirmModal(customRaterModal,row)" -->
              <select
                *ngIf="row.role == 2"
                [(ngModel)]="row.role"
                [disabled]="row.role == 2"
                class="form-control"
              >
                <option *ngIf="row.role == 2" value="2" disabled>Self</option>
                <!-- <ng-container *ngFor="let role of contactRoles;">
                  <option *ngIf="role.id != 2" [value]="role.id"
                    >{{ role.name }}
                  </option>
                </ng-container> -->
              </select>

              <ng-select
                *ngIf="row.role != 2"
                [markFirst]="false"
                [items]="row.role == 2 ? [{ id: 2, name: 'Self' }] : contactRoles"
                [bindLabel]="'name'"
                [disabled]="row.role == 2"
                dropdownPosition="auto"
                bindLabel="name"
                bindValue="id"
                [placeholder]="'Choose Role'"
                [(ngModel)]="row.role"
                [clearable]="false"
                [ngModelOptions]="{ standalone: true }"
                (change)="openConfirmModal(customRaterModal, row)"
                appendTo="body"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-row="row"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="text-truncate d-inline-block">
                      <span
                        class="text-truncate d-inline-block w-100 item-name-height"
                        >{{ item.name }}</span
                      >
                    </div>
                    <div>
                      <span *ngIf="item.allow_delete">
                        <a
                          (click)="openConfirmModal(customRaterModal, row, item)"
                          class="table-app-edit"
                          title="Edit"
                          >Edit</a
                        >
                      </span>

                      <span *ngIf="item.allow_delete">
                        <a
                          (click)="openDeleteModal(deleteModal, item)"
                          class="table-app-delete"
                          title="Delete"
                          >Delete</a
                        >
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [draggable]="false"
          [resizeable]="true"
          [name]="col.name"
          *ngFor="let col of columns; let i = index"
          [width]="getColumnWidth(col.name)"
          [prop]="col.name"
          [sortable]="true"
        >
          <ng-template let-column="column" ngx-datatable-header-template>
            <div
              (click)="$event.stopPropagation()"
              class="action-col-box d-flex align-item-center"
            >
              <span class="d-inline-block text-nowrap">
                {{ col.name }}
              </span>
              <ul class="icon-actions">
                <li></li>
                <li>
                  <span>
                    <a
                      (click)="addColumnName(col, i)"
                      class="table-app-edit"
                      title="Edit"
                      >Edit</a
                    >
                  </span>
                </li>

                <li>
                  <span>
                    <a
                      (click)="deleteColumn(col, i)"
                      class="table-app-delete"
                      title="Delete"
                      >Delete</a
                    >
                  </span>
                </li>
              </ul>
            </div>
          </ng-template>

          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row"
            let-value="value"
          >
            <!-- <span>{{row|json}}</span>
            <span>{{col|json}}</span> -->

            <input
              maxlength="60"
              class="rowInput"
              [(ngModel)]="row[col.name]"
              [value]="row[col.name]"
              type="text"
              name=""
              class="form-control"
            />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer *ngIf="true">
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
          >
            <div style="padding: 5px 10px;" class="page-count">
              <div>Number of records: {{ recordsNumber }}</div>
            </div>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>

      <div class="actions">
        <a
          *ngIf="emailReminderData == undefined"
          style="float: left;"
          href="javascript:void(0)"
          (click)="openBulkUploaderModal(bulkModal)"
          class="bulk-lnk"
          ><i class="fas fa-info-circle me-2"></i>Save time. Try our Bulk
          Uploader</a
        >
        <div class="text-right">
          <button
            *ngIf="emailReminderData == undefined"
            (click)="resetSheet()"
            class="btn btn-md btn-primary"
          >
            Reset Sheet
          </button>
          <button
            *ngIf="emailReminderData == undefined"
            (click)="addContacts()"
            class="btn btn-md btn-primary"
          >
            Add More
          </button>

          <button
            *ngIf="emailReminderData != undefined"
            (click)="reloadRecords()"
            class="btn btn-md btn-primary"
          >
            Reload the Records
          </button>
          <button
            *ngIf="emailReminderData != undefined"
            (click)="deleteRecords()"
            class="btn btn-md btn-primary"
          >
            Delete Records
          </button>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button (click)="saveContacts()" class="btn btn-md btn-primary">
        Save
      </button>
      <a
        href="javascript:void(0)"
        (click)="closeContactsModal()"
        class="btn btn-md btn-secondary"
        >Cancel</a
      >
    </div>
  </div>
</ng-template>

<ng-template #testEmailModal let-modal class="no-border">
  <div class="modal-content">
    <div class="fsop-alert-modal">
      <span>Confirmation</span>
    </div>
    <div class="fsop-alert-modal-body">
      <form [formGroup]="testEmailForm" novalidate>
        <div class="form-group">
          <label
            >Email address for testing:
            <span style="color: red;">*</span></label
          >
          <input
            [ngStyle]="{
              border:
                !isValidEmail(testEmailForm.controls.test_email.value) &&
                (testEmailForm.controls.test_email.dirty || isTestSubmitted)
                  ? '1px solid red'
                  : '0px'
            }"
            class="form-control"
            type="email"
            formControlName="test_email"
          />

          <ng-container
            *ngIf="
              !isValidEmail(testEmailForm.controls.test_email.value) &&
              (testEmailForm.controls.test_email.untouched || testEmailForm.controls.test_email.touched) &&
              isTestSubmitted
            "
          >
            <small
              *ngIf="testEmailForm.controls.test_email.hasError('required')"
              class="required"
              translate
            >
              {{ requiredErrMsg }}
            </small>

            <small
              *ngIf="testEmailForm.controls.test_email.hasError('email')"
              class="required"
              translate
            >
              Enter a valid email address
            </small>
          </ng-container>
        </div>
      </form>
      <b>Note: Survey links are disabled in test mode.</b>
    </div>
    <div class="modal-footer">
      <button (click)="send(true)" class="btn btn-primary">Send</button>
      <button (click)="testEmailModalRef.close()" class="btn btn-secondary">
        Cancel
      </button>
    </div>
  </div>
</ng-template>

<ng-template let-modal #bulkModal class="no-border">
  <div class="modal-content">
    <div class="fsop-alert-modal">
      <span>Bulk Uploader Guidelines</span>
    </div>
    <div class="modal-body guide-modal">
      <p>
        Want to save some time with building your distribution lists? Try our
        Bulk Uploader.
      </p>

      <b>Bulk Uploader Tips</b>
      <ul>
        <li>
          To get started, simply
          <a (click)="downloadSample()" href="javascript:void(0)"
            >download our template</a
          >, insert your recipients into the specified format, and re-upload by
          pressing the “Upload” button.
        </li>
        <li>There can be a maximum of 500 contacts in a single email.</li>
        <li>
          You should save by clicking the “Save” button in order to retain
          changes made to your contact list.
        </li>
        <li>
          In order to provide you with an opportunity to make last minute
          adjustments, you can stop a campaign within 2 minutes of launching.
        </li>
      </ul>
    </div>

    <div class="modal-footer">
      <input
        (change)="onBulkUploaderChanged($event)"
        type="file"
        accept=".csv"
        id="file"
        style="display: none;"
      />
      <button (click)="uploadBulk()" class="btn btn-primary">Upload</button>
      <button (click)="bulkUploaderModalRef.close()" class="btn btn-secondary">
        Cancel
      </button>
    </div>
  </div>
</ng-template>

<ng-template let-modal #guideLineModal class="no-border">
  <div class="modal-content">
    <div class="fsop-alert-modal">
      <span>General Guidelines</span>
    </div>
    <div class="modal-body guide-modal">
      <p>
        Want to get the most out of your survey email campaign? Keep the
        following tips in mind.
      </p>

      <b>Preparation</b>
      <ul>
        <li>The template can be modified to your liking.</li>
        <li>
          The ”Add Name" shortcode will automatically insert the first name of
          your recipients into the email body.
        </li>
        <li>
          The ”Add Survey Link" shortcode will automatically insert the survey
          link into the email body.
        </li>
        <li>
          The ”Add Unsubscribe Link" shortcode will automatically insert an
          unsubscribe link into the email body.
        </li>
        <li>
          “Link Expires” is a mandatory field that provides you with the
          flexibility to have time-limited email campaigns. Simply insert the
          number of days you would like your email campaign to be active. This
          is a mandatory field.
        </li>
        <li>
          As a best practice, you are strongly encouraged to test your survey
          before launching an email campaign. Ready to test? Simply click "Send
          Test Email” and insert a sample recipient.
        </li>
        <li>All fields marked with asterisk are mandatory.</li>
      </ul>

      <b>Distribution</b>

      <ul>
        <li>There can be a maximum of 500 contacts in a single email.</li>
        <li>
          For your convenience, a large number of contacts can be uploaded
          automatically through our Bulk Uploader. Simply download our template,
          insert your recipients into the specified format, and re-upload.
        </li>
        <li>
          You should save by clicking the “Save” button in order to retain
          changes made to your contact list.
        </li>
        <li>
          In order to provide you with an opportunity to make last minute
          adjustments, you can stop a campaign within 2 minutes of launching.
        </li>
      </ul>

      <b>Follow-up</b>

      <ul>
        <li>
          Need to re-encourage your recipients to take your survey? You can
          trigger reminders to any recipients who have not yet taken your
          survey. To access this, visit the Ambivista Reports for your survey,
          toggle the upper-right dropdown from “Survey Analytics” to “Email
          Analytics”, and click the “+” until you are presented with the “Send
          Reminder Email” button.
        </li>
        <li>
          Similar to the original distribution template, the reminder template
          can also be modified to your liking.
        </li>
        <li>
          You are unable to add recipients while sending a reminder email.
          However, you are able to view or delete recipients.
        </li>
        <li>
          You are able to reload the original list of reminder email recipients
          in view contacts.
        </li>
        <li>
          Once a survey has been placed into an inactive state, no reminder
          emails can be sent for the survey.
        </li>
      </ul>
    </div>

    <div class="modal-footer">
      <button (click)="guideLinesModalRef.close()" class="btn btn-primary">
        OK
      </button>
    </div>
  </div>
</ng-template>

<ng-template #modal let-modal class="no-border">
  <app-alert
    [content]="alertContent"
    [active]="modalReferenceSelect"
    (cancelClicked)="closeExpirationModal($event)"
  ></app-alert>
</ng-template>

<ng-template #customRaterModal let-modal class="no-border">
  <app-new-rater-modal
    [contactRoles]="contactRoles"
    (onCloseModalTriggered)="closeCustomeRaterModal()"
    (submitTriggered)="createCustomRater($event)"
    [selectedRow]="selectedRow"
    [selectedRole]="selectedRole"
  ></app-new-rater-modal>
</ng-template>

<ng-template #deleteModal let-modal class="no-border">
  <app-email-confirm-modal
    [title]="'Alert'"
    [buttonName]="'Confirm'"
    [message]="'Are you sure you want to delete the custom role?'"
    (onCloseModalTriggered)="closeDeleteModal()"
    (submitTriggered)="delete($event)"
    [selectedRole]="selectedRole"
  ></app-email-confirm-modal>
</ng-template>
