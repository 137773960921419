import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  // PreloadAllModules
} from "@angular/router";
// import { SurveyPreviewComponent } from 'src/app/survey-preview/survey-preview.component';
import {
  LoggedInGuard,
  AuthenticationGuard,
} from "./core/authentication/authentication.guard";
const routes: Routes = [
  // { path: 'survey/:id/preview', component: SurveyPreviewComponent},
  // { path: 'survey/:id/resp', component: SurveyPreviewComponent},
  // { path: 'survey/:id/resp/survey_email/:survey_email_id/token/:token', component: SurveyPreviewComponent},

  {
    path: "login",
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: "portal",
    loadChildren: () => import('./modules/portal/portal.module').then(m => m.PortalModule),
    // canActivate: [LoggedInGuard],
  },
  {
    path: "forgot-password",
    loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: "resend-password-instruction",
    loadChildren: () => import('./modules/resend-password-instruction/resend-password-instruction.module').then(m => m.ResendPasswordInstructionModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: "set-new-password",
    loadChildren: () => import('./modules/set-new-password/set-new-password.module').then(m => m.SetNewPasswordModule),
    canActivate: [LoggedInGuard],
  },

  {
    path: "",
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthenticationGuard],
  },

  // Fallback when no prior route is matched
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
      // { preloadingStrategy: PreloadAllModules }
    ),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
