import { map, mergeMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";
import { UserDataService } from "../../app/core/user-data";
import { HeaderDataService } from "../../app/core/header-data.service";

@Injectable()
export class TimezoneResolve implements Resolve<any> {
  constructor(private userDataService: UserDataService, private headerDataService: HeaderDataService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let id = this.userDataService.userInfo
      ? this.userDataService.userInfo.id
      : this.headerDataService.userId
      ? this.headerDataService.userId
      : null;
    if (id) {
      return this.userDataService.getCaTimezoneData(id);
    } else {
      return this.userDataService.me();
    }
  }
}
