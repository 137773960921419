import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map, of } from "rxjs";
import { IUserProfile } from "../models/user.model";
@Injectable({
  providedIn: "root",
})
export class HeaderDataService {
  userProfile = new BehaviorSubject<any>(null);
  navbarHeight = new BehaviorSubject<any>(null);
  userProfileDetail: any;
  userId: any;

  constructor() { }

  setProfile(userProfile: IUserProfile) {
    this.userProfileDetail = userProfile;
    this.userId = userProfile.id;
    this.userProfile.next(userProfile);
  }

  getProfileDetail() {
    return this.userProfileDetail;
  }

  // setProfileDetail(data: any) {
  //   this.userProfileDetail = data;
  // }

  setNavBarHeight(height: number) {
    this.navbarHeight.next(height);
  }

  getTimeZone():Observable<string>{
    return this.userProfile.pipe(
      map((userProfile: any) => {
        if (userProfile && userProfile.user_timezone_display) {
          let timezoneNote:string = `Please note that the current timezone is set to  ${userProfile["user_timezone_display"].split(":")[0]}.`;
          return timezoneNote;
        }else{
          return "";
        }
      })
    )
  }
}
