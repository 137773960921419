import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToInteger'
})
export class StringToIntegerPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value!= null && value !== 'NA') {
    	return parseInt(value) + '%'
    }else return value;
  }

}
