import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AssessmentDataService } from "../../../../../../../app/core/assessment-data.service";
import { Observable, of, Subscription } from "rxjs";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-branch-logic-view",
  templateUrl: "./app-branch-logic-view.component.html",
  styleUrls: ["./app-branch-logic-view.component.scss"],
})
export class BranchLogicViewComponent implements OnInit {
  @Input() allQuestions: any;
  @Input() currentQuestion: any;
  @Input() surveyData: any;
  ShowBranchBox: boolean = true;

  conditionSetForMCQ: any = [
    { id: "Is Selected", name: "Is Selected" },
    { id: "Is Not Selected", name: "Is Not Selected" },
  ];

  conditionSetForTextbox: any = [
    { id: "Is greater than", name: "Is greater than" },
    { id: "Is greater than or equal to", name: "Is greater than or equal to" },
    { id: "Is less than", name: "Is less than" },
    { id: "Is less than or equal to", name: "Is less than or equal to" },
    { id: "Is equal to", name: "Is equal to" },
    { id: "Is not equal to", name: "Is not equal to" },
    { id: "Is empty", name: "Is empty" },
    { id: "Is not empty", name: "Is not empty" },
    { id: "Contains", name: "Contains" },
    { id: "Does not contain", name: "Does not contain" },
  ];

  interConditionSet: any = [
    { id: "AND", name: "And" },
    { id: "OR", name: "Or" },
  ];

  @Output("emitBranchLogic")
  emitBranchLogic = new EventEmitter<boolean>();

  nextQuesSet: any = [];
  conditionsArr: any = [];
  @ViewChild("branchLogicModal", { static: false }) branchLogicModal: NgbModalRef;
  subscription: Subscription = new Subscription();
  constructor(
    private modalService: NgbModal,
    private assessmentData: AssessmentDataService,
    private toastService: ToastrService
  ) { }
  ngOnInit() {
    this.listenToBranchLogic();
    this.createQuestionList();
    this.editCondition();
    this.listenToSectionNameUpdate();
  }

  listenToSectionNameUpdate() {
    this.subscription.add(
      this.assessmentData.sectionNameUpdated$.subscribe((reason: any) => {
        this.editCondition();
        // this.listenToBranchLogic();
      })
    );
  }

  listenToBranchLogic() {
    this.subscription.add(
      this.assessmentData.displayLogicAdded$.subscribe((data: any) => {
        let body = data.body.data;
        if (this.currentQuestion) {
          this.createQuestionList();
          let qArr = this.currentQuestion.url.split("/");
          let id = +qArr[qArr.length - 2];

          if (body && data.body && data.id == id && this.currentQuestion) {
            this.currentQuestion["logic"] = body;
            this.editCondition();
          }
        }
      })
    );
  }

  // {"question": 36681,"section": null, "type": "display","conditions":[
  //   {"question_id_from_locator": 36680, "question_choice_locator": 138960, "operator": "selected", "conjuction": null, "question_type":1, "operator_value": 10},
  //   {"question_id_from_locator": 36680, "question_choice_locator": 138961, "operator": "not selected", "conjuction": "AND", "question_type": 1, "operator_value": 10}
  //   ]}

  textToBeShown(conditions: any) {
    let finArr: any = [];
    conditions.forEach((condition: any) => {
      finArr.push(
        this.getQuesOptions(
          condition.section_destination,
          condition,
          condition.question_choice_locator
        )
      );
    });

    if (finArr.length) this.conditionsArr.push(finArr);
    this.emitBranchLogic.next(this.conditionsArr);
  }

  //List of all mcq and text entry type questions
  createQuestionList() {
    this.nextQuesSet = [];
    let qSec = this.currentQuestion.section.split("/");
    let secId = +qSec[qSec.length - 2];
    let gotCurrentSection: boolean = false;

    this.allQuestions.forEach((sec: any, secIndex: any) => {
      let sVal = sec.url.split("/");
      let sId = +sVal[sVal.length - 2];
      if (secId == sId) {
        gotCurrentSection = true;
      } else if (gotCurrentSection) {
        let qArr = sec.url.split("/");
        let id = +qArr[qArr.length - 2];
        sec["_id"] = id;
        this.nextQuesSet.push(sec);
      }
    });
    let endOfSurvey = {
      name: "End of Survey",
    };
    this.nextQuesSet.push(endOfSurvey);
  }

  getQuesOptions(quesId: any, condition: any, optionId?: any) {
    let quesText: any;
    let optionText: any;
    let quesTag: any;
    this.nextQuesSet.filter((sec: any) => {
      if (
        sec._id == quesId &&
        condition.question_destination_type == "question"
      ) {
        quesText = sec.name;
        if (condition.question_destination) {
          sec.questions.forEach((ques: any) => {
            let qArr = ques.url.split("/");
            let id = +qArr[qArr.length - 2];
            if (id == condition.question_destination) {
              quesTag = 'Q' + ques.numbering;
              if (ques._type == 8) {
                quesText = quesText + " (" + ques.text + ") ";
              } else {
                quesText = quesText + " (" + 'Q' + ques.numbering + " " + ques.text + ") ";
              }
            }
          });
        }
        let ques_options = this.currentQuestion.question_options;
        if (ques_options)
          ques_options.forEach((option: any) => {
            if (option.id == optionId) {
              optionText = option.text;
            }
          });
      } else if (
        sec._id == quesId &&
        condition.question_destination_type == "end_of_survey"
      ) {
        quesText = "End of Survey";
        let ques_options = this.currentQuestion.question_options;
        if (ques_options)
          ques_options.forEach((option: any) => {
            if (option.id == optionId) {
              optionText = option.text;
            }
          });
      }
    });

    return {
      question_choosen: this.extractContent(quesText),
      option_choosen: optionText,
      operator: condition.operator,
      conjuction: condition.conjuction,
      operator_value: condition.operator_value,
      invalid: !quesText
        ? true
        : condition.question_destination
          ? quesTag
            ? false
            : true
          : false,
      msg: condition.msg,
    };
  }

  extractContent(s: any) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  editCondition() {
    this.conditionsArr = [];
    let arr = this.currentQuestion.logic;
    let hasDisplay: boolean = false;
    if (arr.length) {
      arr.forEach((ar: any) => {
        if (ar.logic_type == "branching") {
          hasDisplay = true;
          this.textToBeShown(ar.conditions);
        }
      });
    }
    if (!hasDisplay) {
      this.textToBeShown([]);
    }
  }

  branchLogicM: any;
  public openBranchLogicModal(): void {
    this.branchLogicM = this.modalService.open(this.branchLogicModal, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      windowClass: "xlModal",
      size: "lg",
    });
  }

  public closeBranchLogicModal(): void {
    this.branchLogicM.close();
  }

  submitLogic(event: any) {
    let body = event["body"]["data"];
    let id = event["id"];
    if (!event.isEdit) {
      this.assessmentData
        .saveLogic(this.surveyData["identifier"], id, body)
        .subscribe(
          (response: any) => {
            this.toastService.success("Logic added successfully.");
            // event["id"] = response.id;
            // event["body"]["id"] = response.id;
            this.currentQuestion["logic"] = response;
            this.assessmentData.displayLogicAdded$.next(event);
          },
          (error: any) => {
            if (
              error.error &&
              error.error.length &&
              error.error[0] &&
              error.error[0].msg &&
              error.error[0].msg.length
            ) {
              this.toastService.error(error.error[0].msg[0]);
            } else this.toastService.error("Something went wrong.");
          }
        );
    } else if (event.isEdit) {
      this.assessmentData
        .saveLogic(this.surveyData["identifier"], id, body)
        .subscribe(
          (response: any) => {
            this.currentQuestion["logic"] = response;
            this.toastService.success("Logic updated successfully.");
            this.assessmentData.displayLogicAdded$.next(event);
          },
          (error: any) => {
            if (
              error.error &&
              error.error.length &&
              error.error[0] &&
              error.error[0].msg &&
              error.error[0].msg.length
            ) {
              this.toastService.error(error.error[0].msg[0]);
            } else this.toastService.error("Something went wrong.");
          }
        );
    }
    this.closeBranchLogicModal();
  }

  modalref3: any;
  public openConfirmModal(modal: any): void {
    this.modalref3 = this.modalService.open(modal, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      windowClass: "xlModal",
      size: "sm",
    });
  }

  public closeConfirmModal(): void {
    this.modalref3.close();
  }

  delete() {
    let qArr = this.currentQuestion.url.split("/");
    let id = +qArr[qArr.length - 2];

    let logicId: any;
    let arr = this.currentQuestion.logic;
    if (arr.length) {
      arr.forEach((ar: any) => {
        if (ar.logic_type == "branching") {
          logicId = ar.id;
        }
      });
    }

    this.assessmentData
      .deleteLogicCondition(this.surveyData["identifier"], id, logicId)
      .subscribe(
        (obj: any) => {
          this.toastService.success("Skip logic removed successfully.");

          let skipLogicIdx: any;
          let arr = this.currentQuestion.logic;
          if (arr.length) {
            arr.forEach((ar: any, index: any) => {
              if (ar.logic_type == "branching") {
                skipLogicIdx = index;
              }
            });
          }

          this.currentQuestion.logic.splice(skipLogicIdx, 1);
          let event = {
            body: {
              data: this.currentQuestion.logic,
            },
            id: id,
          };
          this.assessmentData.displayLogicAdded$.next(event);
        },
        (error) => {
          if (error && error.error && error.error.message) {
            this.toastService.error(error.error.message);
          } else {
            this.toastService.error("Something went wrong.");
          }
        }
      );
  }

  showDeleteOption() {
    return this.currentQuestion.logic &&
      this.currentQuestion.logic.branching_logic &&
      this.currentQuestion.logic.branching_logic.length &&
      this.currentQuestion.logic.branching_logic[0]
      ? true
      : false;
  }

  isInvalid() {
    let isInvalid: boolean = false;
    this.conditionsArr.forEach((condition: any) => {
      if (condition.invalid) {
        isInvalid = true;
      }
    });
    return isInvalid;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
