import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { isNumber } from "util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AssessmentDataService } from "../../../../../app/core/assessment-data.service";

import $ from 'jquery';

@Component({
  selector: "fsop-dynamic-sum",
  templateUrl: "./fsop-dynamic-sum.component.html",
  styleUrls: ["./fsop-dynamic-sum.component.scss"],
})
export class FsopDynamicSumComponent
  implements OnInit, OnDestroy, AfterViewInit {
    okButtonShow = false;
  @Output("onQuestionDone")
  onQuestionDone = new EventEmitter<any>();
  total: number = 0;
  mustTotalErrorMessage: string = "";
  customValidationErrorMessage: string = "";
  @Input("question")
  question: any;

  @Input("customThemeSettings") customThemeSettings: object;
  @Input("from") from = "";
  @Input("theme")
  theme: string;

  @Input("showQuestionNumber")
  showQuestionNumber: boolean;

  @Input("isNextButtonClicked")
  isNextButtonClicked: boolean;

  @Output("resetNextButton")
  resetNextButton = new EventEmitter<boolean>();

  @Input("numbering")
  numbering: number;

  @Input("previewMode")
  previewMode: boolean;
  questionAnswer: any;
  mobileView: boolean = false;
  keyUpInterval: any;
  allowed_keys = [
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Tab",
    "Delete",
    "Backspace",
  ];

  @Input("showRequiredAsterisk") showRequiredAsterisk: boolean;
  @Input() allQuestions: any;
  @Input() surveyData: any;
  @Input("editable") editable: boolean;
  @Input("backClicked") backClicked: boolean;

  constructor(private modalService: NgbModal, public assessmentDataService: AssessmentDataService) {}

  onOkClick(){
    this.assessmentDataService.focusAnswerOkClicked$.next(true);
  }

  ngOnInit() {
    // sort question option on the basis of position first 
    this.question.question_options = this.question.question_options.sort((a: any, b: any) => {
      if (a['position'] < b['position']) {
        return -1;
      } else if (a['position'] > b['position']) {
        return 1;
      } else {
        return 0;
      }
    });
    if (window.outerWidth < 769) {
      this.mobileView = true;
    }
    if (this.previewMode) {
      if (
        !this.question.hasOwnProperty("answer") ||
        (this.question.answer &&
          (this.question.answer.length !=
            this.question.question_options.length ||
            this.question.answer.filter((a: any) => {
              return (
                (a["answer"] == "" || a["answer"] == null) &&
                a["not_observed"] == false
              );
            }).length != 0))
      ) {
        this.questionAnswer = {
          answer: [],
          ...this.question,
        };

        this.questionAnswer.answer = [];
        this.question.answer = [];

        for (var i in this.question.question_options) {
          this.questionAnswer.answer.push({
            answer: null,
            not_observed: false,
            option: this.question.question_options[i].url,
          });
          this.question.answer.push({
            answer: null,
            not_observed: false,
            option: this.question.question_options[i].url,
          });
        }
      } else {
        let null_option_index = this.question.answer.findIndex((obj: any) => {
          return !obj.option;
        });
        if (null_option_index > -1) {
          this.question.answer = [];
          this.question["answered"] = false;
          for (var i in this.question.question_options) {
            this.question.answer.push({
              answer: null,
              not_observed: false,
              option: this.question.question_options[i].url,
            });
          }
        } else {
          this.updateTotal(this.question);
          setTimeout(() => {
            this.checkIfMustTotal();
            this.checkCustomValidation();
            setTimeout(() => {
              if (
                this.question["hasMustTotalError"] ||
                this.question["hasCustomValidationError"]
              ) {
                this.backClicked = false;
              } else {
                this.backClicked = true;
              }
            }, 1000);
          }, 1500);
        }
      }
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (
        window.name &&
        window.name == "frameAndHashing" &&
        window.innerWidth < 769
      ) {
        this.mobileView = true;
      }
    }, 100);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.previewMode) {
      const nextClickedChange: SimpleChange = changes.isNextButtonClicked;
      this.isNextButtonClicked =
        nextClickedChange && nextClickedChange.currentValue;

      if (this.isNextButtonClicked) {
        this.updateTotal(this.question);
        setTimeout(() => {
          this.checkIfMustTotal();
          this.checkCustomValidation();
        });
      }
    }
  }

  checkForZero(value: any, zeroReturnValue: any, defaultValue: any) {
    if (value == 0) {
      return zeroReturnValue;
    } else {
      return defaultValue;
    }
  }

  private updateQuestionAnswer(question: any) {
    if (this.previewMode) {
      this.okButtonShow = true;
      let nullAnsweredItem: any[] = [];
      nullAnsweredItem = question.answer.filter((a: any) => {
        return a.answer == null || a.answer == "";
      });
      let d:any = document.querySelector(
        `#answer_${this.question.id}_${question.question_options.length}`
      );
      if (
        nullAnsweredItem.length == 0 ||
        (nullAnsweredItem.length == question.answer.length &&
          question.validation_option.to_observe &&
          d["checked"])
      ) {
        question["answered"] = true;
      } else question["answered"] = false;

      // const answer = question.answer.filter((a: any)=>{
      //   return a.option !== null && a.answer !== null
      // });
      // debugger;

      // let qa = {
      //   url: question.url,
      //   type: question._type,
      //   answered: question['answered'],
      //   answer
      // }

      this.question["answer"] = question.answer;
      this.question["answered"] = question.answered;
      this.onQuestionDone.emit(question);
    }
  }
  openModal(e: any, content: any) {
    this.modalService.open(content, {
      size: "sm",
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "tooltip-modal",
    });
  }

  checkValidKey(event: any) {
    if (
      event.key.match(/[0-9]/g) ||
      this.allowed_keys.indexOf(event.key) > -1 ||
      (event.key == "." && event.target.value.indexOf(".") == -1)
    ) {
    } else {
      event.preventDefault();
    }
    // if (event['key'] == "-" || event['key'] == "+" || event['key'] == "=" || (event.keyCode >= 65 && event.keyCode <= 90)) {
    // }
  }

  onOptionValueChanged(event: any, option: any, index: number): void {
    this.resetNextButton.emit(true);

    let z = document.querySelector(
      `#answer_${this.question.id}_${this.question.question_options.length}`
    );
    if (z) {
      z["checked"] = false;
      if (this.question.answer) {
        this.question.answer.forEach((ans: any) => {
          ans["not_observed"] = false;
        });
      }
      if (
        this.questionAnswer &&
        this.questionAnswer.answer &&
        this.questionAnswer.answer.length
      ) {
        this.questionAnswer.answer.forEach((ans: any) => {
          ans["not_observed"] = false;
        });
      }
    }

    clearInterval(this.keyUpInterval);

    this.keyUpInterval = setTimeout(() => {
      var handleEventChange = (q: any) => {
        // let fetchedAnswerOption : any [] = [];
        // if (event.target.value.length > 0) {

        //   fetchedAnswerOption = q.answer.find((a: any)=>{
        //     return a.option === option.url
        //   });

        //   //if answer exists
        //   if(fetchedAnswerOption !== undefined){
        //     let answerOptionIndex = q.answer.indexOf(fetchedAnswerOption);
        //     q.answer[answerOptionIndex].answer = parseFloat(event.target.value);
        //   }else{
        //     q.answer[index].answer = parseFloat(event.target.value);
        //     q.answer[index].option = option.url;
        //   }
        // }else{
        //   fetchedAnswerOption = q.answer.find((a: any)=>{
        //     return a.option === option.url
        //   });

        //   //if answer exists
        //   if(fetchedAnswerOption !== undefined){
        //     let answerOptionIndex = q.answer.indexOf(fetchedAnswerOption);
        //     // q.answer.splice(answerOptionIndex,1)
        //     q.answer[answerOptionIndex].answer = null;
        //   }
        // }

        // for (let index = 0; index < q.answer.length; index++) {
        //   const element = q.answer[index];
        //   let _id = 'dynamic_sum_' + this.question.id + '_' + index;
        //   element['answer'] = document.getElementById(_id)['value']? parseFloat(document.getElementById(_id)['value']) : null;
        // }

        if (
          event.key.match(/[0-9]/g) ||
          this.allowed_keys.indexOf(event.key) > -1 ||
          (event.key == "." && event.target.value.indexOf(".") == -1)
        ) {
          let _index = q.answer.findIndex((obj: any) => {
            return obj["option"] == option["url"];
          });
          let _id = "dynamic_sum_" + this.question.id + "_" + _index;
          let d:any = document.getElementById(_id);
          q.answer[_index]["answer"] = event.target.value.length
            ? event.target.value
            : document.getElementById(_id) &&
              d["value"]
            ? d["value"]
            : null;
          setTimeout(() => {
            this.updateTotal(q);
            this.updateQuestionAnswer(q);
          }, 1000);
        }
      };

      if (this.question.answer != undefined) {
        handleEventChange(this.question);
      } else {
        handleEventChange(this.questionAnswer);
      }
    });
  }

  updateTotal(q: any): void {
    let sum = 0;
    let answers_fields = $(
      'input[id^="dynamic_sum_' + this.question.id + '_"]'
    );
    if (answers_fields.length) {
      for (let index = 0; index < answers_fields.length; index++) {
        const element = answers_fields[index];
        sum +=
          element['value'] && parseFloat(element['value'])
            ? parseFloat(element['value'])
            : 0;
        let _index = this.question.answer.findIndex((obj: any) => {
          return obj.option == this.question.question_options[index]["url"];
        });
        if (
          _index > -1 &&
          (this.question.answer[_index]["answer"] == "" ||
            this.question.answer[_index]["answer"] == null)
        ) {
          this.question.answer[_index]["answer"] =
            element['value'].length && parseFloat(element['value'])
              ? parseFloat(element['value'])
              : null;
        }
      }
    } else {
      q.answer.forEach((answer: any) => {
        sum +=
          answer.answer && parseFloat(answer.answer)
            ? parseFloat(answer.answer)
            : 0;
      });
    }
    this.total =
      parseFloat(sum.toString()) % 1 == 0
        ? parseInt(sum.toString())
        : parseFloat(sum.toString());
  }

  checkFilledAns() {
    if (this.question.answer && this.question.answer.length) {
      if (this.total > 0) {
        return true;
      } else {
        this.mustTotalErrorMessage = "";
        delete this.question["hasMustTotalError"];
        this.question["mustTotalErrorMessage"] = "";
        return false;
      }
    } else {
      this.mustTotalErrorMessage = "";
      delete this.question["hasMustTotalError"];
      this.question["mustTotalErrorMessage"] = "";
      return false;
    }
  }

  checkIfMustTotal(): void {
    let z = document.querySelector(
      `#answer_${this.question.id}_${this.question.question_options.length}`
    );
    if (z && z["checked"] == true) {
      if (this.question.answer) {
        this.question.answer.forEach((ans: any) => {
          ans["not_observed"] = true;
        });
      }
      if (
        this.questionAnswer &&
        this.questionAnswer.answer &&
        this.questionAnswer.answer.length
      ) {
        this.questionAnswer.answer.forEach((ans: any) => {
          ans["not_observed"] = true;
        });
      }
      this.mustTotalErrorMessage = "";
      delete this.question["hasMustTotalError"];
      this.question["mustTotalErrorMessage"] = "";
      return;
    }

    if (this.question.validation_option.must_total != null) {
      if (
        this.total == this.question.validation_option.must_total &&
        !this.ansFilled() &&
        this.question.answer.filter((ans: any) => {
          return ans.answer == null || ans.answer == "";
        }).length > 0
      ) {
        this.mustTotalErrorMessage = `Distribute total among all options or fill remaining options with '0'.`;
        this.question["hasMustTotalError"] = true;
        this.question[
          "mustTotalErrorMessage"
        ] = `Distribute total among all options or fill remaining options with '0'.`;
        return;
      }

      if (
        this.question.validation_option.validation != 2 ||
        (this.question.validation_option.validation == 2 &&
          this.checkFilledAns())
      ) {
        let d:any = document.querySelector(
          `#answer_${this.question.id}_${this.question.question_options.length}`
        );
        if (
          this.total != this.question.validation_option.must_total ||
          (!this.total &&
            this.question.validation_option.to_observe &&
            d["checked"] == false)
        ) {
          if (
            !this.total &&
            !this.question.validation_option.validation &&
            !this.ansFilled() &&
            this.question.answer.filter((ans: any) => {
              return ans.answer == null || ans.answer == "";
            }).length == this.question.question_options.length
          ) {
            this.mustTotalErrorMessage = "";
            delete this.question["hasMustTotalError"];
            this.question["mustTotalErrorMessage"] = "";
          } else {
            this.mustTotalErrorMessage = `Total must be ${this.question.validation_option.must_total}.`;
            this.question["hasMustTotalError"] = true;
            this.question[
              "mustTotalErrorMessage"
            ] = `Total must be ${this.question.validation_option.must_total}.`;
          }
        } else {
          this.mustTotalErrorMessage = "";
          delete this.question["hasMustTotalError"];
          this.question["mustTotalErrorMessage"] = "";
        }
      }
    }
  }

  ansFilled() {
    if (this.question && this.question.answer) {
      let _answered = 0;
      for (let index = 0; index < this.question.answer.length; index++) {
        const element = this.question.answer[index];
        if (element.answer != null && element.answer != "") {
          _answered++;
        }
        if (index == this.question.answer.length - 1) {
          if (_answered == this.question.answer.length) {
            return true;
          } else {
            return false;
          }
        }
      }
    }

    return false;
  }

  checkCustomValidation(): void {
    let z = document.querySelector(
      `#answer_${this.question.id}_${this.question.question_options.length}`
    );
    if (z && z["checked"] == true) {
      if (this.question.answer) {
        this.question.answer.forEach((ans: any) => {
          ans["not_observed"] = true;
        });
      }
      if (
        this.questionAnswer &&
        this.questionAnswer.answer &&
        this.questionAnswer.answer.length
      ) {
        this.questionAnswer.answer.forEach((ans: any) => {
          ans["not_observed"] = true;
        });
      }
      this.customValidationErrorMessage = "";
      delete this.question["hasCustomValidationError"];
      return;
    }

    if (this.question.validation_option.is_custom_validation) {
      if (this.question.answer !== undefined) {
        let concernedAnswer = this.question.answer.find((a: any) => {
          return (
            a.option ===
            this.question.question_options[
              this.question.validation_option.choice - 1
            ].url
          );
        });

        if (concernedAnswer !== undefined) {
          if (
            (concernedAnswer.answer &&
              parseFloat(concernedAnswer.answer) !==
                parseFloat(this.question.validation_option.choice_value)) ||
            (!concernedAnswer.answer && this.total > 0)
          ) {
            this.question["hasCustomValidationError"] = true;
            this.customValidationErrorMessage = this.question.validation_option
              .msg
              ? this.question.validation_option.msg
              : `Value of ${
                  this.question.question_options[
                    this.question.validation_option.choice - 1
                  ].text
                } should be ${parseFloat(
                  this.question.validation_option.choice_value
                )}.`;
          } else {
            this.customValidationErrorMessage = "";
            delete this.question["hasCustomValidationError"];
          }
        } else {
          this.question["hasCustomValidationError"] = true;
          this.customValidationErrorMessage = this.question.validation_option
            .msg
            ? this.question.validation_option.msg
            : `Value of ${
                this.question.question_options[
                  this.question.validation_option.choice - 1
                ].text
              } should be ${parseFloat(
                this.question.validation_option.choice_value
              )}.`;
        }
      } else {
        let concernedAnswer = this.questionAnswer.answer.find((a: any) => {
          return (
            a.option ===
            this.question.question_options[
              this.question.validation_option.choice - 1
            ].url
          );
        });

        if (concernedAnswer !== undefined) {
          if (
            (concernedAnswer.answer &&
              parseFloat(concernedAnswer.answer) !==
                parseFloat(this.question.validation_option.choice_value)) ||
            (!concernedAnswer.answer && this.total > 0)
          ) {
            this.question["hasCustomValidationError"] = true;
            this.customValidationErrorMessage = this.question.validation_option
              .msg
              ? this.question.validation_option.msg
              : `Value of ${
                  this.question.question_options[
                    this.question.validation_option.choice - 1
                  ].text
                } should be ${parseFloat(
                  this.question.validation_option.choice_value
                )}.`;
          } else {
            this.customValidationErrorMessage = "";
            delete this.question["hasCustomValidationError"];
          }
        } else {
          this.question["hasCustomValidationError"] = true;
          this.customValidationErrorMessage = this.question.validation_option
            .msg
            ? this.question.validation_option.msg
            : `Value of ${
                this.question.question_options[
                  this.question.validation_option.choice - 1
                ].text
              } should be ${parseFloat(
                this.question.validation_option.choice_value
              )}.`;
        }
      }
    }
  }

  public getAnswerState(option: any) {
    let a: string = "";
    if (this.previewMode) {
      if (this.question.hasOwnProperty("answer")) {
        let fetchedAnswer = this.question.answer.find((a: any) => {
          return a.option === option.url;
        });
        if (fetchedAnswer !== undefined) {
          a = fetchedAnswer.answer;
          return a && parseFloat(a);
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  checkIfChecked() {
    if (this.question.answer && this.question.answer.length) {
      return this.question.answer[0]["not_observed"];
    }
    if (
      this.questionAnswer &&
      this.questionAnswer.answer &&
      this.questionAnswer.answer.length
    ) {
      return this.questionAnswer.answer[0]["not_observed"];
    }
  }

  checkboxOptionChecked(event: any) {
    this.resetNextButton.emit(true);
    var handleEventChange = (q: any) => {
      if (event.target.checked) {
        for (let index = 0; index < q.answer.length; index++) {
          const element = q.answer[index];
          element.answer = null;
        }
        if (this.question.answer) {
          this.question.answer.forEach((ans: any) => {
            ans["not_observed"] = true;
            ans["answer"] = null;
          });
        }
        if (
          this.questionAnswer &&
          this.questionAnswer.answer &&
          this.questionAnswer.answer.length
        ) {
          this.questionAnswer.answer.forEach((ans: any) => {
            ans["not_observed"] = true;
            ans["answer"] = null;
          });
        }
      } else {
        if (this.question.answer) {
          this.question.answer.forEach((ans: any) => {
            ans["not_observed"] = false;
            ans["answer"] = null;
          });
        }
        if (
          this.questionAnswer &&
          this.questionAnswer.answer &&
          this.questionAnswer.answer.length
        ) {
          this.questionAnswer.answer.forEach((ans: any) => {
            ans["not_observed"] = false;
            ans["answer"] = null;
          });
        }
      }

      setTimeout(() => {
        this.updateTotal(q);
        this.updateQuestionAnswer(q);
      }, 1000);
    };

    if (this.question.answer != undefined) {
      handleEventChange(this.question);
    } else {
      handleEventChange(this.questionAnswer);
    }
  }

  checkRelatedChecked() {
    $(
      "#answer_" +
        this.question.id +
        "_" +
        this.question.question_options.length
    ).trigger("click");
  }

  getQuestionNumbering(numbering: any) {
    return `Q${numbering}<sup
    style="color: red;"
    *ngIf="showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
    >*</sup
  >`;
  }

  getQuestionNumberingWithoutAsterick(numbering: any) {
    return `Q${numbering}`;
  }

  ngOnDestroy() {
    clearInterval(this.keyUpInterval);
  }

  tooltipClick(event: any) {
    event.stopPropagation();
  }

  displayLogic: any = [];
  displayLogicUpdated(event: any) {
    this.displayLogic = event;
  }

  displayLogicClicked: any = false;
  displayLogicClickedEvent(event: any) {
    this.displayLogicClicked = event;
  }
}
