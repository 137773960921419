<div
  id="{{numbering}}"
  class="question-container overridePointer print"
  [ngStyle]="{  'paddingTop': previewMode ? customThemeSettings['content_padding_from_top'] ? customThemeSettings['content_padding_from_top'] +'px' :checkForZero(customThemeSettings['content_padding_from_top'],'0px',''): '' ,
  'paddingBottom': previewMode ? customThemeSettings['content_padding_from_bottom'] ? customThemeSettings['content_padding_from_bottom'] +'px' : checkForZero(customThemeSettings['content_padding_from_bottom'],'0px',''):'',
  'paddingLeft':  previewMode? customThemeSettings['content_padding_from_left'] ? customThemeSettings['content_padding_from_left'] +'px' :checkForZero(customThemeSettings['content_padding_from_left'],'0px','') : '',
  'paddingRight': previewMode ? customThemeSettings['content_padding_from_right'] ? customThemeSettings['content_padding_from_right'] +'px' :checkForZero(customThemeSettings['content_padding_from_right'],'0px',''): ''  ,
  'border-color': customQuestionError.length > 0 ? 'red' : 'black',
  'border' : from ? 'none' :'' }"
  [ngClass]="{ selected: question.uiSelected && !previewMode, 'non-editable': (editable && question.answered && backClicked) ,'previewMode' : previewMode ? true : false, 'wth-out-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
>
  <div
    class="error_container"
    *ngIf="customQuestionError != ''"
    style="text-align: center;"
  >
    <p style="color: red;">{{ customQuestionError }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'required'"
    style="text-align: center;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'requested'"
    style="text-align: center;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>
  <div class="pl-4 ms-1">
    <ng-container *ngIf="!previewMode">
      <ng-container>
        <ng-container>
          <app-display-logic-view
            [currentQuestion]="question"
            [allQuestions]="allQuestions"
            [surveyData]="surveyData"
            (emitDisplayLogic)="displayLogicUpdated($event)"
          ></app-display-logic-view>
        </ng-container>
        </ng-container>
      </ng-container>
  </div>

  <div class="quest" [ngStyle]="{'display':from ? 'flex':''}">
    <h3
      *ngIf="!from  && showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [ngStyle]="{
      'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
      fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumbering(numbering)"
    ></h3>

    <h3
      *ngIf="!from  && !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [ngStyle]="{
    'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
    ></h3>
    <h6
      *ngIf="from  && showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngStyle]="{'margin-right':'1%','margin-top':'0.11%',
    'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumbering(numbering)"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
    ></h6>

    <h3
      *ngIf="from  && !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngStyle]="{'margin-right':'1%','margin-top':'0.11%',
  'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
  fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
    ></h3>
    <h6
      [ngStyle]="{color:previewMode && customThemeSettings &&customThemeSettings['question_font_color'] ? customThemeSettings['question_font_color'] :previewMode && customThemeSettings?'#a5a5a5':'',
    paddingBottom :  previewMode && customThemeSettings && customThemeSettings['padding_btw_question_n_options']  ? customThemeSettings['padding_btw_question_n_options']+ 'px' :'',
    
    
    'fontSize': previewMode ?customThemeSettings &&  customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',


    
    fontFamily :  previewMode && customThemeSettings && customThemeSettings['question_font_family'] ? customThemeSettings['question_font_family'] :'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :'',
    width:!question.question_tooltip?'100%':''}"
      [innerHtml]="question.text | safeHtml"
    ></h6>
    <i
      (click)="tooltipClick($event)"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{marginTop: '0.2%'}"
      *ngIf="!mobileView && question.question_tooltip"
      placement="auto"
      [ngbTooltip]="UploadQuestiontipContent"
    ></i>
    <ng-template #UploadQuestiontipContent>
      <div [innerHTML]="question.question_tooltip | safeHtml "></div>
    </ng-template>
    <i
      (click)="openModal($event,tooltipModalQuestion);$event.stopPropagation();"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{marginTop: '0.2%'}"
      *ngIf="this.mobileView && question.question_tooltip"
    ></i>
  </div>
  <ng-container *ngIf="!previewMode">
    <!-- <app-display-logic-view
    [currentQuestion]="question"
    [allQuestions]="allQuestions"
    [surveyData]="surveyData"
    (emitDisplayLogic)="displayLogicUpdated($event)"
  ></app-display-logic-view> -->
    <!-- <ul
      class="nav nav-tabs"
      id="myTab"
      role="tablist"
      *ngIf="displayLogic.length"
    >
      <li
        class="nav-item"
        *ngIf="displayLogic.length"
        (click)="displayLogicClickedEvent(true)"
      >
        <a
          class="nav-link"
          id="home-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          ><i class="fas fa-question mr-1"></i> Response Logic</a
        >
      </li>
    </ul> -->

    <!-- <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show"
        [ngClass]="{'active': displayLogicClicked }"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <app-display-logic-view
          [currentQuestion]="question"
          [allQuestions]="allQuestions"
          [surveyData]="surveyData"
          (emitDisplayLogic)="displayLogicUpdated($event)"
        ></app-display-logic-view>
      </div>
    </div> -->
  </ng-container>
  <ng-template #tooltipModalQuestion let-modal>
    <a (click)="modal.close()" href="javascript:void(0)" class="close-modal"
      ><i class="fa fa-times"></i
    ></a>
    <div [innerHTML]="question.question_tooltip | safeHtml "></div>
  </ng-template>
  <div class="list-item" (mousedown)="$event.stopPropagation()">
    <div class="choice-container" id="resizeMe_{{random}}">
      <table
        *ngIf="question.option_type == 1 || question.option_type == 2"
        id="resizeMe"
        class="choice-structure"
        [ngStyle]="{
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
      >
        <thead>
          <tr>
            <th
              id="th_{{random}}"
              [ngStyle]="{
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
            minWidth : this.question['columns_width'] ? this.question['columns_width'] :'',width:this.question['columns_width']?this.question['columns_width'] :''}"
              scope="col"
            ></th>
            <th
              *ngFor="let col of question.question_options.cols"
              scope="col"
              [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '':'',
            fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
          fontFamily :  previewMode &&  customThemeSettings['options_font_family'] ? customThemeSettings['options_font_family'] :'',
          paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
          paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
          background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
          fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
            >
              {{ col?.text }}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="question.repeat_header == 1">
          <!--none header-->
          <ng-container
            *ngIf="question.is_position_text_above, else inlineRows"
          >
            <ng-container
              *ngFor="let row of question.question_options.rows; let i = index"
            >
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
              >
                <th
                  scope="row"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '' :'',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
              fontFamily : previewMode &&  customThemeSettings['options_font_family']  ?  customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
              [innerHtml]="row?.text | safeHtml"
                >
                  <!-- {{ row?.text }} -->
                </th>
              </tr>
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
              >
                <th
                  scope="row"
                  [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                ></th>
                <td
                  [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                  *ngFor="let col of question.question_options.cols; let index = index"
                  (click)="optionChecked($event, row, col, i, index)"
                >
                  <input
                    *ngIf="question.option_type == 1"
                    type="radio"
                    (change)="radioOptionChecked(row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    class="fsop-radio"
                    name="answer_{{question.id}}_{{ i }}"
                  />

                  <input
                    *ngIf="question.option_type == 2"
                    (change)="checkboxOptionChecked($event, row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    type="checkbox"
                    class="fsop-checkbox"
                    id="answer_{{question.id}}_{{ i }}_{{ index }}"
                  />
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-template #inlineRows>
            <ng-container
              *ngFor="let row of question.question_options.rows; let i = index"
            >
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
              >
                <td
                  scope="row"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '' :'',
                fontSize:  previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
              fontFamily :   previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
              [innerHtml]="row?.text | safeHtml"
                >
                  <!-- {{ row?.text }} -->
                </td>
                <td
                  [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                  *ngFor="let col of question.question_options.cols; let index = index"
                  (click)="optionChecked($event, row, col, i, index)"
                >
                  <input
                    *ngIf="question.option_type == 1"
                    type="radio"
                    (change)="radioOptionChecked(row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    class="fsop-radio"
                    name="answer_{{question.id}}_{{ i }}"
                  />

                  <input
                    *ngIf="question.option_type == 2"
                    (change)="checkboxOptionChecked($event, row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    type="checkbox"
                    class="fsop-checkbox"
                    id="answer_{{question.id}}_{{ i }}_{{ index }}"
                  />
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </tbody>

        <!--middle header-->
        <tbody *ngIf="question.repeat_header == 2">
          <ng-container
            *ngIf="question.is_position_text_above, else inlineRows"
          >
            <ng-container
              *ngFor="let row of question.question_options.rows; let i = index"
            >
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
              >
                <th
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '' :'',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
              fontFamily : previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
              [innerHtml]="row?.text | safeHtml"
                >
                  <!-- {{ row?.text }} -->
                </th>
                <th [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}" *ngFor="let col of question.question_options.cols"></th>
              </tr>
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
              >
                <th
                  scope="row"
                  [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                ></th>
                <td
                  [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                  *ngFor="let col of question.question_options.cols; let index = index"
                  (click)="optionChecked($event, row, col, i, index)"
                >
                  <input
                    *ngIf="question.option_type == 1"
                    type="radio"
                    (change)="radioOptionChecked(row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    class="fsop-radio"
                    name="answer_{{question.id}}_{{ i }}"
                  />

                  <input
                    *ngIf="question.option_type == 2"
                    (change)="checkboxOptionChecked($event, row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    type="checkbox"
                    class="fsop-checkbox"
                    id="answer_{{question.id}}_{{ i }}_{{ index }}"
                  />
                </td>
              </tr>

              <tr
                *ngIf="i <= question.question_options.rows.length / 2 - 1 && i > question.question_options.rows.length / 2 - 2"
              >
                <th [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}" scope="col"></th>
                <th
                  class="text-center"
                  *ngFor="let col of question.question_options.cols"
                  scope="col"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '' :'',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']: '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                >
                  {{ col?.text }}
                </th>
              </tr>
            </ng-container>
          </ng-container>
          <ng-template #inlineRows>
            <ng-container
              *ngFor="let row of question.question_options.rows; let i = index"
            >
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
              >
                <th
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '' :'',
                fontSize:previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
            background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
            fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
            [innerHtml]="row?.text | safeHtml"
                >
                  <!-- {{ row?.text }} -->
                </th>
                <td
                  [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                  *ngFor="let col of question.question_options.cols; let index = index"
                  (click)="optionChecked($event, row, col, i, index)"
                >
                  <input
                    *ngIf="question.option_type == 1"
                    type="radio"
                    (change)="radioOptionChecked(row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    class="fsop-radio"
                    name="answer_{{question.id}}_{{ i }}"
                  />

                  <input
                    *ngIf="question.option_type == 2"
                    (change)="checkboxOptionChecked($event, row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    type="checkbox"
                    class="fsop-checkbox"
                    id="answer_{{question.id}}_{{ i }}_{{ index }}"
                  />
                </td>
              </tr>

              <tr
                *ngIf="
                  i <= question.question_options.rows.length / 2 - 1 && i > question.question_options.rows.length / 2 - 2
                "
              >
              <th scope="col" [ngStyle]="{
                paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
            paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
            background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''
              }"></th>
                <th
                  class="text-center"
                  *ngFor="let col of question.question_options.cols"
                  scope="col"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                fontSize:theme == 'custom' && previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
              fontFamily : theme == 'custom' && previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color']? customThemeSettings['option_container_bg_color']  : '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                >
                  {{ col?.text }}
                </th>
              </tr>
            </ng-container>
          </ng-template>
        </tbody>

        <tbody *ngIf="question.repeat_header == 3">
          <ng-container
            *ngIf="question.is_position_text_above, else inlineRows"
          >
            <ng-container
              *ngFor="let row of question.question_options.rows; let i = index; let last = last"
            >
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
              >
                <th
                  scope="row"
                  [ngStyle]="{color:theme == 'custom' && previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :theme == 'custom' && previewMode && customThemeSettings ?'#a5a5a5': '' :'',
                fontSize:theme == 'custom' && previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
              fontFamily : theme == 'custom' && previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
             background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
             fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
             [innerHtml]="row?.text | safeHtml"
                >
                  <!-- {{ row?.text }} -->
                </th>
                <th [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}" *ngFor="let col of question.question_options.cols"></th>
              </tr>
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
                scope="row"
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              >
                <th
                  [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                  scope="row"
                  [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                ></th>
                <td
                  [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                  *ngFor="let col of question.question_options.cols; let index = index"
                  (click)="optionChecked($event, row, col, i, index)"
                >
                  <input
                    *ngIf="question.option_type == 1"
                    type="radio"
                    (change)="radioOptionChecked(row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    class="fsop-radio"
                    name="answer_{{question.id}}_{{ i }}"
                  />

                  <input
                    *ngIf="question.option_type == 2"
                    (change)="checkboxOptionChecked($event, row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    type="checkbox"
                    class="fsop-checkbox"
                    id="answer_{{question.id}}_{{ i }}_{{ index }}"
                  />
                </td>
              </tr>

              <tr *ngIf="last">
                <th [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}" scope="col"></th>
                <th
                  class="text-center"
                  *ngFor="let col of question.question_options.cols"
                  scope="col"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                fontSize:theme == 'custom' && previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
              fontFamily : theme == 'custom' && previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                >
                  {{ col?.text }}
                </th>
              </tr>
            </ng-container>
          </ng-container>

          <ng-template #inlineRows>
            <ng-container
              *ngFor="let row of question.question_options.rows; let i = index; let last = last"
            >
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
                scope="row"
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              >
                <th
                  scope="row"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '' :'',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
      background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
      fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
      [innerHtml]="row?.text | safeHtml"
                >
                  <!-- {{ row?.text }} -->
                </th>
                <td
                [ngStyle]="{
                  paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''
                }"
                  *ngFor="let col of question.question_options.cols; let index = index"
                  (click)="optionChecked($event, row, col, i, index)"
                >
                  <input
                    *ngIf="question.option_type == 1"
                    type="radio"
                    (change)="radioOptionChecked(row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    class="fsop-radio"
                    name="answer_{{question.id}}_{{ i }}"
                  />

                  <input
                    *ngIf="question.option_type == 2"
                    (change)="checkboxOptionChecked($event, row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    type="checkbox"
                    class="fsop-checkbox"
                    id="answer_{{question.id}}_{{ i }}_{{ index }}"
                  />
                </td>
              </tr>

              <tr *ngIf="last">
                <th scope="col" [ngStyle]="{
                  paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''
                }"></th>
                <th
                  class="text-center"
                  *ngFor="let col of question.question_options.cols"
                  scope="col"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                >
                  {{ col?.text }}
                </th>
              </tr>
            </ng-container>
          </ng-template>
        </tbody>

        <tbody *ngIf="question.repeat_header == 4">
          <ng-container
            *ngIf="question.is_position_text_above, else inlineRows"
          >
            <ng-container
              *ngFor="let row of question.question_options.rows; let i = index; let first = first; let odd = odd;let last = last"
            >
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
                scope="row"
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              >
                <th
                  scope="row"
                  [ngStyle]="{color:previewMode ? customThemeSettings['options_font_color']? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '' :'',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
              [innerHtml]="row?.text | safeHtml"
                >
                  <!-- {{ row?.text }} -->
                </th>
                <th [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}" *ngFor="let col of question.question_options.cols"></th>
              </tr>
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
                scope="row"
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              >
                <th [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}" scope="row"></th>
                <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                  *ngFor="let col of question.question_options.cols; let index = index"
                  (click)="optionChecked($event, row, col, i, index)"
                >
                  <input
                    *ngIf="question.option_type == 1"
                    type="radio"
                    (change)="radioOptionChecked(row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    class="fsop-radio"
                    name="answer_{{question.id}}_{{ i }}"
                  />

                  <input
                    *ngIf="question.option_type == 2"
                    (change)="checkboxOptionChecked($event, row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    type="checkbox"
                    class="fsop-checkbox"
                    id="answer_{{question.id}}_{{ i }}_{{ index }}"
                  />
                </td>
              </tr>

              <tr *ngIf="last || odd">
                <th scope="col" [ngStyle]="{
                  paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''
                }"></th>
                <th
                  *ngFor="let col of question.question_options.cols"
                  scope="col"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 +  'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px': '6px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                >
                  {{ col?.text }}
                </th>
              </tr>
            </ng-container>
          </ng-container>

          <ng-template #inlineRows>
            <ng-container
              *ngFor="let row of question.question_options.rows; let i = index; let first = first; let odd = odd; let last = last"
            >
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
                scope="row"
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              >
                <th
                  scope="row"
                  [ngStyle]="{color:theme == 'custom' && previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :theme == 'custom'  && previewMode && customThemeSettings ?'#a5a5a5': '' :'',
                fontSize:theme == 'custom' && previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
              fontFamily : theme == 'custom' && previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2  + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2  + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
              [innerHtml]="row?.text | safeHtml"
                >
                  <!-- {{ row?.text }} -->
                </th>
                <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                  *ngFor="let col of question.question_options.cols; let index = index"
                  (click)="optionChecked($event, row, col, i, index)"
                >
                  <input
                    *ngIf="question.option_type == 1"
                    type="radio"
                    (change)="radioOptionChecked(row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    class="fsop-radio"
                    name="answer_{{question.id}}_{{ i }}"
                  />

                  <input
                    *ngIf="question.option_type == 2"
                    (change)="checkboxOptionChecked($event, row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    type="checkbox"
                    class="fsop-checkbox"
                    id="answer_{{question.id}}_{{ i }}_{{ index }}"
                  />
                </td>
              </tr>

              <tr *ngIf="last || odd">
                <th scope="col" [ngStyle]="{
                  paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''
                }"></th>
                <th
                  *ngFor="let col of question.question_options.cols"
                  scope="col"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '' :'',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 +  'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color'] : '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                >
                  {{ col?.text }}
                </th>
              </tr>
            </ng-container>
          </ng-template>
        </tbody>

        <tbody *ngIf="question.repeat_header == 5">
          <ng-container
            *ngIf="question.is_position_text_above, else inlineRows"
          >
            <ng-container
              *ngFor="let row of question.question_options.rows; let i = index; let even = even; let odd = odd"
            >
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
                scope="row"
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              >
                <th
                  scope="row"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ?'#a5a5a5': '':'',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px'):'',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode ?  customThemeSettings['option_container_height']/2  + 'px' : '9px',
              paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':'',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''
              }"
              [innerHtml]="row?.text | safeHtml"
                >
                  <!-- {{ row?.text }} -->
                </th>
                <th [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}" *ngFor="let col of question.question_options.cols"></th>
              </tr>
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
                scope="row"
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              >
                <th scope="row" [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"></th>
                <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                  *ngFor="let col of question.question_options.cols; let index = index"
                  (click)="optionChecked($event, row, col, i, index)"
                >
                  <input
                    *ngIf="question.option_type == 1"
                    type="radio"
                    (change)="radioOptionChecked(row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    class="fsop-radio"
                    name="answer_{{question.id}}_{{ i }}"
                  />

                  <input
                    *ngIf="question.option_type == 2"
                    (change)="checkboxOptionChecked($event, row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    type="checkbox"
                    class="fsop-checkbox"
                    id="answer_{{question.id}}_{{ i }}_{{ index }}"
                  />
                </td>
              </tr>

              <tr *ngIf="even || odd">
                <th [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}" scope="col"></th>
                <th
                  class="text-center"
                  *ngFor="let col of question.question_options.cols"
                  scope="col"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ? '#a5a5a5': '',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color']  ? customThemeSettings['option_container_bg_color'] : '',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                >
                  {{ col.text }}
                </th>
              </tr>
            </ng-container>
          </ng-container>

          <ng-template #inlineRows>
            <ng-container
              *ngFor="let row of question.question_options.rows; let i = index; let even = even; let odd = odd"
            >
              <tr
                [style.border]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ? '1px solid' :  '' :''  "
                [style.borderColor]="previewMode && this.customThemeSettings ? customThemeSettings['option_container_border_color'] ?  customThemeSettings['option_container_border_color'] :  'transparent' :''  "
                [ngStyle]="{
                  paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''
                }"
                scope="row"
              >
                <th
                  scope="row"
                  [ngStyle]="{color:theme == 'custom' && previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :theme == 'custom' && previewMode && customThemeSettings ? '#a5a5a5': '':'',
                fontSize:theme == 'custom' && previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :'14px' :'',
              fontFamily : theme == 'custom' && previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':'',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
              [innerHtml]="row?.text | safeHtml"
                >
                  <!-- {{ row?.text }} -->
                </th>
                <td
                [ngStyle]="{background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''}"
                  *ngFor="let col of question.question_options.cols; let index = index"
                  (click)="optionChecked($event, row, col, i, index)"
                >
                  <input
                    *ngIf="question.option_type == 1"
                    type="radio"
                    (change)="radioOptionChecked(row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    class="fsop-radio"
                    name="answer_{{question.id}}_{{ i }}"
                  />

                  <input
                    *ngIf="question.option_type == 2"
                    (change)="checkboxOptionChecked($event, row, col)"
                    [checked]="checkIfChecked(row.url, col.url)"
                    type="checkbox"
                    class="fsop-checkbox"
                    id="answer_{{question.id}}_{{ i }}_{{ index }}"
                  />
                </td>
              </tr>

              <tr *ngIf="even || odd">
                <th scope="col" [ngStyle]="{
                  paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              background : previewMode && customThemeSettings['option_container_bg_color'] ? customThemeSettings['option_container_bg_color']  : ''
                }"></th>
                <th
                  class="text-center"
                  *ngFor="let col of question.question_options.cols"
                  scope="col"
                  [ngStyle]="{color: previewMode ? customThemeSettings['options_font_color'] :previewMode && customThemeSettings ? '#a5a5a5': '',
                fontSize: previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
              fontFamily :  previewMode ? customThemeSettings['options_font_family'] :'',
              paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
              paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '9px',
            background : previewMode && customThemeSettings['option_container_bg_color']? customThemeSettings['option_container_bg_color']  : '',
            fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
                >
                  {{ col.text }}
                </th>
              </tr>
            </ng-container>
          </ng-template>
        </tbody>
      </table>

      <form *ngIf="question.option_type == 3">
        <div class="form-group">
          <div
            class="item"
            *ngFor="let row of question.question_options.rows; let rowIndex = index;"
          >
            <label
              class="form-control-label"
              [ngStyle]="{color:theme == 'custom' && previewMode ? customThemeSettings['options_font_color'] ? customThemeSettings['options_font_color'] :theme == 'custom'  && previewMode && customThemeSettings ? '#a5a5a5': '':'',
            fontSize:theme == 'custom' && previewMode ? customThemeSettings['options_font_size'] ? customThemeSettings['options_font_size']+'px' :checkForZero(customThemeSettings['options_font_size'],'0px','14px') :'',
          fontFamily : theme == 'custom' && previewMode ? customThemeSettings['options_font_family'] :'',
          paddingTop : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '',
          paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '',
          fontWeight :  previewMode ? customThemeSettings && customThemeSettings['options_font_weight'] ? customThemeSettings['options_font_weight'] :'':''}"
          [innerHtml]="row.text | safeHtml"
              >
              <!-- {{ row.text }} -->
              </label
            >
            <select
              (click)="onSelectBoxClick($event)"
              (change)="onSelectedOptionChanged($event, row)"
              [ngStyle]="{paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                    paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            minHeight : previewMode  ?  '24px' : ''}"
              class="form-control"
            >
              <option value="" selected disabled>Select an answer</option>
              <option
                [selected]="isSelected(rowIndex, col)"
                [value]="col.position"
                *ngFor="let col of question.question_options.cols"
                >{{ col.text }}</option
              >
            </select>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="previewMode && okButtonShow && question['option_type']=='2' && customThemeSettings['theme_layout'] == 'focus'">
      <button
      [id]="'okbt'+question.numbering"
              (click)="onOkClick()"
              class="btn btn-md"
              style="display: inline;margin-top: 20px; color: white; background: #54902b;"
            >OK
            </button>
    </div>
  </div>
</div>
